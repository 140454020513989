.logo {
  margin: 0 40px 50px;
  width: 150px;
  height: 150px;
  object-fit: contain;
}

@media screen and (max-width: 640px) {
  .logo {
    margin: 0 20px 30px;
    width: 100px;
    height: 100px;
  }
}

.linkText {
  cursor: pointer;
}
