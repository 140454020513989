.panel-title {
  font-size: 1rem !important;
  padding-left: 15px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.panel-footer {
  border: none !important;
  padding-top: 0px !important;
}

.panel-actions {
  right: 15px !important;
}

.panelResult {
  margin-bottom: 0 !important;
}

.panel-body {
  padding-top: 15px !important;
  padding-bottom: 0px !important;
}
