@import "variables";
@import "typography";

.akb-credits-overview-div {
  @media screen and (max-width: map-get($akb-breakpoints, desktop)) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .akb-credits-div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-right: 20px;

    .akb-credits-box {
      text-align: center;
      display: flex;
      flex-direction: column;
      margin: 20px;
      h3 {
        margin: 0;
      }
      .akb-credits-wrapper {
        padding: 20px;
        margin: 0 auto;
        width: fit-content;
        //line-height: 70px;
        div {
          padding: 0 20px 0 20px;
          border-radius: 8px;
          border: 1px solid $light-gray;
          font-size: 60px;
          font-weight: 500;
          font-family: "Roboto", Arial, Helvetica, sans-serif;
        }
      }
    }
  }
}
