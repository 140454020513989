.date-group {
  display: flex;
  flex-grow: 1;

  .days,
  .months,
  .years {
    width: 100%;
    height: 36px;

    color: black;
    font-size: 1rem;
    text-align: center;

    border: 1px solid #e4eaec;
  }

  > select + select {
    margin-left: 10px;
  }
}
