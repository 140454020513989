.analysis-datepicker {
  width: 100%;
  height: 35px;
  padding: 8px 10px;

  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  font-style: normal;
  font-family: inherit;

  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-color: #e4eaec;

  // &:hover {
  //   border: 1px solid transparent;
  //   outline: 1px solid rgba(1, 133, 254, 0.7);
  // }

  &:focus {
    border: 1px solid transparent;
    outline: 2px solid rgba(1, 133, 254, 0.7);
  }

  .error & {
    border: 1px solid transparent;
    outline: 1px solid rgb(255, 0, 0);

    &:focus {
      border: 1px solid transparent;
      outline: 2px solid rgba(1, 133, 254, 0.7);
    }

    &:hover {
      border: 1px solid transparent;
    }
  }
}
