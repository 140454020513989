.Register__error h5 {
  color: red;
}

.Register__checkbox input {
  margin-left: 15px;
  margin-right: 25px;
  margin-bottom: 20px;
}

.Register__checkbox {
  display: flex;
}
