@import "typography";
@import "variables";

html * {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

.main-container {
  max-width: $main-container-max-width;
  margin: auto;
}

h1 {
  @include font-headline-1();
}

h2 {
  @include font-headline-1-2();
}

h3 {
  @include font-headline-2();
}

h4 {
  @include font-headline-3();
}

h5 {
  @include font-headline-5();
}

/* reset default :autofill on elements */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
}

/* ... Components ... */
@import "saveAnalysisDropdown";
@import "analysisDatepicker";
@import "dropdownDateSelect";
