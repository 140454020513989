@charset 'UTF-8';
html {
  font-size: 14px;
}

button {
  color: inherit;
}

a:active,
a:hover,
a:focus {
  outline: 0;
}

a.text-body {
  color: #76838f;
}
a.text-body,
a.text-body:hover,
a.text-body:focus {
  text-decoration: none;
}

a.text-action {
  color: #a3afb7;
}
a.text-action,
a.text-action:hover,
a.text-action:focus {
  text-decoration: none;
}
a.text-action:hover,
a.text-action:focus {
  color: #ccd5db;
}
a.text-action .icon + span {
  margin-left: 3px;
}

a.text-like {
  color: #a3afb7 !important;
}
a.text-like,
a.text-like:hover,
a.text-like:focus {
  text-decoration: none;
}
a.text-like.active,
a.text-like:hover,
a.text-like:focus {
  color: #f96868 !important;
}

.text-action + .text-action {
  margin-left: 6px;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  text-shadow: rgba(0, 0, 0, 0.15) 0 0 1px;
}
h1 .icon:first-child,
h2 .icon:first-child,
h3 .icon:first-child,
h4 .icon:first-child,
h5 .icon:first-child,
h6 .icon:first-child,
.h1 .icon:first-child,
.h2 .icon:first-child,
.h3 .icon:first-child,
.h4 .icon:first-child,
.h5 .icon:first-child,
.h6 .icon:first-child {
  margin-right: 0.5em;
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
  margin-top: 22px;
  margin-bottom: 11px;
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
  margin-top: 11px;
  margin-bottom: 11px;
}

mark,
.mark {
  color: #fff;
  border-radius: 0.143rem;
}

.drop-cap {
  font-family: Georgia;
  font-size: 60px;
  line-height: 50px;

  float: left;

  margin-right: 5px;
  padding: 5px;

  color: #263238;
}
.drop-cap-reversed {
  color: #fff;
  background-color: #263238;
}

.list-icons {
  margin-left: 0;
  padding-left: 10px;

  list-style: none;
}
.list-icons > li {
  margin-top: 6px;
}
.list-icons > li:first-child {
  margin-top: 0;
}
.list-icons > li i {
  float: left;

  width: 1em;
  margin: 0 6px 0 0;
}

.blockquote {
  font-size: 1.25rem;

  padding: 11px 22px;

  color: #526069;
  border-left: 2px solid #e4eaec;
}
.blockquote p:last-child {
  margin: 0;
}
.blockquote-success {
  border-color: #8ebe31;
  background-color: rgba(70, 190, 138, 0.1);
}
.blockquote-info {
  border-color: #57c7d4;
  background-color: rgba(87, 199, 212, 0.1);
}
.blockquote-warning {
  border-color: #f2a654;
  background-color: rgba(242, 166, 84, 0.1);
}
.blockquote-danger {
  border-color: #f96868;
  background-color: rgba(249, 104, 104, 0.1);
}

.blockquote-reverse {
  border-right-width: 2px;
  border-left: none;
}

.custom-blockquote {
  padding: 15px 20px;

  border-left-width: 4px;
  border-radius: 0.215rem;
}
.custom-blockquote.blockquote-reverse {
  border-right-width: 4px;
}

.img-bordered {
  padding: 3px;

  border: 1px solid #e4eaec;
}

.img-bordered-primary {
  border-color: #00b3d4 !important;
}

.img-bordered-purple {
  border-color: #7c51d1 !important;
}

.img-bordered-red {
  border-color: #e9595b !important;
}

.img-bordered-green {
  border-color: #7dd3ae !important;
}

.img-bordered-orange {
  border-color: #ec9940 !important;
}

code {
  border: 1px solid #bcd8f1;
}

pre {
  padding: 10.5px;

  color: inherit;
  border: 1px solid #ecf5fc;
  border-radius: 0.215rem;
}

.container {
  max-width: 100%;
}

@media (min-width: 1600px) {
  .container {
    width: 1310px;
  }
}

.row.no-space {
  margin-right: 0;
  margin-left: 0;
}
.row.no-space > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.row-lg {
  margin-right: -1.786rem;
  margin-left: -1.786rem;
}

.row-lg > .col-1,
.row-lg > .col-2,
.row-lg > .col-3,
.row-lg > .col-4,
.row-lg > .col-5,
.row-lg > .col-6,
.row-lg > .col-7,
.row-lg > .col-8,
.row-lg > .col-9,
.row-lg > .col-10,
.row-lg > .col-11,
.row-lg > .col-12,
.row-lg > .col,
.row-lg > .col-sm-1,
.row-lg > .col-sm-2,
.row-lg > .col-sm-3,
.row-lg > .col-sm-4,
.row-lg > .col-sm-5,
.row-lg > .col-sm-6,
.row-lg > .col-sm-7,
.row-lg > .col-sm-8,
.row-lg > .col-sm-9,
.row-lg > .col-sm-10,
.row-lg > .col-sm-11,
.row-lg > .col-sm-12,
.row-lg > .col-sm,
.row-lg > .col-md-1,
.row-lg > .col-md-2,
.row-lg > .col-md-3,
.row-lg > .col-md-4,
.row-lg > .col-md-5,
.row-lg > .col-md-6,
.row-lg > .col-md-7,
.row-lg > .col-md-8,
.row-lg > .col-md-9,
.row-lg > .col-md-10,
.row-lg > .col-md-11,
.row-lg > .col-md-12,
.row-lg > .col-md,
.row-lg > .col-lg-1,
.row-lg > .col-lg-2,
.row-lg > .col-lg-3,
.row-lg > .col-lg-4,
.row-lg > .col-lg-5,
.row-lg > .col-lg-6,
.row-lg > .col-lg-7,
.row-lg > .col-lg-8,
.row-lg > .col-lg-9,
.row-lg > .col-lg-10,
.row-lg > .col-lg-11,
.row-lg > .col-lg-12,
.row-lg > .col-lg,
.row-lg > .col-xl-1,
.row-lg > .col-xl-2,
.row-lg > .col-xl-3,
.row-lg > .col-xl-4,
.row-lg > .col-xl-5,
.row-lg > .col-xl-6,
.row-lg > .col-xl-7,
.row-lg > .col-xl-8,
.row-lg > .col-xl-9,
.row-lg > .col-xl-10,
.row-lg > .col-xl-11,
.row-lg > .col-xl-12,
.row-lg > .col-xl,
.row-lg > .col-xxl-1,
.row-lg > .col-xxl-2,
.row-lg > .col-xxl-3,
.row-lg > .col-xxl-4,
.row-lg > .col-xxl-5,
.row-lg > .col-xxl-6,
.row-lg > .col-xxl-7,
.row-lg > .col-xxl-8,
.row-lg > .col-xxl-9,
.row-lg > .col-xxl-10,
.row-lg > .col-xxl-11,
.row-lg > .col-xxl-12,
.row-lg > .col-xxl {
  padding-right: 1.786rem;
  padding-left: 1.786rem;
}

.table {
  color: #76838f;
}
.table th {
  font-weight: 400;
}
.table thead th,
.table tfoot th {
  font-weight: 400;

  color: #526069;
  border-bottom-width: 1px;
}
.table tbody + tbody {
  border-top-width: 1px;
}
.table a {
  text-decoration: underline;
}
.table th > .checkbox-custom:only-child,
.table td > .checkbox-custom:only-child {
  margin-top: 0;
  margin-bottom: 0;

  text-align: center;
}
.table thead:first-child th {
  border-top: none;
}
.table .cell-30 {
  width: 30px;
}
.table .cell-40 {
  width: 40px;
}
.table .cell-50 {
  width: 50px;
}
.table .cell-60 {
  width: 60px;
}
.table .cell-80 {
  width: 80px;
}
.table .cell-100 {
  width: 100px;
}
.table .cell-120 {
  width: 120px;
}
.table .cell-130 {
  width: 130px;
}
.table .cell-150 {
  width: 150px;
}
.table .cell-180 {
  width: 180px;
}
.table .cell-200 {
  width: 200px;
}
.table .cell-250 {
  width: 250px;
}
.table .cell-300 {
  width: 300px;
}

.table-active,
.table-active > th,
.table-active > td {
  color: inherit;
  background-color: #f3f7f9;
}

.table-active a {
  color: inherit;
}

.table-hover .table-active:hover {
  background-color: #e2ecf1;
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  color: inherit;
  background-color: #e2ecf1;
}

.table-success,
.table-success > th,
.table-success > td {
  color: #fff;
  background-color: #8ebe31;
}

.table-success a {
  color: #fff;
}

.table-hover .table-success:hover {
  background-color: #3dae7d;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  color: #fff;
  background-color: #3dae7d;
}

.table-info,
.table-info > th,
.table-info > td {
  color: #fff;
  background-color: #57c7d4;
}

.table-info a {
  color: #fff;
}

.table-hover .table-info:hover {
  background-color: #43c0cf;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  color: #fff;
  background-color: #43c0cf;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  color: #fff;
  background-color: #f2a654;
}

.table-warning a {
  color: #fff;
}

.table-hover .table-warning:hover {
  background-color: #f09a3c;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  color: #fff;
  background-color: #f09a3c;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  color: #fff;
  background-color: #f96868;
}

.table-danger a {
  color: #fff;
}

.table-hover .table-danger:hover {
  background-color: #f84f4f;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  color: #fff;
  background-color: #f84f4f;
}

.table .thead-default th {
  color: inherit;
  background-color: #f3f7f9;
}

.table .thead-primary th {
  color: #fff;
  background-color: #00b3d4;
}

.table .thead-success th {
  color: #fff;
  background-color: #8ebe31;
}

.table .thead-info th {
  color: #fff;
  background-color: #57c7d4;
}

.table .thead-warning th {
  color: #fff;
  background-color: #f2a654;
}

.table .thead-danger th {
  color: #fff;
  background-color: #f96868;
}

.table .thead-dark th {
  color: #fff;
  background-color: #526069;
}

.table .thead-gray th {
  color: #526069;
  background-color: #ccd5db;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px;
}

.table-section + tbody {
  display: none;
}

.table-section-arrow {
  font-family: "Web Icons";
  font-weight: normal;
  font-weight: normal;
  font-style: normal;
  font-style: normal;

  position: relative;
  position: relative;
  position: relative;

  display: inline-block;
  display: inline-block;

  -webkit-transition: -webkit-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  -webkit-transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  transform: translate(0, 0);
  text-align: center;

  text-rendering: auto;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: auto;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.table-section-arrow:before {
  content: "";
}

.table-section.active tr {
  background-color: #f3f7f9;
}

.table-section.active + tbody {
  display: table-row-group;
}

.table-section.active .table-section-arrow {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

table col[class*="col-"] {
  position: static;

  display: table-column;
  float: none;
}

.form-control {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 2.573rem;

  -webkit-transition: -webkit-box-shadow 0.25s linear, border 0.25s linear,
    color 0.25s linear, background-color 0.25s linear;
  -o-transition: box-shadow 0.25s linear, border 0.25s linear,
    color 0.25s linear, background-color 0.25s linear;
  transition: box-shadow 0.25s linear, border 0.25s linear, color 0.25s linear,
    background-color 0.25s linear;

  border-color: #e4eaec;
  -webkit-box-shadow: none;
  box-shadow: none;

  -webkit-appearance: none;
  -moz-appearance: none;
}

select.form-control {
  padding-right: 30px;

  background: #fff
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAFCAYAAABB9hwOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4RpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpiNWZkMzNlMC0zNTcxLTI4NDgtYjA3NC01ZTRhN2RjMWVmNjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTUxRUI3MDdEQjk4MTFFNUI1NDA5QTcyNTlFQzRERTYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTUxRUI3MDZEQjk4MTFFNUI1NDA5QTcyNTlFQzRERTYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6ZWNiNjQzMjYtNDc1Yi01OTQxLWIxYjItNDVkZjU5YjZlODA2IiBzdFJlZjpkb2N1bWVudElEPSJhZG9iZTpkb2NpZDpwaG90b3Nob3A6N2RlYzI2YWMtZGI5OC0xMWU1LWIwMjgtY2ZhNDhhOGNjNWY1Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+AXTIGgAAAFRJREFUeNpidI1KSWFgYDBlwASngXjOrqWzGcgBTEC8DIjfo4m/h4qTDUAGfwPi+UD8Hyr2H8r/RqnBIHATiPdC2XuhfIoACxJ7PRDzQmmKAUCAAQDxOxHyb4DjOAAAAABJRU5ErkJggg==)
    no-repeat center right;
}

select[multiple].form-control {
  padding-right: 1rem;

  background: #fff;
}

.form-control.focus,
.form-control:focus {
  outline: 0;
}

textarea.form-control,
select[multiple],
select[size] {
  height: auto;
}

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  height: 2.288rem;
}

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  height: 3.287667rem;
}

select.form-control-lg:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: 3.287667rem;
}

select.form-control-sm:not([size]):not([multiple]),
.input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-addon:not([size]):not([multiple]),
.input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: 2.288rem;
}

.form-group .form-control-label {
  padding: 0.429rem 0;
}
.form-group .form-control-label-lg {
  font-size: 1.286rem;

  padding: 0.715rem 0;
}
.form-group .form-control-label-sm {
  font-size: 0.858rem;

  padding: 0.429rem 0;
}

.has-success .form-control-label {
  color: #8ebe31;
}

.has-success .form-control-feedback {
  color: #8ebe31;
}

.has-success .form-control {
  border-color: #8ebe31;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.has-warning .form-control-label {
  color: #f2a654;
}

.has-warning .form-control-feedback {
  color: #f2a654;
}

.has-warning .form-control {
  border-color: #f2a654;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.has-danger .form-control-label {
  color: #f96868;
}

.has-danger .form-control-feedback {
  color: #f96868;
}

.has-danger .form-control {
  border-color: #f96868;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.form-group {
  margin-bottom: 1.429rem;
}
.form-group.has-feedback.no-label .form-control-feedback {
  top: 0;
}
.form-group.has-feedback.left-feedback .form-control-feedback {
  right: auto;
  left: 0;
}
.form-group.has-feedback.left-feedback .form-control {
  padding-right: 13px;
  padding-left: 50px;
}

.form-control.square {
  border-radius: 0;
}

.form-control.round {
  border-radius: 200px;
}

textarea.form-control.no-resize {
  resize: none;
}

.input-group-file input[type="text"] {
  background-color: #fff;
}

.input-group-file .btn-file {
  line-height: inherit;

  position: relative;

  overflow: hidden;
}
.input-group-file .btn-file.btn-outline {
  border: 1px solid #e4eaec;
  border-left: none;
}
.input-group-file .btn-file.btn-outline:hover {
  border-left: none;
}
.input-group-file .btn-file .icon {
  line-height: inherit;

  margin: 0 3px;
}
.input-group-file .btn-file input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;

  display: block;

  min-width: 100%;
  min-height: 100%;

  cursor: pointer;
  text-align: 0;

  opacity: 0;
}

.text-help {
  display: block;

  margin-top: 7px;
  margin-bottom: 8px;

  color: #bcc2c8;
}
.text-help .icon {
  margin: 0 5px;
}

.input-search-close {
  opacity: 0.2;
  color: #000;
  text-shadow: none;
}
.input-search-close.icon {
  font-size: inherit;
  line-height: inherit;
}
.input-search-close:hover,
.input-search-close:focus {
  cursor: pointer;
  text-decoration: none;

  opacity: 0.5;
  color: #000;
}

button.input-search-close {
  padding: 0;

  cursor: pointer;

  border: 0;
  background: transparent;

  -webkit-appearance: none;
}

.input-search {
  position: relative;
}
.input-search .form-control {
  border-radius: 200px;
}
.input-search .input-search-icon,
.input-search .input-search-close {
  position: absolute;
  z-index: 1;
  top: 50%;

  width: 2.573rem;

  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.input-search .input-search-close {
  right: 8px;
}
.input-search .input-search-icon + .form-control {
  padding-left: 3.073rem;
}
.input-search .input-search-icon {
  font-size: 16px;

  left: 8px;

  text-align: center;
  pointer-events: none;

  color: #a3afb7;
}

.input-search-btn + .form-control {
  padding-right: 50px;
}

.input-search-btn {
  position: absolute;
  top: 0;
  right: 0;

  height: 100%;
  padding: 0 10px;

  border: none;
  border-radius: 0 200px 200px 0;
  background: transparent;
}
.input-search-btn .icon {
  margin: 0 3px;
}

.input-search-dark .input-search-icon {
  color: #76838f;
}

.input-search-dark .form-control {
  background: #f3f7f9;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.input-search-dark .form-control:focus {
  background-color: #fff;
}

.form-inline .form-group {
  margin-right: 20px;
}
.form-inline .form-group:last-child {
  margin-right: 0;
}

.form-inline .form-control-label {
  margin-right: 5px;
}

@media (max-width: 767px) {
  .form-inline .form-group {
    margin-right: 0;
  }
}

.form-horizontal .form-control-label {
  font-size: 1rem;

  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 768px) {
  .form-horizontal .form-control-label {
    margin-bottom: 0;
    padding-top: 7px;

    text-align: right;
  }
  .form-horizontal .form-control-lg,
  .form-horizontal .input-group-lg > .form-control,
  .form-horizontal .input-group-lg > .input-group-addon,
  .form-horizontal .input-group-lg > .input-group-btn > .btn {
    font-size: 18px;

    padding-top: 11px;
  }
  .form-horizontal .form-control-sm,
  .form-horizontal .input-group-sm > .form-control,
  .form-horizontal .input-group-sm > .input-group-addon,
  .form-horizontal .input-group-sm > .input-group-btn > .btn {
    font-size: 12px;

    padding-top: 7px;
  }
}

input::-ms-clear {
  display: none;

  width: 0;
  height: 0;
}

/*$btn-floating-xs-padding:                10px !default;*/
/*$btn-floating-sm-padding:                13px !default;*/
/*$btn-floating-lg-padding:                15px !default;*/
.btn {
  cursor: pointer;
  -webkit-transition: border 0.2s linear, color 0.2s linear, width 0.2s linear,
    background-color 0.2s linear;
  -o-transition: border 0.2s linear, color 0.2s linear, width 0.2s linear,
    background-color 0.2s linear;
  transition: border 0.2s linear, color 0.2s linear, width 0.2s linear,
    background-color 0.2s linear;

  -webkit-font-smoothing: subpixel-antialiased;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: 0;
}
.btn:active,
.btn.active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn .icon {
  line-height: inherit;

  width: 1em;
  margin: -1px 3px 0;

  text-align: center;
}

a.btn:not(.btn-link) {
  text-decoration: none;
}
a.btn:not(.btn-link),
a.btn:not(.btn-link):active,
a.btn:not(.btn-link).active {
  text-decoration: none;
}

.btn-block {
  white-space: normal;
}

.btn-outline.btn-default {
  color: #76838f;
  background-color: transparent;
}
.btn-outline.btn-default:hover,
.btn-outline.btn-default:focus,
.btn-outline.btn-default:active,
.btn-outline.btn-default.active,
.open > .btn-outline.btn-default.dropdown-toggle {
  color: #76838f;
  border-color: #e4eaec;
  background-color: rgba(118, 131, 143, 0.1);
}
.btn-outline.btn-default:hover .badge-pill,
.btn-outline.btn-default:focus .badge-pill,
.btn-outline.btn-default:active .badge-pill,
.btn-outline.btn-default.active .badge-pill,
.open > .btn-outline.btn-default.dropdown-toggle .badge-pill {
  color: #76838f;
  background-color: #76838f;
}
.btn-outline.btn-default.btn-up:before {
  border-bottom-color: rgba(118, 131, 143, 0.1);
}
.btn-outline.btn-default.btn-up:focus:before,
.btn-outline.btn-default.btn-up:hover:before {
  border-bottom-color: rgba(118, 131, 143, 0.1);
}
.btn-outline.btn-default.btn-up.active:before,
.btn-outline.btn-default.btn-up:active:before,
.open > .btn-outline.btn-default.btn-up.dropdown-toggle:before {
  border-bottom-color: #ccd5db;
}
.btn-outline.btn-default.btn-right:before {
  border-left-color: rgba(118, 131, 143, 0.1);
}
.btn-outline.btn-default.btn-right:focus:before,
.btn-outline.btn-default.btn-right:hover:before {
  border-left-color: rgba(118, 131, 143, 0.1);
}
.btn-outline.btn-default.btn-right.active:before,
.btn-outline.btn-default.btn-right:active:before,
.open > .btn-outline.btn-default.btn-right.dropdown-toggle:before {
  border-left-color: #ccd5db;
}
.btn-outline.btn-default.btn-bottom:before {
  border-top-color: rgba(118, 131, 143, 0.1);
}
.btn-outline.btn-default.btn-bottom:focus:before,
.btn-outline.btn-default.btn-bottom:hover:before {
  border-top-color: rgba(118, 131, 143, 0.1);
}
.btn-outline.btn-default.btn-bottom.active:before,
.btn-outline.btn-default.btn-bottom:active:before,
.open > .btn-outline.btn-default.btn-bottom.dropdown-toggle:before {
  border-top-color: #ccd5db;
}
.btn-outline.btn-default.btn-left:before {
  border-right-color: rgba(118, 131, 143, 0.1);
}
.btn-outline.btn-default.btn-left:focus:before,
.btn-outline.btn-default.btn-left:hover:before {
  border-right-color: rgba(118, 131, 143, 0.1);
}
.btn-outline.btn-default.btn-left.active:before,
.btn-outline.btn-default.btn-left:active:before,
.open > .btn-outline.btn-default.btn-left.dropdown-toggle:before {
  border-right-color: #ccd5db;
}

.btn-outline.btn-primary {
  color: #00b3d4;
  background-color: transparent;
}
.btn-outline.btn-primary:hover,
.btn-outline.btn-primary:focus,
.btn-outline.btn-primary:active,
.btn-outline.btn-primary.active,
.open > .btn-outline.btn-primary.dropdown-toggle {
  color: #fff;
  border-color: #00b3d4;
  background-color: #00b3d4;
}
.btn-outline.btn-primary:hover .badge-pill,
.btn-outline.btn-primary:focus .badge-pill,
.btn-outline.btn-primary:active .badge-pill,
.btn-outline.btn-primary.active .badge-pill,
.open > .btn-outline.btn-primary.dropdown-toggle .badge-pill {
  color: #00b3d4;
  background-color: #fff;
}
.btn-outline.btn-primary.btn-up:before {
  border-bottom-color: #00b3d4;
}
.btn-outline.btn-primary.btn-up:focus:before,
.btn-outline.btn-primary.btn-up:hover:before {
  border-bottom-color: #00b3d4;
}
.btn-outline.btn-primary.btn-up.active:before,
.btn-outline.btn-primary.btn-up:active:before,
.open > .btn-outline.btn-primary.btn-up.dropdown-toggle:before {
  border-bottom-color: #4e97d9;
}
.btn-outline.btn-primary.btn-right:before {
  border-left-color: #00b3d4;
}
.btn-outline.btn-primary.btn-right:focus:before,
.btn-outline.btn-primary.btn-right:hover:before {
  border-left-color: #00b3d4;
}
.btn-outline.btn-primary.btn-right.active:before,
.btn-outline.btn-primary.btn-right:active:before,
.open > .btn-outline.btn-primary.btn-right.dropdown-toggle:before {
  border-left-color: #4e97d9;
}
.btn-outline.btn-primary.btn-bottom:before {
  border-top-color: #00b3d4;
}
.btn-outline.btn-primary.btn-bottom:focus:before,
.btn-outline.btn-primary.btn-bottom:hover:before {
  border-top-color: #00b3d4;
}
.btn-outline.btn-primary.btn-bottom.active:before,
.btn-outline.btn-primary.btn-bottom:active:before,
.open > .btn-outline.btn-primary.btn-bottom.dropdown-toggle:before {
  border-top-color: #4e97d9;
}
.btn-outline.btn-primary.btn-left:before {
  border-right-color: #00b3d4;
}
.btn-outline.btn-primary.btn-left:focus:before,
.btn-outline.btn-primary.btn-left:hover:before {
  border-right-color: #00b3d4;
}
.btn-outline.btn-primary.btn-left.active:before,
.btn-outline.btn-primary.btn-left:active:before,
.open > .btn-outline.btn-primary.btn-left.dropdown-toggle:before {
  border-right-color: #4e97d9;
}

.btn-outline.btn-success {
  color: #8ebe31;
  background-color: transparent;
}
.btn-outline.btn-success:hover,
.btn-outline.btn-success:focus,
.btn-outline.btn-success:active,
.btn-outline.btn-success.active,
.open > .btn-outline.btn-success.dropdown-toggle {
  color: #fff;
  border-color: #8ebe31;
  background-color: #8ebe31;
}
.btn-outline.btn-success:hover .badge-pill,
.btn-outline.btn-success:focus .badge-pill,
.btn-outline.btn-success:active .badge-pill,
.btn-outline.btn-success.active .badge-pill,
.open > .btn-outline.btn-success.dropdown-toggle .badge-pill {
  color: #8ebe31;
  background-color: #fff;
}
.btn-outline.btn-success.btn-up:before {
  border-bottom-color: #8ebe31;
}
.btn-outline.btn-success.btn-up:focus:before,
.btn-outline.btn-success.btn-up:hover:before {
  border-bottom-color: #8ebe31;
}
.btn-outline.btn-success.btn-up.active:before,
.btn-outline.btn-success.btn-up:active:before,
.open > .btn-outline.btn-success.btn-up.dropdown-toggle:before {
  border-bottom-color: #36ab7a;
}
.btn-outline.btn-success.btn-right:before {
  border-left-color: #8ebe31;
}
.btn-outline.btn-success.btn-right:focus:before,
.btn-outline.btn-success.btn-right:hover:before {
  border-left-color: #8ebe31;
}
.btn-outline.btn-success.btn-right.active:before,
.btn-outline.btn-success.btn-right:active:before,
.open > .btn-outline.btn-success.btn-right.dropdown-toggle:before {
  border-left-color: #36ab7a;
}
.btn-outline.btn-success.btn-bottom:before {
  border-top-color: #8ebe31;
}
.btn-outline.btn-success.btn-bottom:focus:before,
.btn-outline.btn-success.btn-bottom:hover:before {
  border-top-color: #8ebe31;
}
.btn-outline.btn-success.btn-bottom.active:before,
.btn-outline.btn-success.btn-bottom:active:before,
.open > .btn-outline.btn-success.btn-bottom.dropdown-toggle:before {
  border-top-color: #36ab7a;
}
.btn-outline.btn-success.btn-left:before {
  border-right-color: #8ebe31;
}
.btn-outline.btn-success.btn-left:focus:before,
.btn-outline.btn-success.btn-left:hover:before {
  border-right-color: #8ebe31;
}
.btn-outline.btn-success.btn-left.active:before,
.btn-outline.btn-success.btn-left:active:before,
.open > .btn-outline.btn-success.btn-left.dropdown-toggle:before {
  border-right-color: #36ab7a;
}

.btn-outline.btn-info {
  color: #57c7d4;
  background-color: transparent;
}
.btn-outline.btn-info:hover,
.btn-outline.btn-info:focus,
.btn-outline.btn-info:active,
.btn-outline.btn-info.active,
.open > .btn-outline.btn-info.dropdown-toggle {
  color: #fff;
  border-color: #57c7d4;
  background-color: #57c7d4;
}
.btn-outline.btn-info:hover .badge-pill,
.btn-outline.btn-info:focus .badge-pill,
.btn-outline.btn-info:active .badge-pill,
.btn-outline.btn-info.active .badge-pill,
.open > .btn-outline.btn-info.dropdown-toggle .badge-pill {
  color: #57c7d4;
  background-color: #fff;
}
.btn-outline.btn-info.btn-up:before {
  border-bottom-color: #57c7d4;
}
.btn-outline.btn-info.btn-up:focus:before,
.btn-outline.btn-info.btn-up:hover:before {
  border-bottom-color: #57c7d4;
}
.btn-outline.btn-info.btn-up.active:before,
.btn-outline.btn-info.btn-up:active:before,
.open > .btn-outline.btn-info.btn-up.dropdown-toggle:before {
  border-bottom-color: #47b8c6;
}
.btn-outline.btn-info.btn-right:before {
  border-left-color: #57c7d4;
}
.btn-outline.btn-info.btn-right:focus:before,
.btn-outline.btn-info.btn-right:hover:before {
  border-left-color: #57c7d4;
}
.btn-outline.btn-info.btn-right.active:before,
.btn-outline.btn-info.btn-right:active:before,
.open > .btn-outline.btn-info.btn-right.dropdown-toggle:before {
  border-left-color: #47b8c6;
}
.btn-outline.btn-info.btn-bottom:before {
  border-top-color: #57c7d4;
}
.btn-outline.btn-info.btn-bottom:focus:before,
.btn-outline.btn-info.btn-bottom:hover:before {
  border-top-color: #57c7d4;
}
.btn-outline.btn-info.btn-bottom.active:before,
.btn-outline.btn-info.btn-bottom:active:before,
.open > .btn-outline.btn-info.btn-bottom.dropdown-toggle:before {
  border-top-color: #47b8c6;
}
.btn-outline.btn-info.btn-left:before {
  border-right-color: #57c7d4;
}
.btn-outline.btn-info.btn-left:focus:before,
.btn-outline.btn-info.btn-left:hover:before {
  border-right-color: #57c7d4;
}
.btn-outline.btn-info.btn-left.active:before,
.btn-outline.btn-info.btn-left:active:before,
.open > .btn-outline.btn-info.btn-left.dropdown-toggle:before {
  border-right-color: #47b8c6;
}

.btn-outline.btn-warning {
  color: #f2a654;
  background-color: transparent;
}
.btn-outline.btn-warning:hover,
.btn-outline.btn-warning:focus,
.btn-outline.btn-warning:active,
.btn-outline.btn-warning.active,
.open > .btn-outline.btn-warning.dropdown-toggle {
  color: #fff;
  border-color: #f2a654;
  background-color: #f2a654;
}
.btn-outline.btn-warning:hover .badge-pill,
.btn-outline.btn-warning:focus .badge-pill,
.btn-outline.btn-warning:active .badge-pill,
.btn-outline.btn-warning.active .badge-pill,
.open > .btn-outline.btn-warning.dropdown-toggle .badge-pill {
  color: #f2a654;
  background-color: #fff;
}
.btn-outline.btn-warning.btn-up:before {
  border-bottom-color: #f2a654;
}
.btn-outline.btn-warning.btn-up:focus:before,
.btn-outline.btn-warning.btn-up:hover:before {
  border-bottom-color: #f2a654;
}
.btn-outline.btn-warning.btn-up.active:before,
.btn-outline.btn-warning.btn-up:active:before,
.open > .btn-outline.btn-warning.btn-up.dropdown-toggle:before {
  border-bottom-color: #ec9940;
}
.btn-outline.btn-warning.btn-right:before {
  border-left-color: #f2a654;
}
.btn-outline.btn-warning.btn-right:focus:before,
.btn-outline.btn-warning.btn-right:hover:before {
  border-left-color: #f2a654;
}
.btn-outline.btn-warning.btn-right.active:before,
.btn-outline.btn-warning.btn-right:active:before,
.open > .btn-outline.btn-warning.btn-right.dropdown-toggle:before {
  border-left-color: #ec9940;
}
.btn-outline.btn-warning.btn-bottom:before {
  border-top-color: #f2a654;
}
.btn-outline.btn-warning.btn-bottom:focus:before,
.btn-outline.btn-warning.btn-bottom:hover:before {
  border-top-color: #f2a654;
}
.btn-outline.btn-warning.btn-bottom.active:before,
.btn-outline.btn-warning.btn-bottom:active:before,
.open > .btn-outline.btn-warning.btn-bottom.dropdown-toggle:before {
  border-top-color: #ec9940;
}
.btn-outline.btn-warning.btn-left:before {
  border-right-color: #f2a654;
}
.btn-outline.btn-warning.btn-left:focus:before,
.btn-outline.btn-warning.btn-left:hover:before {
  border-right-color: #f2a654;
}
.btn-outline.btn-warning.btn-left.active:before,
.btn-outline.btn-warning.btn-left:active:before,
.open > .btn-outline.btn-warning.btn-left.dropdown-toggle:before {
  border-right-color: #ec9940;
}

.btn-outline.btn-danger {
  color: #f96868;
  background-color: transparent;
}
.btn-outline.btn-danger:hover,
.btn-outline.btn-danger:focus,
.btn-outline.btn-danger:active,
.btn-outline.btn-danger.active,
.open > .btn-outline.btn-danger.dropdown-toggle {
  color: #fff;
  border-color: #f96868;
  background-color: #f96868;
}
.btn-outline.btn-danger:hover .badge-pill,
.btn-outline.btn-danger:focus .badge-pill,
.btn-outline.btn-danger:active .badge-pill,
.btn-outline.btn-danger.active .badge-pill,
.open > .btn-outline.btn-danger.dropdown-toggle .badge-pill {
  color: #f96868;
  background-color: #fff;
}
.btn-outline.btn-danger.btn-up:before {
  border-bottom-color: #f96868;
}
.btn-outline.btn-danger.btn-up:focus:before,
.btn-outline.btn-danger.btn-up:hover:before {
  border-bottom-color: #f96868;
}
.btn-outline.btn-danger.btn-up.active:before,
.btn-outline.btn-danger.btn-up:active:before,
.open > .btn-outline.btn-danger.btn-up.dropdown-toggle:before {
  border-bottom-color: #e9595b;
}
.btn-outline.btn-danger.btn-right:before {
  border-left-color: #f96868;
}
.btn-outline.btn-danger.btn-right:focus:before,
.btn-outline.btn-danger.btn-right:hover:before {
  border-left-color: #f96868;
}
.btn-outline.btn-danger.btn-right.active:before,
.btn-outline.btn-danger.btn-right:active:before,
.open > .btn-outline.btn-danger.btn-right.dropdown-toggle:before {
  border-left-color: #e9595b;
}
.btn-outline.btn-danger.btn-bottom:before {
  border-top-color: #f96868;
}
.btn-outline.btn-danger.btn-bottom:focus:before,
.btn-outline.btn-danger.btn-bottom:hover:before {
  border-top-color: #f96868;
}
.btn-outline.btn-danger.btn-bottom.active:before,
.btn-outline.btn-danger.btn-bottom:active:before,
.open > .btn-outline.btn-danger.btn-bottom.dropdown-toggle:before {
  border-top-color: #e9595b;
}
.btn-outline.btn-danger.btn-left:before {
  border-right-color: #f96868;
}
.btn-outline.btn-danger.btn-left:focus:before,
.btn-outline.btn-danger.btn-left:hover:before {
  border-right-color: #f96868;
}
.btn-outline.btn-danger.btn-left.active:before,
.btn-outline.btn-danger.btn-left:active:before,
.open > .btn-outline.btn-danger.btn-left.dropdown-toggle:before {
  border-right-color: #e9595b;
}

.btn-outline.btn-dark {
  color: #526069;
  background-color: transparent;
}
.btn-outline.btn-dark:hover,
.btn-outline.btn-dark:focus,
.btn-outline.btn-dark:active,
.btn-outline.btn-dark.active,
.open > .btn-outline.btn-dark.dropdown-toggle {
  color: #fff;
  border-color: #526069;
  background-color: #526069;
}
.btn-outline.btn-dark:hover .badge-pill,
.btn-outline.btn-dark:focus .badge-pill,
.btn-outline.btn-dark:active .badge-pill,
.btn-outline.btn-dark.active .badge-pill,
.open > .btn-outline.btn-dark.dropdown-toggle .badge-pill {
  color: #526069;
  background-color: #fff;
}
.btn-outline.btn-dark.btn-up:before {
  border-bottom-color: #526069;
}
.btn-outline.btn-dark.btn-up:focus:before,
.btn-outline.btn-dark.btn-up:hover:before {
  border-bottom-color: #526069;
}
.btn-outline.btn-dark.btn-up.active:before,
.btn-outline.btn-dark.btn-up:active:before,
.open > .btn-outline.btn-dark.btn-up.dropdown-toggle:before {
  border-bottom-color: #37474f;
}
.btn-outline.btn-dark.btn-right:before {
  border-left-color: #526069;
}
.btn-outline.btn-dark.btn-right:focus:before,
.btn-outline.btn-dark.btn-right:hover:before {
  border-left-color: #526069;
}
.btn-outline.btn-dark.btn-right.active:before,
.btn-outline.btn-dark.btn-right:active:before,
.open > .btn-outline.btn-dark.btn-right.dropdown-toggle:before {
  border-left-color: #37474f;
}
.btn-outline.btn-dark.btn-bottom:before {
  border-top-color: #526069;
}
.btn-outline.btn-dark.btn-bottom:focus:before,
.btn-outline.btn-dark.btn-bottom:hover:before {
  border-top-color: #526069;
}
.btn-outline.btn-dark.btn-bottom.active:before,
.btn-outline.btn-dark.btn-bottom:active:before,
.open > .btn-outline.btn-dark.btn-bottom.dropdown-toggle:before {
  border-top-color: #37474f;
}
.btn-outline.btn-dark.btn-left:before {
  border-right-color: #526069;
}
.btn-outline.btn-dark.btn-left:focus:before,
.btn-outline.btn-dark.btn-left:hover:before {
  border-right-color: #526069;
}
.btn-outline.btn-dark.btn-left.active:before,
.btn-outline.btn-dark.btn-left:active:before,
.open > .btn-outline.btn-dark.btn-left.dropdown-toggle:before {
  border-right-color: #37474f;
}

.btn-outline.btn-inverse {
  color: #fff;
  background-color: transparent;
}
.btn-outline.btn-inverse:hover,
.btn-outline.btn-inverse:focus,
.btn-outline.btn-inverse:active,
.btn-outline.btn-inverse.active,
.open > .btn-outline.btn-inverse.dropdown-toggle {
  color: #76838f;
  border-color: #fff;
  background-color: #fff;
}
.btn-outline.btn-inverse:hover .badge-pill,
.btn-outline.btn-inverse:focus .badge-pill,
.btn-outline.btn-inverse:active .badge-pill,
.btn-outline.btn-inverse.active .badge-pill,
.open > .btn-outline.btn-inverse.dropdown-toggle .badge-pill {
  color: #fff;
  background-color: #76838f;
}
.btn-outline.btn-inverse.btn-up:before {
  border-bottom-color: #fff;
}
.btn-outline.btn-inverse.btn-up:focus:before,
.btn-outline.btn-inverse.btn-up:hover:before {
  border-bottom-color: #fff;
}
.btn-outline.btn-inverse.btn-up.active:before,
.btn-outline.btn-inverse.btn-up:active:before,
.open > .btn-outline.btn-inverse.btn-up.dropdown-toggle:before {
  border-bottom-color: #fff;
}
.btn-outline.btn-inverse.btn-right:before {
  border-left-color: #fff;
}
.btn-outline.btn-inverse.btn-right:focus:before,
.btn-outline.btn-inverse.btn-right:hover:before {
  border-left-color: #fff;
}
.btn-outline.btn-inverse.btn-right.active:before,
.btn-outline.btn-inverse.btn-right:active:before,
.open > .btn-outline.btn-inverse.btn-right.dropdown-toggle:before {
  border-left-color: #fff;
}
.btn-outline.btn-inverse.btn-bottom:before {
  border-top-color: #fff;
}
.btn-outline.btn-inverse.btn-bottom:focus:before,
.btn-outline.btn-inverse.btn-bottom:hover:before {
  border-top-color: #fff;
}
.btn-outline.btn-inverse.btn-bottom.active:before,
.btn-outline.btn-inverse.btn-bottom:active:before,
.open > .btn-outline.btn-inverse.btn-bottom.dropdown-toggle:before {
  border-top-color: #fff;
}
.btn-outline.btn-inverse.btn-left:before {
  border-right-color: #fff;
}
.btn-outline.btn-inverse.btn-left:focus:before,
.btn-outline.btn-inverse.btn-left:hover:before {
  border-right-color: #fff;
}
.btn-outline.btn-inverse.btn-left.active:before,
.btn-outline.btn-inverse.btn-left:active:before,
.open > .btn-outline.btn-inverse.btn-left.dropdown-toggle:before {
  border-right-color: #fff;
}

.btn-xs,
.btn-group-xs > .btn {
  font-size: 0.858rem;
  line-height: 1.5;

  padding: 0.072rem 0.358rem;

  border-radius: 0.143rem;
}

.btn-squared {
  border-radius: 0;
}

.btn-round {
  border-radius: 1000px;
}

.btn-primary {
  border-color: #00b3d4;
  background-color: #00b3d4;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  border-color: #89bceb;
  background-color: #89bceb;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-primary:active,
.btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  border-color: #4e97d9;
  background-color: #4e97d9;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary:active.focus,
.btn-primary.active:hover,
.btn-primary.active:focus,
.btn-primary.active.focus,
.open > .btn-primary.dropdown-toggle:hover,
.open > .btn-primary.dropdown-toggle:focus,
.open > .btn-primary.dropdown-toggle.focus {
  border-color: #4e97d9;
  background-color: #4e97d9;
}
.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled.focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled].focus,
.btn-primary[disabled]:active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  color: #fff;
  border-color: #a2caee;
  background-color: #a2caee;
}
.btn-primary .badge-pill {
  color: #00b3d4;
  background-color: #fff;
}
.btn-primary.btn-up:before {
  border-bottom-color: #00b3d4;
}
.btn-primary.btn-up:focus:before,
.btn-primary.btn-up:hover:before {
  border-bottom-color: #89bceb;
}
.btn-primary.btn-up.active:before,
.btn-primary.btn-up:active:before,
.open > .btn-primary.btn-up.dropdown-toggle:before {
  border-bottom-color: #4e97d9;
}
.btn-primary.btn-right:before {
  border-left-color: #00b3d4;
}
.btn-primary.btn-right:focus:before,
.btn-primary.btn-right:hover:before {
  border-left-color: #89bceb;
}
.btn-primary.btn-right.active:before,
.btn-primary.btn-right:active:before,
.open > .btn-primary.btn-right.dropdown-toggle:before {
  border-left-color: #4e97d9;
}
.btn-primary.btn-bottom:before {
  border-top-color: #00b3d4;
}
.btn-primary.btn-bottom:focus:before,
.btn-primary.btn-bottom:hover:before {
  border-top-color: #89bceb;
}
.btn-primary.btn-bottom.active:before,
.btn-primary.btn-bottom:active:before,
.open > .btn-primary.btn-bottom.dropdown-toggle:before {
  border-top-color: #4e97d9;
}
.btn-primary.btn-left:before {
  border-right-color: #00b3d4;
}
.btn-primary.btn-left:focus:before,
.btn-primary.btn-left:hover:before {
  border-right-color: #89bceb;
}
.btn-primary.btn-left.active:before,
.btn-primary.btn-left:active:before,
.open > .btn-primary.btn-left.dropdown-toggle:before {
  border-right-color: #4e97d9;
}

.btn-success {
  border-color: #8ebe31;
  background-color: #8ebe31;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-success:hover,
.btn-success:focus,
.btn-success.focus {
  color: #fff;
  border-color: #5cd29d;
  background-color: #5cd29d;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-success:active,
.btn-success.active,
.open > .btn-success.dropdown-toggle {
  border-color: #36ab7a;
  background-color: #36ab7a;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-success:active:hover,
.btn-success:active:focus,
.btn-success:active.focus,
.btn-success.active:hover,
.btn-success.active:focus,
.btn-success.active.focus,
.open > .btn-success.dropdown-toggle:hover,
.open > .btn-success.dropdown-toggle:focus,
.open > .btn-success.dropdown-toggle.focus {
  border-color: #36ab7a;
  background-color: #36ab7a;
}
.btn-success.disabled,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled.focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success[disabled],
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled].focus,
.btn-success[disabled]:active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
  color: #fff;
  border-color: #7dd3ae;
  background-color: #7dd3ae;
}
.btn-success .badge-pill {
  color: #8ebe31;
  background-color: #fff;
}
.btn-success.btn-up:before {
  border-bottom-color: #8ebe31;
}
.btn-success.btn-up:focus:before,
.btn-success.btn-up:hover:before {
  border-bottom-color: #5cd29d;
}
.btn-success.btn-up.active:before,
.btn-success.btn-up:active:before,
.open > .btn-success.btn-up.dropdown-toggle:before {
  border-bottom-color: #36ab7a;
}
.btn-success.btn-right:before {
  border-left-color: #8ebe31;
}
.btn-success.btn-right:focus:before,
.btn-success.btn-right:hover:before {
  border-left-color: #5cd29d;
}
.btn-success.btn-right.active:before,
.btn-success.btn-right:active:before,
.open > .btn-success.btn-right.dropdown-toggle:before {
  border-left-color: #36ab7a;
}
.btn-success.btn-bottom:before {
  border-top-color: #8ebe31;
}
.btn-success.btn-bottom:focus:before,
.btn-success.btn-bottom:hover:before {
  border-top-color: #5cd29d;
}
.btn-success.btn-bottom.active:before,
.btn-success.btn-bottom:active:before,
.open > .btn-success.btn-bottom.dropdown-toggle:before {
  border-top-color: #36ab7a;
}
.btn-success.btn-left:before {
  border-right-color: #8ebe31;
}
.btn-success.btn-left:focus:before,
.btn-success.btn-left:hover:before {
  border-right-color: #5cd29d;
}
.btn-success.btn-left.active:before,
.btn-success.btn-left:active:before,
.open > .btn-success.btn-left.dropdown-toggle:before {
  border-right-color: #36ab7a;
}

.btn-info {
  border-color: #57c7d4;
  background-color: #57c7d4;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-info:hover,
.btn-info:focus,
.btn-info.focus {
  color: #fff;
  border-color: #77d6e1;
  background-color: #77d6e1;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-info:active,
.btn-info.active,
.open > .btn-info.dropdown-toggle {
  border-color: #47b8c6;
  background-color: #47b8c6;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-info:active:hover,
.btn-info:active:focus,
.btn-info:active.focus,
.btn-info.active:hover,
.btn-info.active:focus,
.btn-info.active.focus,
.open > .btn-info.dropdown-toggle:hover,
.open > .btn-info.dropdown-toggle:focus,
.open > .btn-info.dropdown-toggle.focus {
  border-color: #47b8c6;
  background-color: #47b8c6;
}
.btn-info.disabled,
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled.focus,
.btn-info.disabled:active,
.btn-info.disabled.active,
.btn-info[disabled],
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled].focus,
.btn-info[disabled]:active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
  color: #fff;
  border-color: #9ae1e9;
  background-color: #9ae1e9;
}
.btn-info .badge-pill {
  color: #57c7d4;
  background-color: #fff;
}
.btn-info.btn-up:before {
  border-bottom-color: #57c7d4;
}
.btn-info.btn-up:focus:before,
.btn-info.btn-up:hover:before {
  border-bottom-color: #77d6e1;
}
.btn-info.btn-up.active:before,
.btn-info.btn-up:active:before,
.open > .btn-info.btn-up.dropdown-toggle:before {
  border-bottom-color: #47b8c6;
}
.btn-info.btn-right:before {
  border-left-color: #57c7d4;
}
.btn-info.btn-right:focus:before,
.btn-info.btn-right:hover:before {
  border-left-color: #77d6e1;
}
.btn-info.btn-right.active:before,
.btn-info.btn-right:active:before,
.open > .btn-info.btn-right.dropdown-toggle:before {
  border-left-color: #47b8c6;
}
.btn-info.btn-bottom:before {
  border-top-color: #57c7d4;
}
.btn-info.btn-bottom:focus:before,
.btn-info.btn-bottom:hover:before {
  border-top-color: #77d6e1;
}
.btn-info.btn-bottom.active:before,
.btn-info.btn-bottom:active:before,
.open > .btn-info.btn-bottom.dropdown-toggle:before {
  border-top-color: #47b8c6;
}
.btn-info.btn-left:before {
  border-right-color: #57c7d4;
}
.btn-info.btn-left:focus:before,
.btn-info.btn-left:hover:before {
  border-right-color: #77d6e1;
}
.btn-info.btn-left.active:before,
.btn-info.btn-left:active:before,
.open > .btn-info.btn-left.dropdown-toggle:before {
  border-right-color: #47b8c6;
}

.btn-warning {
  border-color: #f2a654;
  background-color: #f2a654;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus {
  color: #fff;
  border-color: #f4b066;
  background-color: #f4b066;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-warning:active,
.btn-warning.active,
.open > .btn-warning.dropdown-toggle {
  border-color: #ec9940;
  background-color: #ec9940;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-warning:active:hover,
.btn-warning:active:focus,
.btn-warning:active.focus,
.btn-warning.active:hover,
.btn-warning.active:focus,
.btn-warning.active.focus,
.open > .btn-warning.dropdown-toggle:hover,
.open > .btn-warning.dropdown-toggle:focus,
.open > .btn-warning.dropdown-toggle.focus {
  border-color: #ec9940;
  background-color: #ec9940;
}
.btn-warning.disabled,
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled.focus,
.btn-warning.disabled:active,
.btn-warning.disabled.active,
.btn-warning[disabled],
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled].focus,
.btn-warning[disabled]:active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
  color: #fff;
  border-color: #f6be80;
  background-color: #f6be80;
}
.btn-warning .badge-pill {
  color: #f2a654;
  background-color: #fff;
}
.btn-warning.btn-up:before {
  border-bottom-color: #f2a654;
}
.btn-warning.btn-up:focus:before,
.btn-warning.btn-up:hover:before {
  border-bottom-color: #f4b066;
}
.btn-warning.btn-up.active:before,
.btn-warning.btn-up:active:before,
.open > .btn-warning.btn-up.dropdown-toggle:before {
  border-bottom-color: #ec9940;
}
.btn-warning.btn-right:before {
  border-left-color: #f2a654;
}
.btn-warning.btn-right:focus:before,
.btn-warning.btn-right:hover:before {
  border-left-color: #f4b066;
}
.btn-warning.btn-right.active:before,
.btn-warning.btn-right:active:before,
.open > .btn-warning.btn-right.dropdown-toggle:before {
  border-left-color: #ec9940;
}
.btn-warning.btn-bottom:before {
  border-top-color: #f2a654;
}
.btn-warning.btn-bottom:focus:before,
.btn-warning.btn-bottom:hover:before {
  border-top-color: #f4b066;
}
.btn-warning.btn-bottom.active:before,
.btn-warning.btn-bottom:active:before,
.open > .btn-warning.btn-bottom.dropdown-toggle:before {
  border-top-color: #ec9940;
}
.btn-warning.btn-left:before {
  border-right-color: #f2a654;
}
.btn-warning.btn-left:focus:before,
.btn-warning.btn-left:hover:before {
  border-right-color: #f4b066;
}
.btn-warning.btn-left.active:before,
.btn-warning.btn-left:active:before,
.open > .btn-warning.btn-left.dropdown-toggle:before {
  border-right-color: #ec9940;
}

.btn-danger {
  border-color: #f96868;
  background-color: #f96868;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  border-color: #fa7a7a;
  background-color: #fa7a7a;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-danger:active,
.btn-danger.active,
.open > .btn-danger.dropdown-toggle {
  border-color: #e9595b;
  background-color: #e9595b;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-danger:active:hover,
.btn-danger:active:focus,
.btn-danger:active.focus,
.btn-danger.active:hover,
.btn-danger.active:focus,
.btn-danger.active.focus,
.open > .btn-danger.dropdown-toggle:hover,
.open > .btn-danger.dropdown-toggle:focus,
.open > .btn-danger.dropdown-toggle.focus {
  border-color: #e9595b;
  background-color: #e9595b;
}
.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled.focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger[disabled],
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled].focus,
.btn-danger[disabled]:active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
  color: #fff;
  border-color: #fa9898;
  background-color: #fa9898;
}
.btn-danger .badge-pill {
  color: #f96868;
  background-color: #fff;
}
.btn-danger.btn-up:before {
  border-bottom-color: #f96868;
}
.btn-danger.btn-up:focus:before,
.btn-danger.btn-up:hover:before {
  border-bottom-color: #fa7a7a;
}
.btn-danger.btn-up.active:before,
.btn-danger.btn-up:active:before,
.open > .btn-danger.btn-up.dropdown-toggle:before {
  border-bottom-color: #e9595b;
}
.btn-danger.btn-right:before {
  border-left-color: #f96868;
}
.btn-danger.btn-right:focus:before,
.btn-danger.btn-right:hover:before {
  border-left-color: #fa7a7a;
}
.btn-danger.btn-right.active:before,
.btn-danger.btn-right:active:before,
.open > .btn-danger.btn-right.dropdown-toggle:before {
  border-left-color: #e9595b;
}
.btn-danger.btn-bottom:before {
  border-top-color: #f96868;
}
.btn-danger.btn-bottom:focus:before,
.btn-danger.btn-bottom:hover:before {
  border-top-color: #fa7a7a;
}
.btn-danger.btn-bottom.active:before,
.btn-danger.btn-bottom:active:before,
.open > .btn-danger.btn-bottom.dropdown-toggle:before {
  border-top-color: #e9595b;
}
.btn-danger.btn-left:before {
  border-right-color: #f96868;
}
.btn-danger.btn-left:focus:before,
.btn-danger.btn-left:hover:before {
  border-right-color: #fa7a7a;
}
.btn-danger.btn-left.active:before,
.btn-danger.btn-left:active:before,
.open > .btn-danger.btn-left.dropdown-toggle:before {
  border-right-color: #e9595b;
}

.btn-default {
  color: #76838f;
  border-color: #e4eaec;
  border-color: #e4eaec;
  background-color: #e4eaec;
  background-color: #e4eaec;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-box-shadow: none;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: none;
}
.btn-default:hover {
  color: #76838f;
  border-color: #c0ced3;
  background-color: #c6d3d7;
}
.btn-default:focus,
.btn-default.focus {
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 2px rgba(228, 234, 236, 0.5);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 2px rgba(228, 234, 236, 0.5);
}
.btn-default.disabled,
.btn-default:disabled {
  border-color: #e4eaec;
  background-color: #e4eaec;
}
.btn-default:active,
.btn-default.active,
.show > .btn-default.dropdown-toggle {
  color: #76838f;
  border-color: #c0ced3;
  background-color: #c6d3d7;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-default:hover,
.btn-default:focus,
.btn-default.focus {
  color: #76838f;
  border-color: #f3f7f9;
  background-color: #f3f7f9;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-default:active,
.btn-default.active,
.open > .btn-default.dropdown-toggle {
  border-color: #ccd5db;
  background-color: #ccd5db;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-default:active:hover,
.btn-default:active:focus,
.btn-default:active.focus,
.btn-default.active:hover,
.btn-default.active:focus,
.btn-default.active.focus,
.open > .btn-default.dropdown-toggle:hover,
.open > .btn-default.dropdown-toggle:focus,
.open > .btn-default.dropdown-toggle.focus {
  border-color: #ccd5db;
  background-color: #ccd5db;
}
.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled.focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled],
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled].focus,
.btn-default[disabled]:active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active {
  color: #76838f;
  border-color: #f3f7f9;
  background-color: #f3f7f9;
}
.btn-default .badge-pill {
  color: #e4eaec;
  background-color: #76838f;
}
.btn-default.btn-up:before {
  border-bottom-color: #e4eaec;
}
.btn-default.btn-up:focus:before,
.btn-default.btn-up:hover:before {
  border-bottom-color: #f3f7f9;
}
.btn-default.btn-up.active:before,
.btn-default.btn-up:active:before,
.open > .btn-default.btn-up.dropdown-toggle:before {
  border-bottom-color: #ccd5db;
}
.btn-default.btn-right:before {
  border-left-color: #e4eaec;
}
.btn-default.btn-right:focus:before,
.btn-default.btn-right:hover:before {
  border-left-color: #f3f7f9;
}
.btn-default.btn-right.active:before,
.btn-default.btn-right:active:before,
.open > .btn-default.btn-right.dropdown-toggle:before {
  border-left-color: #ccd5db;
}
.btn-default.btn-bottom:before {
  border-top-color: #e4eaec;
}
.btn-default.btn-bottom:focus:before,
.btn-default.btn-bottom:hover:before {
  border-top-color: #f3f7f9;
}
.btn-default.btn-bottom.active:before,
.btn-default.btn-bottom:active:before,
.open > .btn-default.btn-bottom.dropdown-toggle:before {
  border-top-color: #ccd5db;
}
.btn-default.btn-left:before {
  border-right-color: #e4eaec;
}
.btn-default.btn-left:focus:before,
.btn-default.btn-left:hover:before {
  border-right-color: #f3f7f9;
}
.btn-default.btn-left.active:before,
.btn-default.btn-left:active:before,
.open > .btn-default.btn-left.dropdown-toggle:before {
  border-right-color: #ccd5db;
}

.btn-inverse {
  color: #76838f;
  border-color: #e4eaec;
  border-color: #e4eaec;
  background-color: #fff;
  background-color: #fff;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-box-shadow: none;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: none;
}
.btn-inverse:hover {
  color: #76838f;
  border-color: #c0ced3;
  background-color: #e6e6e6;
}
.btn-inverse:focus,
.btn-inverse.focus {
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 2px rgba(228, 234, 236, 0.5);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 2px rgba(228, 234, 236, 0.5);
}
.btn-inverse.disabled,
.btn-inverse:disabled {
  border-color: #e4eaec;
  background-color: #fff;
}
.btn-inverse:active,
.btn-inverse.active,
.show > .btn-inverse.dropdown-toggle {
  color: #76838f;
  border-color: #c0ced3;
  background-color: #e6e6e6;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse.focus {
  color: #76838f;
  border-color: #f3f7f9;
  background-color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-inverse:active,
.btn-inverse.active,
.open > .btn-inverse.dropdown-toggle {
  border-color: #ccd5db;
  background-color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-inverse:active:hover,
.btn-inverse:active:focus,
.btn-inverse:active.focus,
.btn-inverse.active:hover,
.btn-inverse.active:focus,
.btn-inverse.active.focus,
.open > .btn-inverse.dropdown-toggle:hover,
.open > .btn-inverse.dropdown-toggle:focus,
.open > .btn-inverse.dropdown-toggle.focus {
  border-color: #ccd5db;
  background-color: #fff;
}
.btn-inverse.disabled,
.btn-inverse.disabled:hover,
.btn-inverse.disabled:focus,
.btn-inverse.disabled.focus,
.btn-inverse.disabled:active,
.btn-inverse.disabled.active,
.btn-inverse[disabled],
.btn-inverse[disabled]:hover,
.btn-inverse[disabled]:focus,
.btn-inverse[disabled].focus,
.btn-inverse[disabled]:active,
.btn-inverse[disabled].active,
fieldset[disabled] .btn-inverse,
fieldset[disabled] .btn-inverse:hover,
fieldset[disabled] .btn-inverse:focus,
fieldset[disabled] .btn-inverse.focus,
fieldset[disabled] .btn-inverse:active,
fieldset[disabled] .btn-inverse.active {
  color: #ccd5db;
  border-color: #a3afb7;
  background-color: #fff;
}
.btn-inverse .badge-pill {
  color: #fff;
  background-color: #76838f;
}
.btn-inverse.btn-up:before {
  border-bottom-color: #fff;
}
.btn-inverse.btn-up:focus:before,
.btn-inverse.btn-up:hover:before {
  border-bottom-color: #fff;
}
.btn-inverse.btn-up.active:before,
.btn-inverse.btn-up:active:before,
.open > .btn-inverse.btn-up.dropdown-toggle:before {
  border-bottom-color: #fff;
}
.btn-inverse.btn-right:before {
  border-left-color: #fff;
}
.btn-inverse.btn-right:focus:before,
.btn-inverse.btn-right:hover:before {
  border-left-color: #fff;
}
.btn-inverse.btn-right.active:before,
.btn-inverse.btn-right:active:before,
.open > .btn-inverse.btn-right.dropdown-toggle:before {
  border-left-color: #fff;
}
.btn-inverse.btn-bottom:before {
  border-top-color: #fff;
}
.btn-inverse.btn-bottom:focus:before,
.btn-inverse.btn-bottom:hover:before {
  border-top-color: #fff;
}
.btn-inverse.btn-bottom.active:before,
.btn-inverse.btn-bottom:active:before,
.open > .btn-inverse.btn-bottom.dropdown-toggle:before {
  border-top-color: #fff;
}
.btn-inverse.btn-left:before {
  border-right-color: #fff;
}
.btn-inverse.btn-left:focus:before,
.btn-inverse.btn-left:hover:before {
  border-right-color: #fff;
}
.btn-inverse.btn-left.active:before,
.btn-inverse.btn-left:active:before,
.open > .btn-inverse.btn-left.dropdown-toggle:before {
  border-right-color: #fff;
}

.btn-dark {
  color: #fff;
  border-color: #526069;
  border-color: #526069;
  background-color: #526069;
  background-color: #526069;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-box-shadow: none;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: none;
}
.btn-dark:hover {
  color: #fff;
  border-color: #374147;
  background-color: #3c464c;
}
.btn-dark:focus,
.btn-dark.focus {
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 2px rgba(82, 96, 105, 0.5);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 2px rgba(82, 96, 105, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  border-color: #526069;
  background-color: #526069;
}
.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  border-color: #374147;
  background-color: #3c464c;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-dark:hover,
.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  border-color: #76838f;
  background-color: #76838f;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-dark:active,
.btn-dark.active,
.open > .btn-dark.dropdown-toggle {
  border-color: #37474f;
  background-color: #37474f;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-dark:active:hover,
.btn-dark:active:focus,
.btn-dark:active.focus,
.btn-dark.active:hover,
.btn-dark.active:focus,
.btn-dark.active.focus,
.open > .btn-dark.dropdown-toggle:hover,
.open > .btn-dark.dropdown-toggle:focus,
.open > .btn-dark.dropdown-toggle.focus {
  border-color: #37474f;
  background-color: #37474f;
}
.btn-dark.disabled,
.btn-dark.disabled:hover,
.btn-dark.disabled:focus,
.btn-dark.disabled.focus,
.btn-dark.disabled:active,
.btn-dark.disabled.active,
.btn-dark[disabled],
.btn-dark[disabled]:hover,
.btn-dark[disabled]:focus,
.btn-dark[disabled].focus,
.btn-dark[disabled]:active,
.btn-dark[disabled].active,
fieldset[disabled] .btn-dark,
fieldset[disabled] .btn-dark:hover,
fieldset[disabled] .btn-dark:focus,
fieldset[disabled] .btn-dark.focus,
fieldset[disabled] .btn-dark:active,
fieldset[disabled] .btn-dark.active {
  color: #fff;
  border-color: #a3afb7;
  background-color: #a3afb7;
}
.btn-dark .badge-pill {
  color: #526069;
  background-color: #fff;
}
.btn-dark.btn-up:before {
  border-bottom-color: #526069;
}
.btn-dark.btn-up:focus:before,
.btn-dark.btn-up:hover:before {
  border-bottom-color: #76838f;
}
.btn-dark.btn-up.active:before,
.btn-dark.btn-up:active:before,
.open > .btn-dark.btn-up.dropdown-toggle:before {
  border-bottom-color: #37474f;
}
.btn-dark.btn-right:before {
  border-left-color: #526069;
}
.btn-dark.btn-right:focus:before,
.btn-dark.btn-right:hover:before {
  border-left-color: #76838f;
}
.btn-dark.btn-right.active:before,
.btn-dark.btn-right:active:before,
.open > .btn-dark.btn-right.dropdown-toggle:before {
  border-left-color: #37474f;
}
.btn-dark.btn-bottom:before {
  border-top-color: #526069;
}
.btn-dark.btn-bottom:focus:before,
.btn-dark.btn-bottom:hover:before {
  border-top-color: #76838f;
}
.btn-dark.btn-bottom.active:before,
.btn-dark.btn-bottom:active:before,
.open > .btn-dark.btn-bottom.dropdown-toggle:before {
  border-top-color: #37474f;
}
.btn-dark.btn-left:before {
  border-right-color: #526069;
}
.btn-dark.btn-left:focus:before,
.btn-dark.btn-left:hover:before {
  border-right-color: #76838f;
}
.btn-dark.btn-left.active:before,
.btn-dark.btn-left:active:before,
.open > .btn-dark.btn-left.dropdown-toggle:before {
  border-right-color: #37474f;
}
.btn-dark.btn-flat {
  color: #526069;
}

.btn-flat {
  border: none;
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat.disabled {
  color: #a3afb7;
}

.btn-icon,
.btn.icon {
  line-height: 1em;

  padding: 0.715rem;
}
.btn-icon.btn-xs,
.btn-group-xs > .btn-icon.btn,
.btn.icon.btn-xs,
.btn-group-xs > .btn.icon {
  font-size: 0.858rem;

  padding: 0.286rem;
}
.btn-icon.btn-sm,
.btn.icon.btn-sm {
  font-size: 1rem;

  padding: 0.572rem;
}
.btn-icon.btn-lg,
.btn.icon.btn-lg {
  font-size: 1.429rem;

  padding: 0.858rem;
}
.btn-icon.disabled,
.btn.icon.disabled {
  color: #a3afb7;
}

.btn-icon .icon {
  margin: -1px 0 0;
}

.btn-raised {
  -webkit-transition: -webkit-box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1);

  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.18), 0 2px 4px rgba(0, 0, 0, 0.21);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.18), 0 2px 4px rgba(0, 0, 0, 0.21);
}
.btn-raised:hover,
.btn-raised:active,
.btn-raised.active,
.open > .btn-raised.dropdown-toggle {
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.2);
}
.btn-raised.disabled,
.btn-raised[disabled],
fieldset[disabled] .btn-raised {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-floating {
  font-size: 1.715rem;

  width: 4rem;
  height: 4rem;
  margin: 0;
  padding: 0;

  text-align: center;

  border-radius: 100%;
  -webkit-box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
.btn-floating.btn-xs,
.btn-group-xs > .btn-floating.btn {
  font-size: 0.929rem;

  width: 2.143rem;
  height: 2.143rem;
  padding: 0;
}
.btn-floating.btn-sm {
  font-size: 1.072rem;

  width: 2.858rem;
  height: 2.858rem;
  padding: 0;
}
.btn-floating.btn-lg {
  font-size: 2.143rem;

  width: 5rem;
  height: 5rem;
  padding: 0;
}
.btn-floating i {
  position: relative;
  top: 0;
}

.btn-animate {
  position: relative;

  overflow: hidden;
}
.btn-animate span {
  display: block;

  width: 100%;
  height: 100%;

  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}
.btn-animate-side {
  padding: 0.572rem 2rem;
}
.btn-animate-side span {
  -webkit-transition: -webkit-transform 0.2s ease-out 0s;
  -o-transition: -o-transform 0.2s ease-out 0s;
  transition: transform 0.2s ease-out 0s;
}
.btn-animate-side span > .icon {
  position: absolute;
  top: 50%;
  left: 0;

  display: block;

  -webkit-transition: opacity 0.2s ease-out 0s;
  -o-transition: opacity 0.2s ease-out 0s;
  transition: opacity 0.2s ease-out 0s;
  -webkit-transform: translate(-20px, -50%);
  -ms-transform: translate(-20px, -50%);
  -o-transform: translate(-20px, -50%);
  transform: translate(-20px, -50%);

  opacity: 0;
}
.btn-animate-side:hover span {
  -webkit-transform: translate(10px, 0px);
  -ms-transform: translate(10px, 0px);
  -o-transform: translate(10px, 0px);
  transform: translate(10px, 0px);
}
.btn-animate-side:hover span > .icon {
  opacity: 1;
}
.btn-animate-side.btn-xs,
.btn-group-xs > .btn-animate-side.btn {
  padding: 0.215rem 1rem;
}
.btn-animate-side.btn-xs span > .icon,
.btn-group-xs > .btn-animate-side.btn span > .icon {
  left: 5px;
}
.btn-animate-side.btn-xs:hover span,
.btn-group-xs > .btn-animate-side.btn:hover span {
  -webkit-transform: translate(8px, 0px);
  -ms-transform: translate(8px, 0px);
  -o-transform: translate(8px, 0px);
  transform: translate(8px, 0px);
}
.btn-animate-side.btn-sm {
  padding: 0.429rem 1.572rem;
}
.btn-animate-side.btn-sm span > .icon {
  left: 3px;
}
.btn-animate-side.btn-sm:hover span {
  -webkit-transform: translate(8px, 0px);
  -ms-transform: translate(8px, 0px);
  -o-transform: translate(8px, 0px);
  transform: translate(8px, 0px);
}
.btn-animate-side.btn-lg {
  padding: 0.715rem 2.358rem;
}
.btn-animate-side.btn-lg span > .icon {
  left: -6px;
}
.btn-animate-side.btn-lg:hover span {
  -webkit-transform: translate(14px, 0px);
  -ms-transform: translate(14px, 0px);
  -o-transform: translate(14px, 0px);
  transform: translate(14px, 0px);
}
.btn-animate-vertical span {
  -webkit-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
.btn-animate-vertical span > .icon {
  font-size: 1.715rem;

  position: absolute;
  top: -2px;
  left: 50%;

  display: block;

  -webkit-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  -o-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}
.btn-animate-vertical:hover span {
  -webkit-transform: translate(0, 150%);
  -ms-transform: translate(0, 150%);
  -o-transform: translate(0, 150%);
  transform: translate(0, 150%);
}
.btn-animate-vertical.btn-xs span > .icon,
.btn-group-xs > .btn-animate-vertical.btn span > .icon {
  font-size: 1.286rem;

  top: -5px;
}
.btn-animate-vertical.btn-sm span > .icon {
  font-size: 1.5rem;

  top: -3px;
}
.btn-animate-vertical.btn-lg span > .icon {
  font-size: 2.643rem;
}

.btn-tagged {
  padding: 0;
  padding-right: 0.572rem;
}
.btn-tagged .btn-tag {
  margin-right: 0.357rem;
  padding: 0.429rem 0.572rem;
}
.btn-tagged.btn-xs,
.btn-group-xs > .btn-tagged.btn {
  padding-right: 0.358rem;
}
.btn-tagged.btn-xs .btn-tag,
.btn-group-xs > .btn-tagged.btn .btn-tag {
  margin-right: 0.143rem;
  padding: 0.072rem 0.286rem;
}
.btn-tagged.btn-sm {
  padding-right: 0.858rem;
}
.btn-tagged.btn-sm .btn-tag {
  margin-right: 0.643rem;
  padding: 0.429rem 0.429rem;
}
.btn-tagged.btn-lg {
  padding-right: 1rem;
}
.btn-tagged.btn-lg .btn-tag {
  margin-right: 0.785rem;
  padding: 0.715rem 1rem;
}
.btn-tagged.btn-block {
  text-align: left;
}

.btn-tag {
  display: inline-block;

  border-radius: 0.215rem 0 0 0.215rem;
  background-color: rgba(0, 0, 0, 0.15);
}

.btn-pill-left {
  border-radius: 500px 0 0 500px;
}

.btn-pill-right {
  border-radius: 0 500px 500px 0;
}

.btn-direction {
  position: relative;
}
.btn-direction:before {
  line-height: 0;

  position: absolute;

  content: "";

  border: 8px solid transparent;
}

.btn-up:before {
  top: -16px;
  left: 50%;

  margin-left: -8px;

  border-bottom-color: #e4eaec;
}

.btn-right:before {
  top: 50%;
  right: -16px;

  margin-top: -8px;

  border-left-color: #e4eaec;
}

.btn-bottom:before {
  bottom: -16px;
  left: 50%;

  margin-left: -8px;

  border-top-color: #e4eaec;
}

.btn-left:before {
  top: 50%;
  left: -16px;

  margin-top: -8px;

  border-right-color: #e4eaec;
}

.btn-pure,
.btn-pure:hover,
.btn-pure:focus,
.btn-pure:active,
.btn-pure.active,
.open > .btn-pure.dropdown-toggle,
.btn-pure[disabled],
fieldset[disabled] .btn-pure {
  border-color: transparent;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-pure:hover,
.btn-pure:focus,
.btn-pure.focus,
.btn-pure:hover:hover,
.btn-pure:hover:focus,
.btn-pure:hover.focus,
.btn-pure:focus:hover,
.btn-pure:focus:focus,
.btn-pure:focus.focus,
.btn-pure:active:hover,
.btn-pure:active:focus,
.btn-pure:active.focus,
.btn-pure.active:hover,
.btn-pure.active:focus,
.btn-pure.active.focus,
.open > .btn-pure.dropdown-toggle:hover,
.open > .btn-pure.dropdown-toggle:focus,
.open > .btn-pure.dropdown-toggle.focus,
.btn-pure[disabled]:hover,
.btn-pure[disabled]:focus,
.btn-pure[disabled].focus,
fieldset[disabled] .btn-pure:hover,
fieldset[disabled] .btn-pure:focus,
fieldset[disabled] .btn-pure.focus {
  border-color: transparent;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-pure.btn-default {
  color: #a3afb7;
}
.btn-pure.btn-default:hover,
.btn-pure.btn-default:focus,
.btn-pure.btn-default:active,
.btn-pure.btn-default.active,
.open > .btn-pure.btn-default.dropdown-toggle {
  color: #ccd5db;
}
.btn-pure.btn-default:hover:hover,
.btn-pure.btn-default:hover:focus,
.btn-pure.btn-default:hover.focus,
.btn-pure.btn-default:focus:hover,
.btn-pure.btn-default:focus:focus,
.btn-pure.btn-default:focus.focus,
.btn-pure.btn-default:active:hover,
.btn-pure.btn-default:active:focus,
.btn-pure.btn-default:active.focus,
.btn-pure.btn-default.active:hover,
.btn-pure.btn-default.active:focus,
.btn-pure.btn-default.active.focus,
.open > .btn-pure.btn-default.dropdown-toggle:hover,
.open > .btn-pure.btn-default.dropdown-toggle:focus,
.open > .btn-pure.btn-default.dropdown-toggle.focus {
  color: #ccd5db;
}
.btn-pure.btn-default:hover .badge-pill,
.btn-pure.btn-default:focus .badge-pill,
.btn-pure.btn-default:active .badge-pill,
.btn-pure.btn-default.active .badge-pill,
.open > .btn-pure.btn-default.dropdown-toggle .badge-pill {
  color: #ccd5db;
}

.btn-pure.btn-primary {
  color: #00b3d4;
}
.btn-pure.btn-primary:hover,
.btn-pure.btn-primary:focus,
.btn-pure.btn-primary:active,
.btn-pure.btn-primary.active,
.open > .btn-pure.btn-primary.dropdown-toggle {
  color: #a2caee;
}
.btn-pure.btn-primary:hover:hover,
.btn-pure.btn-primary:hover:focus,
.btn-pure.btn-primary:hover.focus,
.btn-pure.btn-primary:focus:hover,
.btn-pure.btn-primary:focus:focus,
.btn-pure.btn-primary:focus.focus,
.btn-pure.btn-primary:active:hover,
.btn-pure.btn-primary:active:focus,
.btn-pure.btn-primary:active.focus,
.btn-pure.btn-primary.active:hover,
.btn-pure.btn-primary.active:focus,
.btn-pure.btn-primary.active.focus,
.open > .btn-pure.btn-primary.dropdown-toggle:hover,
.open > .btn-pure.btn-primary.dropdown-toggle:focus,
.open > .btn-pure.btn-primary.dropdown-toggle.focus {
  color: #a2caee;
}
.btn-pure.btn-primary:hover .badge-pill,
.btn-pure.btn-primary:focus .badge-pill,
.btn-pure.btn-primary:active .badge-pill,
.btn-pure.btn-primary.active .badge-pill,
.open > .btn-pure.btn-primary.dropdown-toggle .badge-pill {
  color: #a2caee;
}

.btn-pure.btn-success {
  color: #8ebe31;
}
.btn-pure.btn-success:hover,
.btn-pure.btn-success:focus,
.btn-pure.btn-success:active,
.btn-pure.btn-success.active,
.open > .btn-pure.btn-success.dropdown-toggle {
  color: #7dd3ae;
}
.btn-pure.btn-success:hover:hover,
.btn-pure.btn-success:hover:focus,
.btn-pure.btn-success:hover.focus,
.btn-pure.btn-success:focus:hover,
.btn-pure.btn-success:focus:focus,
.btn-pure.btn-success:focus.focus,
.btn-pure.btn-success:active:hover,
.btn-pure.btn-success:active:focus,
.btn-pure.btn-success:active.focus,
.btn-pure.btn-success.active:hover,
.btn-pure.btn-success.active:focus,
.btn-pure.btn-success.active.focus,
.open > .btn-pure.btn-success.dropdown-toggle:hover,
.open > .btn-pure.btn-success.dropdown-toggle:focus,
.open > .btn-pure.btn-success.dropdown-toggle.focus {
  color: #7dd3ae;
}
.btn-pure.btn-success:hover .badge-pill,
.btn-pure.btn-success:focus .badge-pill,
.btn-pure.btn-success:active .badge-pill,
.btn-pure.btn-success.active .badge-pill,
.open > .btn-pure.btn-success.dropdown-toggle .badge-pill {
  color: #7dd3ae;
}

.btn-pure.btn-info {
  color: #57c7d4;
}
.btn-pure.btn-info:hover,
.btn-pure.btn-info:focus,
.btn-pure.btn-info:active,
.btn-pure.btn-info.active,
.open > .btn-pure.btn-info.dropdown-toggle {
  color: #9ae1e9;
}
.btn-pure.btn-info:hover:hover,
.btn-pure.btn-info:hover:focus,
.btn-pure.btn-info:hover.focus,
.btn-pure.btn-info:focus:hover,
.btn-pure.btn-info:focus:focus,
.btn-pure.btn-info:focus.focus,
.btn-pure.btn-info:active:hover,
.btn-pure.btn-info:active:focus,
.btn-pure.btn-info:active.focus,
.btn-pure.btn-info.active:hover,
.btn-pure.btn-info.active:focus,
.btn-pure.btn-info.active.focus,
.open > .btn-pure.btn-info.dropdown-toggle:hover,
.open > .btn-pure.btn-info.dropdown-toggle:focus,
.open > .btn-pure.btn-info.dropdown-toggle.focus {
  color: #9ae1e9;
}
.btn-pure.btn-info:hover .badge-pill,
.btn-pure.btn-info:focus .badge-pill,
.btn-pure.btn-info:active .badge-pill,
.btn-pure.btn-info.active .badge-pill,
.open > .btn-pure.btn-info.dropdown-toggle .badge-pill {
  color: #9ae1e9;
}

.btn-pure.btn-warning {
  color: #f2a654;
}
.btn-pure.btn-warning:hover,
.btn-pure.btn-warning:focus,
.btn-pure.btn-warning:active,
.btn-pure.btn-warning.active,
.open > .btn-pure.btn-warning.dropdown-toggle {
  color: #f6be80;
}
.btn-pure.btn-warning:hover:hover,
.btn-pure.btn-warning:hover:focus,
.btn-pure.btn-warning:hover.focus,
.btn-pure.btn-warning:focus:hover,
.btn-pure.btn-warning:focus:focus,
.btn-pure.btn-warning:focus.focus,
.btn-pure.btn-warning:active:hover,
.btn-pure.btn-warning:active:focus,
.btn-pure.btn-warning:active.focus,
.btn-pure.btn-warning.active:hover,
.btn-pure.btn-warning.active:focus,
.btn-pure.btn-warning.active.focus,
.open > .btn-pure.btn-warning.dropdown-toggle:hover,
.open > .btn-pure.btn-warning.dropdown-toggle:focus,
.open > .btn-pure.btn-warning.dropdown-toggle.focus {
  color: #f6be80;
}
.btn-pure.btn-warning:hover .badge-pill,
.btn-pure.btn-warning:focus .badge-pill,
.btn-pure.btn-warning:active .badge-pill,
.btn-pure.btn-warning.active .badge-pill,
.open > .btn-pure.btn-warning.dropdown-toggle .badge-pill {
  color: #f6be80;
}

.btn-pure.btn-danger {
  color: #f96868;
}
.btn-pure.btn-danger:hover,
.btn-pure.btn-danger:focus,
.btn-pure.btn-danger:active,
.btn-pure.btn-danger.active,
.open > .btn-pure.btn-danger.dropdown-toggle {
  color: #fa9898;
}
.btn-pure.btn-danger:hover:hover,
.btn-pure.btn-danger:hover:focus,
.btn-pure.btn-danger:hover.focus,
.btn-pure.btn-danger:focus:hover,
.btn-pure.btn-danger:focus:focus,
.btn-pure.btn-danger:focus.focus,
.btn-pure.btn-danger:active:hover,
.btn-pure.btn-danger:active:focus,
.btn-pure.btn-danger:active.focus,
.btn-pure.btn-danger.active:hover,
.btn-pure.btn-danger.active:focus,
.btn-pure.btn-danger.active.focus,
.open > .btn-pure.btn-danger.dropdown-toggle:hover,
.open > .btn-pure.btn-danger.dropdown-toggle:focus,
.open > .btn-pure.btn-danger.dropdown-toggle.focus {
  color: #fa9898;
}
.btn-pure.btn-danger:hover .badge-pill,
.btn-pure.btn-danger:focus .badge-pill,
.btn-pure.btn-danger:active .badge-pill,
.btn-pure.btn-danger.active .badge-pill,
.open > .btn-pure.btn-danger.dropdown-toggle .badge-pill {
  color: #fa9898;
}

.btn-pure.btn-dark {
  color: #526069;
}
.btn-pure.btn-dark:hover,
.btn-pure.btn-dark:focus,
.btn-pure.btn-dark:active,
.btn-pure.btn-dark.active,
.open > .btn-pure.btn-dark.dropdown-toggle {
  color: #76838f;
}
.btn-pure.btn-dark:hover:hover,
.btn-pure.btn-dark:hover:focus,
.btn-pure.btn-dark:hover.focus,
.btn-pure.btn-dark:focus:hover,
.btn-pure.btn-dark:focus:focus,
.btn-pure.btn-dark:focus.focus,
.btn-pure.btn-dark:active:hover,
.btn-pure.btn-dark:active:focus,
.btn-pure.btn-dark:active.focus,
.btn-pure.btn-dark.active:hover,
.btn-pure.btn-dark.active:focus,
.btn-pure.btn-dark.active.focus,
.open > .btn-pure.btn-dark.dropdown-toggle:hover,
.open > .btn-pure.btn-dark.dropdown-toggle:focus,
.open > .btn-pure.btn-dark.dropdown-toggle.focus {
  color: #76838f;
}
.btn-pure.btn-dark:hover .badge-pill,
.btn-pure.btn-dark:focus .badge-pill,
.btn-pure.btn-dark:active .badge-pill,
.btn-pure.btn-dark.active .badge-pill,
.open > .btn-pure.btn-dark.dropdown-toggle .badge-pill {
  color: #76838f;
}

.btn-pure.btn-inverse {
  color: #fff;
}
.btn-pure.btn-inverse:hover,
.btn-pure.btn-inverse:focus,
.btn-pure.btn-inverse:active,
.btn-pure.btn-inverse.active,
.open > .btn-pure.btn-inverse.dropdown-toggle {
  color: #fff;
}
.btn-pure.btn-inverse:hover:hover,
.btn-pure.btn-inverse:hover:focus,
.btn-pure.btn-inverse:hover.focus,
.btn-pure.btn-inverse:focus:hover,
.btn-pure.btn-inverse:focus:focus,
.btn-pure.btn-inverse:focus.focus,
.btn-pure.btn-inverse:active:hover,
.btn-pure.btn-inverse:active:focus,
.btn-pure.btn-inverse:active.focus,
.btn-pure.btn-inverse.active:hover,
.btn-pure.btn-inverse.active:focus,
.btn-pure.btn-inverse.active.focus,
.open > .btn-pure.btn-inverse.dropdown-toggle:hover,
.open > .btn-pure.btn-inverse.dropdown-toggle:focus,
.open > .btn-pure.btn-inverse.dropdown-toggle.focus {
  color: #fff;
}
.btn-pure.btn-inverse:hover .badge-pill,
.btn-pure.btn-inverse:focus .badge-pill,
.btn-pure.btn-inverse:active .badge-pill,
.btn-pure.btn-inverse.active .badge-pill,
.open > .btn-pure.btn-inverse.dropdown-toggle .badge-pill {
  color: #fff;
}

.dropdown-toggle:after {
  margin-right: 0;
  margin-left: 0.3em;
}

.dropdown-toggle.btn-xs:after,
.btn-group-xs > .dropdown-toggle.btn:after {
  margin-left: 0;
}

.dropdown-item {
  width: -webkit-calc(100% - 10px);
  width: calc(100% - 10px);
  margin: 2px 5px;
  padding: 0.572rem 1.072rem;

  -webkit-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  transition: background-color 0.25s;

  border-radius: 0.215rem;
}
.dropdown-item .icon:first-child {
  width: 1em;
  margin-right: 0.5em;

  text-align: center;
}

.dropdown-divider {
  margin: 6px 0;
}

/* .dropdown-menu { */
/* -webkit-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s; */
/* } */
.dropdown-menu.dropdown-menu-bullet {
  margin-top: 12px;
}
.dropdown-menu.dropdown-menu-bullet:before,
.dropdown-menu.dropdown-menu-bullet:after {
  position: absolute;
  left: 10px;

  display: inline-block;

  width: 0;
  height: 0;

  content: "";

  border: 7px solid transparent;
  border-top-width: 0;
}
.dropdown-menu.dropdown-menu-bullet:before {
  top: -7px;

  border-bottom-color: #e4eaec;
}
.dropdown-menu.dropdown-menu-bullet:after {
  top: -6px;

  border-bottom-color: #fff;
}
.dropdown-menu-right.dropdown-menu-bullet:before,
.dropdown-menu-right.dropdown-menu-bullet:after {
  right: 10px;
  left: auto;
}
.dropdown-menu.animate {
  overflow: hidden;
}
.dropdown-menu.animate > .dropdown-item {
  -webkit-animation: slide-left 0.5s both;
  -o-animation: slide-left 0.5s both;
  animation: slide-left 0.5s both;
}
.dropdown-menu.animate > .dropdown-item:nth-child(1) {
  -webkit-animation: animation-delay(0.02s);
  -o-animation: animation-delay(0.02s);
  animation: animation-delay(0.02s);
}
.dropdown-menu.animate > .dropdown-item:nth-child(2) {
  -webkit-animation: animation-delay(0.04s);
  -o-animation: animation-delay(0.04s);
  animation: animation-delay(0.04s);
}
.dropdown-menu.animate > .dropdown-item:nth-child(3) {
  -webkit-animation: animation-delay(0.06s);
  -o-animation: animation-delay(0.06s);
  animation: animation-delay(0.06s);
}
.dropdown-menu.animate > .dropdown-item:nth-child(4) {
  -webkit-animation: animation-delay(0.08s);
  -o-animation: animation-delay(0.08s);
  animation: animation-delay(0.08s);
}
.dropdown-menu.animate > .dropdown-item:nth-child(5) {
  -webkit-animation: animation-delay(0.1s);
  -o-animation: animation-delay(0.1s);
  animation: animation-delay(0.1s);
}
.dropdown-menu.animate > .dropdown-item:nth-child(6) {
  -webkit-animation: animation-delay(0.12s);
  -o-animation: animation-delay(0.12s);
  animation: animation-delay(0.12s);
}
.dropdown-menu.animate > .dropdown-item:nth-child(7) {
  -webkit-animation: animation-delay(0.14s);
  -o-animation: animation-delay(0.14s);
  animation: animation-delay(0.14s);
}
.dropdown-menu.animate > .dropdown-item:nth-child(8) {
  -webkit-animation: animation-delay(0.16s);
  -o-animation: animation-delay(0.16s);
  animation: animation-delay(0.16s);
}
.dropdown-menu.animate > .dropdown-item:nth-child(9) {
  -webkit-animation: animation-delay(0.18s);
  -o-animation: animation-delay(0.18s);
  animation: animation-delay(0.18s);
}
.dropdown-menu.animate > .dropdown-item:nth-child(10) {
  -webkit-animation: animation-delay(0.2s);
  -o-animation: animation-delay(0.2s);
  animation: animation-delay(0.2s);
}
.dropdown-menu.animate > .dropdown-item.dropdown-divider {
  -webkit-animation-name: none;
  -o-animation-name: none;
  animation-name: none;
}
.dropdown-menu.animate.animate-reverse > .dropdown-item:nth-last-child(1) {
  -webkit-animation: animation-delay(0.02s);
  -o-animation: animation-delay(0.02s);
  animation: animation-delay(0.02s);
}
.dropdown-menu.animate.animate-reverse > .dropdown-item:nth-last-child(2) {
  -webkit-animation: animation-delay(0.04s);
  -o-animation: animation-delay(0.04s);
  animation: animation-delay(0.04s);
}
.dropdown-menu.animate.animate-reverse > .dropdown-item:nth-last-child(3) {
  -webkit-animation: animation-delay(0.06s);
  -o-animation: animation-delay(0.06s);
  animation: animation-delay(0.06s);
}
.dropdown-menu.animate.animate-reverse > .dropdown-item:nth-last-child(4) {
  -webkit-animation: animation-delay(0.08s);
  -o-animation: animation-delay(0.08s);
  animation: animation-delay(0.08s);
}
.dropdown-menu.animate.animate-reverse > .dropdown-item:nth-last-child(5) {
  -webkit-animation: animation-delay(0.1s);
  -o-animation: animation-delay(0.1s);
  animation: animation-delay(0.1s);
}
.dropdown-menu.animate.animate-reverse > .dropdown-item:nth-last-child(6) {
  -webkit-animation: animation-delay(0.12s);
  -o-animation: animation-delay(0.12s);
  animation: animation-delay(0.12s);
}
.dropdown-menu.animate.animate-reverse > .dropdown-item:nth-last-child(7) {
  -webkit-animation: animation-delay(0.14s);
  -o-animation: animation-delay(0.14s);
  animation: animation-delay(0.14s);
}
.dropdown-menu.animate.animate-reverse > .dropdown-item:nth-last-child(8) {
  -webkit-animation: animation-delay(0.16s);
  -o-animation: animation-delay(0.16s);
  animation: animation-delay(0.16s);
}
.dropdown-menu.animate.animate-reverse > .dropdown-item:nth-last-child(9) {
  -webkit-animation: animation-delay(0.18s);
  -o-animation: animation-delay(0.18s);
  animation: animation-delay(0.18s);
}
.dropdown-menu.animate.animate-reverse > .dropdown-item:nth-last-child(10) {
  -webkit-animation: animation-delay(0.2s);
  -o-animation: animation-delay(0.2s);
  animation: animation-delay(0.2s);
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  margin-bottom: 6px;

  -webkit-box-shadow: 0 -3px 12px rgba(0, 0, 0, 0.05);
  box-shadow: 0 -3px 12px rgba(0, 0, 0, 0.05);
}
.dropup .dropdown-menu.dropdown-menu-bullet,
.navbar-fixed-bottom .dropdown .dropdown-menu.dropdown-menu-bullet {
  margin-bottom: 12px;
}
.dropup .dropdown-menu.dropdown-menu-bullet:before,
.dropup .dropdown-menu.dropdown-menu-bullet:after,
.navbar-fixed-bottom .dropdown .dropdown-menu.dropdown-menu-bullet:before,
.navbar-fixed-bottom .dropdown .dropdown-menu.dropdown-menu-bullet:after {
  top: auto;

  border-top-width: 7px;
  border-bottom-width: 0;
}
.dropup .dropdown-menu.dropdown-menu-bullet:before,
.navbar-fixed-bottom .dropdown .dropdown-menu.dropdown-menu-bullet:before {
  bottom: -7px;

  border-top-color: #e4eaec;
}
.dropup .dropdown-menu.dropdown-menu-bullet:after,
.navbar-fixed-bottom .dropdown .dropdown-menu.dropdown-menu-bullet:after {
  bottom: -6px;

  border-top-color: #fff;
}

.dropdown-menu > .dropdown-header {
  font-size: 1rem;
  font-weight: 500;

  padding: 8px 20px 6px;

  cursor: default;
}

.dropdown-menu > .dropdown-submenu {
  position: relative;
}
.dropdown-menu > .dropdown-submenu > .dropdown-item {
  position: relative;
}
.dropdown-menu > .dropdown-submenu > .dropdown-item:after {
  position: absolute;
  right: 10px;

  display: inline-block;

  width: 0;
  height: 0;
  margin-top: 6px;

  content: "";
  vertical-align: middle;

  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px dashed;
}
.dropdown-menu > .dropdown-submenu .dropdown-menu {
  left: 100%;

  margin: 0;
}
.dropdown-menu > .dropdown-submenu.dropdown-menu-left .dropdown-menu {
  left: -100%;
}
.dropdown-menu > .dropdown-submenu:hover .dropdown-menu {
  display: block;
}

.dropdown .dropdown-submenu .dropdown-menu {
  top: 0;
}

.dropup .dropdown-submenu .dropdown-menu {
  bottom: 0;
}

.dropdown-menu-media {
  width: 360px;
  padding-top: 0;
  padding-bottom: 0;
}
.dropdown-menu-media > li {
  margin: 0;
  padding: 0;
}
.dropdown-menu-media .dropdown-menu-header {
  position: relative;

  padding: 20px 20px;

  border-bottom: 1px solid #e4eaec;
  background-color: #fff;
}
.dropdown-menu-media .dropdown-menu-header > h3,
.dropdown-menu-media .dropdown-menu-header > h4,
.dropdown-menu-media .dropdown-menu-header > h5 {
  margin: 0;
}
.dropdown-menu-media .dropdown-menu-header .badge {
  position: absolute;
  top: 50%;
  right: 20px;

  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.dropdown-menu-media .list-group {
  font-size: 0.858rem;

  max-height: 270px;
  margin: 0;

  border-radius: 0;
}
.dropdown-menu-media .list-group-item {
  margin: 0;
  padding: 0 20px;

  border: none;
  border-radius: 0 !important;
}
.dropdown-menu-media .list-group-item .media {
  padding: 15px 0;

  border-top: 1px solid #e4eaec;
}
.dropdown-menu-media .list-group-item:first-child .media {
  border-top: none;
}
.dropdown-menu-media > .dropdown-menu-footer {
  border-top: 1px solid #e4eaec;
  background-color: #f3f7f9;
}
.dropdown-menu-media > .dropdown-menu-footer > a {
  margin: 0;
  padding: 15px 20px !important;

  color: #a3afb7 !important;
}
.dropdown-menu-media > .dropdown-menu-footer > a:hover {
  color: #89bceb !important;
  background-color: transparent !important;
}
.dropdown-menu-media > .dropdown-menu-footer > .dropdown-menu-footer-btn {
  position: absolute;
  right: 0;
}
.dropdown-menu-media > .dropdown-menu-footer > .dropdown-menu-footer-btn:hover {
  color: #89bceb !important;
  background-color: transparent !important;
}

.dropdown-menu-primary .dropdown-iten.active,
.dropdown-menu-primary .dropdown-iten.active:hover,
.dropdown-menu-primary .dropdown-iten.active:focus {
  color: #fff;
  background-color: #00b3d4;
}

.dropdown-menu-success .dropdown-iten.active,
.dropdown-menu-success .dropdown-iten.active:hover,
.dropdown-menu-success .dropdown-iten.active:focus {
  color: #fff;
  background-color: #8ebe31;
}

.dropdown-menu-info .dropdown-iten.active,
.dropdown-menu-info .dropdown-iten.active:hover,
.dropdown-menu-info .dropdown-iten.active:focus {
  color: #fff;
  background-color: #57c7d4;
}

.dropdown-menu-warning .dropdown-iten.active,
.dropdown-menu-warning .dropdown-iten.active:hover,
.dropdown-menu-warning .dropdown-iten.active:focus {
  color: #fff;
  background-color: #f2a654;
}

.dropdown-menu-danger .dropdown-iten.active,
.dropdown-menu-danger .dropdown-iten.active:hover,
.dropdown-menu-danger .dropdown-iten.active:focus {
  color: #fff;
  background-color: #f96868;
}

.dropdown-menu-dark .dropdown-iten.active,
.dropdown-menu-dark .dropdown-iten.active:hover,
.dropdown-menu-dark .dropdown-iten.active:focus {
  color: #fff;
  background-color: #526069;
}

.btn-group .btn ~ .dropdown-toggle {
  margin-left: -1px;
  padding-right: 0.8em;
  padding-left: 0.8em;
}
.btn-group .btn ~ .dropdown-toggle:after {
  margin-left: 0;
}

.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
}

.btn-group:focus .dropdown-toggle {
  -webkit-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
}

.btn-group-justified {
  display: table;

  width: 100%;

  table-layout: fixed;
  border-collapse: separate;
}
.btn-group-justified > .btn,
.btn-group-justified > .btn-group {
  display: table-cell;
  float: none;

  width: 1%;
}
.btn-group-justified > .btn-group .btn {
  width: 100%;
}
.btn-group-justified > .btn-group .dropdown-menu {
  left: auto;
}

.input-group-addon {
  -webkit-transition: border 0.25s linear, color 0.25s linear,
    background-color 0.25s linear;
  -o-transition: border 0.25s linear, color 0.25s linear,
    background-color 0.25s linear;
  transition: border 0.25s linear, color 0.25s linear,
    background-color 0.25s linear;
}

.input-group-btn .btn {
  padding: 6px 10px;
}
.input-group-btn .btn > .icon {
  line-height: inherit;

  vertical-align: bottom;
}
.input-group-btn .btn ~ .dropdown-toggle:after {
  margin-left: 0;
}

.input-group-lg .input-group-addon,
.input-group-lg .input-group-btn {
  line-height: 1.333333;
}

.input-group-sm .input-group-addon,
.input-group-sm .input-group-btn {
  line-height: 1.5;
}

.form-icons .form-control-icon {
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 10px;

  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.form-icons .form-control-icon + .form-control {
  padding-left: 30px;

  border-top-left-radius: 0.215rem;
  border-bottom-left-radius: 0.215rem;
}

.form-icons .round-input-control .form-control {
  border-radius: 200px;
}

.form-icons .large-icon .form-control {
  height: 3.2rem !important;
}

.form-icons .small-icon .form-control {
  height: 2.2rem !important;
}

.form-icons .form-control-icon-right {
  right: 10px;
  left: auto;
}
.form-icons .form-control-icon-right + .form-control {
  padding-right: 30px;
  padding-left: 1rem;
}

.nav-link {
  position: relative;

  display: block;
  overflow: hidden;

  padding: 0.715rem 1.072rem;
}
.nav-link:focus {
  outline: none;
}
.nav-link:focus,
.nav-link:hover {
  background-color: #f3f7f9;
}
.nav-link.disabled:focus,
.nav-link.disabled:hover {
  background-color: transparent;
}
.nav-link .close {
  display: inline-block;

  margin-left: 10px;
}

.nav-quick {
  margin-right: 0;
  margin-bottom: 22px;
  margin-left: 0;
  padding: 0;

  border-radius: 0.215rem;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.nav-quick .nav-item {
  position: relative;

  display: block;

  padding: 0;

  list-style: none;

  text-align: center;
}
.nav-quick .nav-link {
  padding: 1.143rem 0;

  color: #76838f;
}
.nav-quick .nav-link .icon {
  font-size: 2.286rem;

  display: block;

  margin-bottom: 0.2rem;
}
.nav-quick .badge,
.nav-quick .badge-pill {
  position: absolute;
  top: 0;
  right: 0;
}
.nav-quick-sm .nav-link {
  padding: 0.858rem 0;
}
.nav-quick-sm .nav-link .icon {
  font-size: 1.7145rem;
}
.nav-quick-lg .nav-link {
  padding: 1.572rem 0;
}
.nav-quick-lg .nav-link .icon {
  font-size: 2.8575rem;
}
.nav-quick-bordered {
  border-top: 1px solid #e4eaec;
  border-left: 1px solid #e4eaec;
}
.nav-quick-bordered .nav-item {
  border-right: 1px solid #e4eaec;
  border-bottom: 1px solid #e4eaec;
}

.nav-tabs .nav-link {
  -webkit-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;

  color: #76838f;
}
.nav-tabs .nav-link > .icon {
  line-height: 1;

  margin-right: 0.5em;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:focus,
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-item.show .nav-link:focus,
.nav-tabs .nav-item.show .nav-link:hover,
.nav-tabs .nav-item.open .nav-link,
.nav-tabs .nav-item.open .nav-link:focus,
.nav-tabs .nav-item.open .nav-link:hover {
  border-color: transparent;
  border-bottom-color: #00b3d4;
}

.nav-tabs .dropup .dropdown-menu {
  margin-bottom: -1px;

  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.nav-pills .nav-link {
  -webkit-transition: border 0.2s linear 0s, color 0.2s linear 0s,
    background-color 0.2s linear 0s;
  -o-transition: border 0.2s linear 0s, color 0.2s linear 0s,
    background-color 0.2s linear 0s;
  transition: border 0.2s linear 0s, color 0.2s linear 0s,
    background-color 0.2s linear 0s;
}

.nav-pills-rounded .nav-link {
  margin-right: 5px;
  margin-left: 5px;
  padding-right: 20px;
  padding-left: 20px;

  border-radius: 1000px;
}

.nav-tabs.nav-tabs-bottom {
  border-top: 1px solid #e4eaec;
  border-bottom: none;
}
.nav-tabs.nav-tabs-bottom .nav-item {
  margin-top: -1px;
  margin-bottom: 0;
}
.nav-tabs.nav-tabs-bottom .nav-link {
  border-radius: 0 0 0.286rem 0.286rem;
}
.nav-tabs.nav-tabs-bottom .nav-link:hover,
.nav-tabs.nav-tabs-bottom .nav-link:focus {
  border-top-color: #e4eaec;
  border-bottom-color: transparent;
}

.nav-tabs-reverse .nav-item {
  float: right;
}
.nav-tabs-reverse .nav-item + .nav-item {
  margin-right: 2px;
  margin-left: 0;
}

.nav-tabs-solid {
  border-bottom-color: #f3f7f9;
}
.nav-tabs-solid .nav-link:hover {
  border-color: transparent;
}
.nav-tabs-solid .nav-link.active,
.nav-tabs-solid .nav-link.active:focus,
.nav-tabs-solid .nav-link.active:hover,
.nav-tabs-solid .nav-item.show .nav-link,
.nav-tabs-solid .nav-item.show .nav-link:focus,
.nav-tabs-solid .nav-item.show .nav-link:hover,
.nav-tabs-solid .nav-item.open .nav-link,
.nav-tabs-solid .nav-item.open .nav-link:focus,
.nav-tabs-solid .nav-item.open .nav-link:hover {
  color: #76838f;
  border-color: transparent;
  background-color: #f3f7f9;
}
.nav-tabs-solid ~ .tab-content {
  padding: 1.429rem;

  background-color: #f3f7f9;
}
.nav-tabs-solid.nav-tabs-bottom .nav-link.active,
.nav-tabs-solid.nav-tabs-bottom .nav-link.active:hover,
.nav-tabs-solid.nav-tabs-bottom .nav-link.active:focus {
  border: none;
}

.nav-tabs-line {
  position: relative;
}
.nav-tabs-line .nav-link {
  padding: 0.715rem 1.429rem;

  border-bottom: 2px solid transparent;
}
.nav-tabs-line .nav-link:hover,
.nav-tabs-line .nav-link:focus {
  background-color: transparent;
}
.nav-tabs-line .nav-link:hover {
  border-bottom-color: #ccd5db;
}
.nav-tabs-line .nav-link.active,
.nav-tabs-line .nav-link.active:focus,
.nav-tabs-line .nav-link.active:hover,
.nav-tabs-line .nav-item.show .nav-link,
.nav-tabs-line .nav-item.show .nav-link:focus,
.nav-tabs-line .nav-item.show .nav-link:hover,
.nav-tabs-line .nav-item.open .nav-link,
.nav-tabs-line .nav-item.open .nav-link:focus,
.nav-tabs-line .nav-item.open .nav-link:hover {
  color: #00b3d4;
  border-bottom: 2px solid #00b3d4;
  background-color: transparent;
}
.nav-tabs-line .dropdown-menu {
  margin-top: 0;
}
.nav-tabs-line .dropup .dropdown-menu {
  margin-bottom: 0;
}
.nav-tabs-line .nav-item.open > .nav-link,
.nav-tabs-line .nav-item.open > .nav-link:focus,
.nav-tabs-line .nav-item.open > .nav-link:hover,
.nav-tabs-line .nav-item.show > .nav-link,
.nav-tabs-line .nav-item.show > .nav-link:focus,
.nav-tabs-line .nav-item.show > .nav-link:hover {
  border-bottom-color: #00b3d4;
}
.nav-tabs-line.nav-tabs-bottom .nav-link {
  border-top: 2px solid transparent;
  border-bottom: none;
}
.nav-tabs-line.nav-tabs-bottom .nav-link:hover {
  border-top-color: #ccd5db;
  border-bottom-color: transparent;
}
.nav-tabs-line.nav-tabs-bottom .nav-link.active,
.nav-tabs-line.nav-tabs-bottom .nav-link.active:focus,
.nav-tabs-line.nav-tabs-bottom .nav-link.active:hover,
.nav-tabs-line.nav-tabs-bottom .nav-item.open .nav-link,
.nav-tabs-line.nav-tabs-bottom .nav-item.open .nav-link:focus,
.nav-tabs-line.nav-tabs-bottom .nav-item.open .nav-link:hover,
.nav-tabs-line.nav-tabs-bottom .nav-item.show .nav-link,
.nav-tabs-line.nav-tabs-bottom .nav-item.show .nav-link:focus,
.nav-tabs-line.nav-tabs-bottom .nav-item.show .nav-link:hover {
  border-top: 2px solid #00b3d4;
  border-bottom: none;
}
.nav-tabs-line.nav-tabs-bottom .nav-item.open > .nav-link,
.nav-tabs-line.nav-tabs-bottom .nav-item.open > .nav-link:focus,
.nav-tabs-line.nav-tabs-bottom .nav-item.open > .nav-link:hover,
.nav-tabs-line.nav-tabs-bottom .nav-item.show > .nav-link,
.nav-tabs-line.nav-tabs-bottom .nav-item.show > .nav-link:focus,
.nav-tabs-line.nav-tabs-bottom .nav-item.show > .nav-link:hover {
  border-top-color: #00b3d4;
}

.tabs-line-top {
  border-bottom: 1px solid #e4eaec;
}
.tabs-line-top .nav-link,
.tabs-line-top .nav-item .nav-link {
  bottom: -1px;

  border-top: 2px solid transparent;
  border-bottom-color: transparent;
}
.tabs-line-top .nav-link:hover,
.tabs-line-top .nav-item .nav-link:hover {
  border-top: 2px solid #ccd5db;
  border-bottom-color: transparent;
}
.tabs-line-top .nav-link.active,
.tabs-line-top .nav-link.active:focus,
.tabs-line-top .nav-link.active:hover,
.tabs-line-top .nav-item.open .nav-link,
.tabs-line-top .nav-item.open .nav-link:focus,
.tabs-line-top .nav-item.open .nav-link:hover,
.tabs-line-top .nav-item.show .nav-link,
.tabs-line-top .nav-item.show .nav-link:focus,
.tabs-line-top .nav-item.show .nav-link:hover {
  color: #00b3d4;
  border-top: 2px solid #00b3d4;
  border-right: 1px solid #e4eaec;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #e4eaec;
  background-color: transparent;
}

.nav-tabs-vertical::after {
  display: block;
  clear: both;

  content: "";
}

.nav-tabs-vertical .nav-tabs {
  float: left;
  flex-direction: column;

  border-right: 1px solid #e4eaec;
  border-bottom: none;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
}
.nav-tabs-vertical .nav-tabs .nav-item {
  float: none;

  margin-right: -1px;
  margin-bottom: 2px;
  margin-left: 0;
}
.nav-tabs-vertical .nav-tabs .nav-link {
  border-radius: 0.286rem 0 0 0.286rem;
}
.nav-tabs-vertical .nav-tabs .nav-link:hover {
  border-right-color: #e4eaec;
  border-bottom-color: transparent;
}
.nav-tabs-vertical .nav-tabs .nav-link.active,
.nav-tabs-vertical .nav-tabs .nav-link.active:focus,
.nav-tabs-vertical .nav-tabs .nav-link.active:hover {
  border-right-color: #00b3d4;
  border-bottom-color: transparent;
}

.nav-tabs-vertical .nav-tabs-reverse {
  float: right;

  border-right: none;
  border-left: 1px solid #e4eaec;
}
.nav-tabs-vertical .nav-tabs-reverse .nav-item {
  margin-right: 0;
  margin-left: -1px;
}
.nav-tabs-vertical .nav-tabs-reverse .nav-link {
  border-radius: 0 0.286rem 0.286rem 0;
}
.nav-tabs-vertical .nav-tabs-reverse .nav-link:hover {
  border-right-color: transparent;
  border-left-color: #e4eaec;
}
.nav-tabs-vertical .nav-tabs-reverse .nav-link.active,
.nav-tabs-vertical .nav-tabs-reverse .nav-link.active:focus,
.nav-tabs-vertical .nav-tabs-reverse .nav-link.active:hover {
  border-right-color: transparent;
  border-left-color: #00b3d4;
}

.nav-tabs-vertical .nav-tabs-solid {
  border-right-color: #f3f7f9;
}
.nav-tabs-vertical .nav-tabs-solid .nav-link:hover {
  border-color: transparent;
}
.nav-tabs-vertical .nav-tabs-solid .nav-link.active,
.nav-tabs-vertical .nav-tabs-solid .nav-link.active:focus,
.nav-tabs-vertical .nav-tabs-solid .nav-link.active:hover {
  border-color: transparent;
}
.nav-tabs-vertical .nav-tabs-solid + .tab-content {
  padding: 1.429rem;
}
.nav-tabs-vertical .nav-tabs-solid.nav-tabs-reverse {
  border-left-color: #f3f7f9;
}

.nav-tabs-vertical .nav-tabs-line .nav-link {
  border-right: 2px solid transparent;
  border-bottom: none;
}
.nav-tabs-vertical .nav-tabs-line .nav-link:hover {
  border-right-color: #ccd5db;
}
.nav-tabs-vertical .nav-tabs-line .nav-link.active,
.nav-tabs-vertical .nav-tabs-line .nav-link.active:hover,
.nav-tabs-vertical .nav-tabs-line .nav-link.active:focus {
  border-right: 2px solid #00b3d4;
  border-bottom: none;
}

.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse .nav-link {
  border-right-width: 1px;
  border-left: 2px solid transparent;
}
.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse .nav-link:hover {
  border-color: transparent;
  border-left-color: #ccd5db;
}
.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse .nav-link.active,
.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse .nav-link.active:hover,
.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse .nav-link.active:focus {
  border-right: 1px solid transparent;
  border-left: 2px solid #00b3d4;
}

.nav-tabs-vertical .tab-content {
  overflow: hidden;
}

.nav-tabs-inverse .nav-link.active,
.nav-tabs-inverse .nav-link.active:hover,
.nav-tabs-inverse .nav-link.active:focus {
  border-color: transparent;
}

.nav-tabs-inverse .nav-tabs-solid {
  border-bottom-color: #fff;
}
.nav-tabs-inverse .nav-tabs-solid .nav-link.active,
.nav-tabs-inverse .nav-tabs-solid .nav-link.active:hover,
.nav-tabs-inverse .nav-tabs-solid .nav-link.active:focus {
  color: #76838f;
  background-color: #fff;
}

.nav-tabs-inverse.nav-tabs-vertical .nav-link.active,
.nav-tabs-inverse.nav-tabs-vertical .nav-link.active:hover,
.nav-tabs-inverse.nav-tabs-vertical .nav-link.active:focus {
  border-color: transparent;
}

.nav-tabs-inverse.nav-tabs-vertical .nav-tabs-solid {
  border-right-color: #fff;
}
.nav-tabs-inverse.nav-tabs-vertical .nav-tabs-solid.nav-tabs-reverse {
  border-left-color: #fff;
}

.nav-tabs-inverse .tab-content {
  background: #fff;
}

.nav-tabs-animate .tab-content {
  overflow: hidden;
}

.nav-tabs-lg .nav-link {
  font-size: 1.286rem;
  line-height: 1.333333;

  padding: 0.858rem 1.429rem;
}

.nav-tabs-sm .nav-link {
  font-size: 0.858rem;
  line-height: 1.5;

  padding: 0.358rem 0.715rem;
}

.navbar::after {
  display: block;
  clear: both;

  content: "";
}

@media (min-width: 480px) {
  .navbar {
    border-radius: 0.215rem;
  }
}

.navbar-full {
  z-index: 1200;
}
@media (min-width: 480px) {
  .navbar-full {
    border-radius: 0;
  }
}

.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  z-index: 1500;
  right: 0;
  left: 0;
}
@media (min-width: 480px) {
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    border-radius: 0;
  }
}

.navbar-fixed-top {
  top: 0;
}

.navbar-fixed-bottom {
  bottom: 0;
}

.navbar-sticky-top {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1500;
  top: 0;

  width: 100%;
}
@media (min-width: 480px) {
  .navbar-sticky-top {
    border-radius: 0;
  }
}

.navbar {
  min-height: 4.715rem;
  padding: 0;

  border: none;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.navbar-nav {
  margin: 0.78575rem -1.0715rem;
}
.navbar-nav .nav-item {
  float: none;
}
.navbar-nav .nav-link {
  padding-top: 1.572rem;
  padding-bottom: 1.572rem;
}
.navbar-nav .nav-item .nav-link {
  line-height: 22px;

  padding-top: 0.715rem;
  padding-bottom: 0.715rem;
}
.navbar-nav .nav-item + .nav-item {
  margin-left: 0;
}
.navbar-nav > .nav-item > .dropdown-menu {
  margin-top: 0;

  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu,
  .navbar-nav .show .dropdown-menu {
    position: static;

    float: none;

    width: auto;
    margin-top: 0;

    border: 0;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .navbar-nav .open .dropdown-menu .dropdown-item,
  .navbar-nav .open .dropdown-menu .dropdown-header,
  .navbar-nav .show .dropdown-menu .dropdown-item,
  .navbar-nav .show .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }
  .navbar-nav .open .dropdown-menu .dropdown-item,
  .navbar-nav .show .dropdown-menu .dropdown-item {
    line-height: 22px;
  }
  .navbar-nav .open .dropdown-menu .dropdown-item:hover,
  .navbar-nav .open .dropdown-menu .dropdown-item:focus,
  .navbar-nav .show .dropdown-menu .dropdown-item:hover,
  .navbar-nav .show .dropdown-menu .dropdown-item:focus {
    background-image: none;
  }
}
@media (min-width: 768px) {
  .navbar-nav {
    float: left;

    margin: 0;
  }
  .navbar-nav > .nav-item {
    float: left;
  }
  .navbar-nav > .nav-item > .nav-link {
    padding-top: 1.5715rem;
    padding-bottom: 1.5715rem;
  }
  .navbar-nav.navbar-right:last-child {
    margin-right: -1.0715rem;
  }
}

.navbar-header::after {
  display: block;
  clear: both;

  content: "";
}

@media (min-width: 768px) {
  .navbar-header {
    float: left;
  }
}

.container-fluid .navbar-header,
.container-fluid .navbar-collapse {
  margin-right: -1.0715rem;
  margin-left: -1.0715rem;
}
@media (min-width: 768px) {
  .container-fluid .navbar-header,
  .container-fluid .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }
}

.navbar-toggler {
  line-height: 22px;

  position: relative;

  float: right;

  height: 4.715rem;
  margin-top: 0;
  margin-right: 1.0715rem;
  margin-bottom: 0;
  padding: 1.5715rem 1.0715rem;

  cursor: pointer;
  -webkit-transition: color 0.25s linear;
  -o-transition: color 0.25s linear;
  transition: color 0.25s linear;

  border: 1px solid transparent;
  border-radius: 0.215rem;
  background: transparent !important;
  background-image: none;
}
.navbar-toggler:hover {
  background: transparent !important;
}
.navbar-toggler:focus {
  outline: none;
}
.navbar-toggler .icon {
  margin-top: -1px;
}
.navbar-toggler .icon-bar {
  display: block;

  width: 1.572rem;
  height: 2px;

  border-radius: 1px;
}
.navbar-toggler .icon-bar + .icon-bar {
  margin-top: 0.286rem;
}
@media (min-width: 768px) {
  .navbar-toggler {
    display: none;
  }
}

.navbar-toggler-left {
  float: left;

  margin-right: 0;
  margin-left: 1.0715rem;
}

.navbar-collapse {
  overflow-x: visible;

  padding-right: 1.0715rem;
  padding-left: 1.0715rem;

  border-top: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);

  -webkit-overflow-scrolling: touch;
}
.navbar-collapse::after {
  display: block;
  clear: both;

  content: "";
}
.navbar-collapse.in {
  overflow-y: visible;
}
@media (min-width: 768px) {
  .navbar-collapse {
    width: auto;

    border-top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .navbar-collapse.collapse {
    display: block !important;
    overflow: visible !important;

    height: auto !important;
    padding-bottom: 0;
  }
  .navbar-fixed-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
  }
}

.navbar-fixed-top,
.navbar-fixed-bottom {
  width: 100%;
}

.hidden-float::after {
  display: block;
  clear: both;

  content: "";
}

@media (min-width: 768px) {
  .hidden-float {
    display: block;
  }
}

@media (max-width: 767px) {
  .hidden-float {
    display: none !important;
  }
}

.navbar-brand {
  font-size: 1.286rem;
  font-weight: 500;
  line-height: 22px;

  float: left;

  height: 4.715rem;
  margin: 0;
  padding: 1.5715rem 1.429rem;
}
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}
.navbar-brand > img.navbar-brand-logo {
  display: inline-block;
}
@media (min-width: 768px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: -1.0715rem;
  }
}

.navbar-brand-logo {
  display: inline-block;

  height: 2.286rem;
  margin-top: -0.357rem;
}

.navbar-brand-text {
  margin-left: 0.429rem;
}

@media (max-width: 767px) {
  .navbar-brand-center {
    position: absolute;
    left: 50%;

    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}

@media (min-width: 480px) {
  .navbar-mega .container,
  .navbar-mega .container-fluid {
    position: relative;
  }
}

@media (max-width: 767px) {
  .navbar-mega .container,
  .navbar-mega .container-fluid {
    position: static;
  }
}

.navbar-mega .dropdown-menu {
  left: auto;
}

.navbar-mega .dropdown-mega {
  position: static;
}

.navbar-mega .mega-content {
  padding: 1.429rem 2.143rem;
}

.navbar-mega .mega-menu {
  min-width: 10.715rem;
  max-width: 100%;
}
.navbar-mega .mega-menu > ul {
  padding-left: 0;
}
.navbar-mega .mega-menu .list-icons {
  margin-bottom: 0.429rem;
}

.navbar-mega .dropdown.dropdown-fw .dropdown-menu {
  right: 5px;
  left: 5px;
}
@media (max-width: 767px) {
  .navbar-mega .dropdown.dropdown-fw .dropdown-menu {
    right: 0;
    left: 0;
  }
}

.navbar-form {
  margin-top: 1.071rem;
  margin-right: -1.0715rem;
  margin-bottom: 1.071rem;
  margin-left: -1.0715rem;
  padding: 0.715rem 1.0715rem;

  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1),
    0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1),
    0 1px 0 rgba(255, 255, 255, 0.1);
}
@media (max-width: 767px) {
  .navbar-form .form-group {
    margin-bottom: 0.358rem;
  }
}
@media (min-width: 768px) {
  .navbar-form {
    width: auto;
    margin-right: 0;
    margin-left: 0;
    padding-top: 0;
    padding-bottom: 0;

    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .navbar-form.navbar-right:last-child {
    margin-right: -1.0715rem;
  }
}
.navbar-form .icon {
  font-size: 1.143rem;

  color: rgba(55, 71, 79, 0.4);
}
.navbar-form .form-control {
  border: none;
  border-radius: 38px;
  background-color: #f3f7f9;
}

@media (min-width: 768px) {
  .navbar-search.collapse {
    display: block !important;
    visibility: visible !important;
    overflow: visible !important;

    height: auto !important;
  }
}

@media (max-width: 767px) {
  .navbar-search {
    padding-right: 1.0715rem;
    padding-left: 1.0715rem;
  }
}

@media (max-width: 767px) {
  .navbar-search .navbar-form {
    margin-top: 0;
    margin-bottom: 0;

    border-bottom: none;
  }
}

.container > .navbar-search,
.container-fluid > .navbar-search {
  margin-right: -1.0715rem;
  margin-left: -1.0715rem;
}
@media (min-width: 768px) {
  .container > .navbar-search,
  .container-fluid > .navbar-search {
    margin-right: 0;
    margin-left: 0;
  }
}

.navbar-search-overlap {
  position: absolute !important;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;

  background-color: #fff;
}
.navbar-search-overlap .form-group,
.navbar-search-overlap .form-control {
  display: block !important;

  margin: 0;
}
.navbar-search-overlap .form-control {
  height: 4.715rem !important;

  border-radius: 0;
  background-color: transparent !important;
}
.navbar-search-overlap .form-control:focus {
  border-color: transparent;
}

.navbar-toolbar {
  float: left;
}
.navbar-toolbar::after {
  display: block;
  clear: both;

  content: "";
}
.navbar-toolbar .nav-item {
  float: left;
}
.navbar-toolbar .nav-item::after {
  display: block;
  clear: both;

  content: "";
}
.navbar-toolbar .nav-link {
  line-height: 22px;

  padding-top: 1.5715rem;
  padding-bottom: 1.5715rem;
}
.navbar-toolbar .dropdown-menu {
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  -o-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-animation-duration: 0.3s;
  -o-animation-duration: 0.3s;
  animation-duration: 0.3s;
}
@media (max-width: 767px) {
  .navbar-toolbar .dropdown-menu:not(.dropdown-menu-media) {
    overflow-x: hidden;
    overflow-y: scroll;

    max-height: 28.572rem;

    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767px) and (max-device-width: 480px) and (orientation: landscape) {
  .navbar-toolbar .dropdown-menu:not(.dropdown-menu-media) {
    max-height: 14.286rem;
  }
}
@media (max-width: 767px) {
  .navbar-toolbar .open,
  .navbar-toolbar .show {
    position: static;
  }
  .navbar-toolbar .open .dropdown-menu,
  .navbar-toolbar .show .dropdown-menu {
    right: 0;
    left: 0;

    float: none;

    width: auto;
    margin-top: 0;

    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

@media (max-width: 767px) {
  .navbar-toolbar-left {
    float: left !important;
  }
  .navbar-toolbar-right {
    float: right !important;
  }
}

.navbar-nav .nav-link.navbar-avatar,
.navbar-toolbar .nav-link.navbar-avatar {
  padding-top: 1.286rem;
  padding-bottom: 1.286rem;
}

@media (max-width: 767px) {
  .navbar-nav .nav-link.navbar-avatar {
    padding-top: 0.429rem;
    padding-bottom: 0.429rem;
  }
}

.navbar-avatar .avatar {
  width: 2.143rem;
}

.icon-fullscreen {
  font-family: "Web Icons";
}
.icon-fullscreen:before {
  content: "";
}
.icon-fullscreen.active:before {
  content: "";
}

.icon-menubar {
  font-family: "Web Icons";
}
.icon-menubar:before {
  content: "";
}
.icon-menubar.active:before {
  content: "";
}

.navbar-btn {
  margin-top: 1.071rem;
  margin-bottom: 1.071rem;
}
.navbar-btn.btn-sm {
  margin-top: 1.2135rem;
  margin-bottom: 1.2135rem;
}
.navbar-btn.btn-xs,
.btn-group-xs > .navbar-btn.btn {
  margin-top: 1.5715rem;
  margin-bottom: 1.5715rem;
}

.navbar-text {
  margin-top: 1.5715rem;
  margin-bottom: 1.5715rem;
}
@media (min-width: 768px) {
  .navbar-text {
    float: left;

    margin-right: 1.0715rem;
    margin-left: 1.0715rem;
  }
  .navbar-text.navbar-right:last-child {
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-left {
    float: left;
  }
  .navbar-right {
    float: right;

    margin-right: -1.0715rem;
  }
  .navbar-right ~ .navbar-right {
    margin-right: 0;
  }
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }
}

.navbar-default {
  border-color: #e4eaec;
  background-color: #fff;
}
.navbar-default .navbar-brand {
  color: #37474f;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #37474f;
  background-color: none;
}
.navbar-default .navbar-text {
  color: #76838f;
}
.navbar-default .navbar-nav .nav-link {
  color: #76838f;
}
.navbar-default .navbar-nav .nav-link:hover,
.navbar-default .navbar-nav .nav-link:focus {
  color: #526069;
  background-color: rgba(243, 247, 249, 0.3);
}
.navbar-default .navbar-nav > .active > .nav-link,
.navbar-default .navbar-nav > .active > .nav-link:hover,
.navbar-default .navbar-nav > .active > .nav-link:focus,
.navbar-default .navbar-nav .nav-link.active,
.navbar-default .navbar-nav .nav-link.active:hover,
.navbar-default .navbar-nav .nav-link.active:focus {
  color: #526069;
  background-color: rgba(243, 247, 249, 0.6);
}
.navbar-default .navbar-nav > .disabled > .nav-link,
.navbar-default .navbar-nav > .disabled > .nav-link:hover,
.navbar-default .navbar-nav > .disabled > .nav-link:focus,
.navbar-default .navbar-nav .nav-link.disabled,
.navbar-default .navbar-nav .nav-link.disabled:hover,
.navbar-default .navbar-nav .nav-link.disabled:focus {
  color: #ccd5db;
  background-color: transparent;
}
.navbar-default .navbar-toggler {
  border-color: transparent;
}
.navbar-default .navbar-toggler:hover,
.navbar-default .navbar-toggler:focus {
  background-color: rgba(243, 247, 249, 0.3);
}
.navbar-default .navbar-toggler .icon-bar {
  background-color: #76838f;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: #e4eaec;
}
.navbar-default .navbar-nav > .open > .nav-link,
.navbar-default .navbar-nav > .open > .nav-link:hover,
.navbar-default .navbar-nav > .open > .nav-link:focus,
.navbar-default .navbar-nav > .show > .nav-link,
.navbar-default .navbar-nav > .show > .nav-link:hover,
.navbar-default .navbar-nav > .show > .nav-link:focus {
  color: #526069;
  background-color: rgba(243, 247, 249, 0.6);
}
@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu .dropdown-item,
  .navbar-default .navbar-nav .show .dropdown-menu .dropdown-item {
    color: #76838f;
  }
  .navbar-default .navbar-nav .open .dropdown-menu .dropdown-item:hover,
  .navbar-default .navbar-nav .open .dropdown-menu .dropdown-item:focus,
  .navbar-default .navbar-nav .show .dropdown-menu .dropdown-item:hover,
  .navbar-default .navbar-nav .show .dropdown-menu .dropdown-item:focus {
    color: #526069;
    background-color: rgba(243, 247, 249, 0.3);
  }
  .navbar-default .navbar-nav .open .dropdown-menu .dropdown-item.active,
  .navbar-default .navbar-nav .open .dropdown-menu .dropdown-item.active:hover,
  .navbar-default .navbar-nav .open .dropdown-menu .dropdown-item.active:focus,
  .navbar-default .navbar-nav .show .dropdown-menu .dropdown-item.active,
  .navbar-default .navbar-nav .show .dropdown-menu .dropdown-item.active:hover,
  .navbar-default .navbar-nav .show .dropdown-menu .dropdown-item.active:focus {
    color: #526069;
    background-color: rgba(243, 247, 249, 0.6);
  }
  .navbar-default .navbar-nav .open .dropdown-menu .dropdown-item.disabled,
  .navbar-default
    .navbar-nav
    .open
    .dropdown-menu
    .dropdown-item.disabled:hover,
  .navbar-default
    .navbar-nav
    .open
    .dropdown-menu
    .dropdown-item.disabled:focus,
  .navbar-default .navbar-nav .show .dropdown-menu .dropdown-item.disabled,
  .navbar-default
    .navbar-nav
    .show
    .dropdown-menu
    .dropdown-item.disabled:hover,
  .navbar-default
    .navbar-nav
    .show
    .dropdown-menu
    .dropdown-item.disabled:focus {
    color: #ccd5db;
    background-color: transparent;
  }
}
.navbar-default .navbar-link {
  color: #76838f;
}
.navbar-default .navbar-link:hover {
  color: #526069;
}
.navbar-default .navbar-toolbar .nav-link {
  display: block;

  color: #76838f;
}
.navbar-default .navbar-toolbar .nav-link:hover,
.navbar-default .navbar-toolbar .nav-link:focus {
  color: #526069;
  background-color: rgba(243, 247, 249, 0.3);
}
.navbar-default .navbar-toolbar > .active > .nav-link,
.navbar-default .navbar-toolbar > .active > .nav-link:hover,
.navbar-default .navbar-toolbar > .active > .nav-link:focus,
.navbar-default .navbar-toolbar .nav-link.active,
.navbar-default .navbar-toolbar .nav-link.active:hover,
.navbar-default .navbar-toolbar .nav-link.active:focus {
  color: #526069;
  background-color: rgba(243, 247, 249, 0.6);
}
.navbar-default .navbar-toolbar > .disabled > .nav-link,
.navbar-default .navbar-toolbar > .disabled > .nav-link:hover,
.navbar-default .navbar-toolbar > .disabled > .nav-link:focus,
.navbar-default .navbar-toolbar .nav-link.disabled,
.navbar-default .navbar-toolbar .nav-link.disabled:hover,
.navbar-default .navbar-toolbar .nav-link.disabled:focus {
  color: #ccd5db;
  background-color: transparent;
}
.navbar-default .navbar-toggler {
  color: #76838f;
}
.navbar-default .navbar-toolbar > .open > .nav-link,
.navbar-default .navbar-toolbar > .open > .nav-link:hover,
.navbar-default .navbar-toolbar > .open > .nav-link:focus,
.navbar-default .navbar-toolbar > .show > .nav-link,
.navbar-default .navbar-toolbar > .show > .nav-link:hover,
.navbar-default .navbar-toolbar > .show > .nav-link:focus {
  color: #526069;
  background-color: rgba(243, 247, 249, 0.6);
}

.navbar-inverse {
  border-color: rgba(0, 0, 0, 0.1);
  background-color: #00b3d4;
}
.navbar-inverse .navbar-brand {
  color: #fff;
}
.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-brand:focus {
  color: #fff;
  background-color: none;
}
.navbar-inverse .navbar-text {
  color: #fff;
}
.navbar-inverse .navbar-nav .nav-link {
  color: #fff;
}
.navbar-inverse .navbar-nav .nav-link:hover,
.navbar-inverse .navbar-nav .nav-link:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
}
.navbar-inverse .navbar-nav > .active > .nav-link,
.navbar-inverse .navbar-nav > .active > .nav-link:hover,
.navbar-inverse .navbar-nav > .active > .nav-link:focus,
.navbar-inverse .navbar-nav .nav-link.active,
.navbar-inverse .navbar-nav .nav-link.active:hover,
.navbar-inverse .navbar-nav .nav-link.active:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
}
.navbar-inverse .navbar-nav > .disabled > .nav-link,
.navbar-inverse .navbar-nav > .disabled > .nav-link:hover,
.navbar-inverse .navbar-nav > .disabled > .nav-link:focus,
.navbar-inverse .navbar-nav .nav-link.disabled,
.navbar-inverse .navbar-nav .nav-link.disabled:hover,
.navbar-inverse .navbar-nav .nav-link.disabled:focus {
  color: #fff;
  background-color: transparent;
}
.navbar-inverse .navbar-toggler {
  color: #fff;
  border-color: transparent;
}
.navbar-inverse .navbar-toggler:hover,
.navbar-inverse .navbar-toggler:focus {
  background-color: rgba(0, 0, 0, 0.1);
}
.navbar-inverse .navbar-toggler .icon-bar {
  background-color: #fff;
}
.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-color: #4397e6;
}
.navbar-inverse .navbar-nav > .open > .nav-link,
.navbar-inverse .navbar-nav > .open > .nav-link:hover,
.navbar-inverse .navbar-nav > .open > .nav-link:focus,
.navbar-inverse .navbar-nav > .show > .nav-link,
.navbar-inverse .navbar-nav > .show > .nav-link:hover,
.navbar-inverse .navbar-nav > .show > .nav-link:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
}
@media (max-width: 767px) {
  .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header,
  .navbar-inverse .navbar-nav .show .dropdown-menu > .dropdown-header {
    border-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu .dropdown-divider,
  .navbar-inverse .navbar-nav .show .dropdown-menu .dropdown-divider {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu .dropdown-item,
  .navbar-inverse .navbar-nav .show .dropdown-menu .dropdown-item {
    color: #fff;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu .dropdown-item:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu .dropdown-item:focus,
  .navbar-inverse .navbar-nav .show .dropdown-menu .dropdown-item:hover,
  .navbar-inverse .navbar-nav .show .dropdown-menu .dropdown-item:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu .dropdown-item.active,
  .navbar-inverse .navbar-nav .open .dropdown-menu .dropdown-item.active:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu .dropdown-item.active:focus,
  .navbar-inverse .navbar-nav .show .dropdown-menu .dropdown-item.active,
  .navbar-inverse .navbar-nav .show .dropdown-menu .dropdown-item.active:hover,
  .navbar-inverse .navbar-nav .show .dropdown-menu .dropdown-item.active:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu .dropdown-item.disabled,
  .navbar-inverse
    .navbar-nav
    .open
    .dropdown-menu
    .dropdown-item.disabled:hover,
  .navbar-inverse
    .navbar-nav
    .open
    .dropdown-menu
    .dropdown-item.disabled:focus,
  .navbar-inverse .navbar-nav .show .dropdown-menu .dropdown-item.disabled,
  .navbar-inverse
    .navbar-nav
    .show
    .dropdown-menu
    .dropdown-item.disabled:hover,
  .navbar-inverse
    .navbar-nav
    .show
    .dropdown-menu
    .dropdown-item.disabled:focus {
    color: #fff;
    background-color: transparent;
  }
}
.navbar-inverse .navbar-link {
  color: #fff;
}
.navbar-inverse .navbar-link:hover {
  color: #fff;
}
.navbar-inverse .navbar-toolbar .nav-link {
  color: #fff;
}
.navbar-inverse .navbar-toolbar .nav-link:hover,
.navbar-inverse .navbar-toolbar .nav-link:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
}
.navbar-inverse .navbar-toolbar > .active > .nav-link,
.navbar-inverse .navbar-toolbar > .active > .nav-link:hover,
.navbar-inverse .navbar-toolbar > .active > .nav-link:focus,
.navbar-inverse .navbar-toolbar .nav-link.active,
.navbar-inverse .navbar-toolbar .nav-link.active:hover,
.navbar-inverse .navbar-toolbar .nav-link.active:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
}
.navbar-inverse .navbar-toolbar > .disabled > .nav-link,
.navbar-inverse .navbar-toolbar > .disabled > .nav-link:hover,
.navbar-inverse .navbar-toolbar > .disabled > .nav-link:focus,
.navbar-inverse .navbar-toolbar .nav-link.disabled,
.navbar-inverse .navbar-toolbar .nav-link.disabled:hover,
.navbar-inverse .navbar-toolbar .nav-link.disabled:focus {
  color: #fff;
  background-color: transparent;
}
.navbar-inverse .navbar-toolbar > .open > .nav-link,
.navbar-inverse .navbar-toolbar > .open > .nav-link:hover,
.navbar-inverse .navbar-toolbar > .open > .nav-link:focus,
.navbar-inverse .navbar-toolbar > .show > .nav-link,
.navbar-inverse .navbar-toolbar > .show > .nav-link:hover,
.navbar-inverse .navbar-toolbar > .show > .nav-link:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .nav.hidden-float,
  .navbar.hidden-float {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (max-width: 767px) {
  .nav.hidden-float,
  .navbar.hidden-float {
    display: none !important;
  }
}

.navbar-toolbar .nav-link {
  display: block;
}

.navbar > .containers,
.navbar > .container-fluid {
  margin: 0;

  -ms-flex: 1 1 0%;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0;
  flex: 1 1 0;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.navbar > .containers .navbar-collapse,
.navbar > .container-fluid .navbar-collapse {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

@media (max-width: 767px) {
  .navbar > .containers,
  .navbar > .container-fluid {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .navbar > .containers .navbar-header,
  .navbar > .container-fluid .navbar-header {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .navbar > .containers .navbar-collapse,
  .navbar > .container-fluid .navbar-collapse {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .navbar > .containers .navbar-search,
  .navbar > .container-fluid .navbar-search {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
}

.breadcrumb {
  margin-bottom: 10px;

  background-color: transparent;
}
.breadcrumb .is-hidden {
  display: none;
}
.breadcrumb-ellipsis {
  display: inline-block;
}
.breadcrumb-dropdown {
  display: inline-block;
}
.breadcrumb.breadcrumb-right > li.breadcrumb-ellipsis.is-hidden + li:before {
  display: none;
}
.breadcrumb.breadcrumb-left .breadcrumb-dropdown,
.breadcrumb.breadcrumb-left .breadcrumb-ellipsis {
  float: left;
}

.breadcrumb-item + .breadcrumb-item:before {
  padding: 0 5px;
}

.breadcrumb-item .icon {
  text-decoration: none;
}
.breadcrumb-item .icon:before {
  margin-right: 10px;
}

.breadcrumb-arrow > .breadcrumb-item + .breadcrumb-item:before {
  content: "» ";
}

.page-item > span {
  padding: 0.643rem 1.072rem;

  -webkit-transition: background 0.2s ease-out, border-color 0s ease-out,
    color 0.2s ease-out;
  -o-transition: background 0.2s ease-out, border-color 0s ease-out,
    color 0.2s ease-out;
  transition: background 0.2s ease-out, border-color 0s ease-out,
    color 0.2s ease-out;
}
.page-item > span:focus,
.page-item > span:hover {
  -webkit-transition: background 0.2s ease-out, border-color 0.2s ease-out,
    color 0.2s ease-out;
  -o-transition: background 0.2s ease-out, border-color 0.2s ease-out,
    color 0.2s ease-out;
  transition: background 0.2s ease-out, border-color 0.2s ease-out,
    color 0.2s ease-out;
  text-decoration: none;
}

.page-item .icon {
  margin-top: -1px;
}

.page-item.active .page-link {
  z-index: 3;
}

.page-item.disabled > span,
.page-item.disabled > span:focus,
.page-item.disabled > span:hover {
  cursor: not-allowed;
  pointer-events: none;

  color: #ccd5db;
  border-color: #e4eaec;
  background-color: transparent;
}

.page-link {
  -webkit-transition: background 0.2s ease-out, border-color 0s ease-out,
    color 0.2s ease-out;
  -o-transition: background 0.2s ease-out, border-color 0s ease-out,
    color 0.2s ease-out;
  transition: background 0.2s ease-out, border-color 0s ease-out,
    color 0.2s ease-out;
}
.page-link:focus,
.page-link:hover {
  -webkit-transition: background 0.2s ease-out, border-color 0.2s ease-out,
    color 0.2s ease-out;
  -o-transition: background 0.2s ease-out, border-color 0.2s ease-out,
    color 0.2s ease-out;
  transition: background 0.2s ease-out, border-color 0.2s ease-out,
    color 0.2s ease-out;
  text-decoration: none;
}

.pagination-gap .page-item.disabled .page-link:focus,
.pagination-gap .page-item.disabled .page-link:hover {
  border-color: #e4eaec;
}

.pagination-gap .page-item:first-child .page-link,
.pagination-gap .page-item:last-child .page-link {
  border-radius: 5px;
}

.pagination-gap .page-item.active .page-link {
  background-color: #00b3d4;
}

.pagination-gap .page-link {
  margin: 0 5px;

  border-radius: 5px;
}
.pagination-gap .page-link:focus,
.pagination-gap .page-link:hover {
  border-color: #00b3d4;
  background-color: transparent;
}

.pagination-no-border .page-link {
  border: none;
}

.badge.badge-outline {
  color: #f3f7f9;
  border-color: #f3f7f9;
  background-color: transparent;
}

.badge.up {
  position: relative;
  top: -10px;

  margin: 0 -0.8em;

  border-radius: 15px;
}

.badge-outline {
  border: 1px solid transparent;
}

.badge-round {
  border-radius: 1em;
}

.badge-pill {
  padding: 3px 6px;
}
.badge-pill:empty {
  display: none;
}
.btn .badge-pill {
  top: 0;
}
.btn-xs .badge-pill,
.btn-group-xs > .btn .badge-pill,
.btn-group-xs > .btn .badge-pill {
  top: 0;

  padding: 1px 5px;
}
a .badge-pill:hover,
a .badge-pill:focus {
  cursor: pointer;
  text-decoration: none;
}
.list-group-item > .badge-pill {
  float: right;

  margin-left: auto;

  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}
.list-group-item > .badge-pill + .badge-pill {
  margin-right: 5px;
}
.nav-pills > li > a > .badge-pill {
  margin-left: 3px;
}

.badge-default {
  color: #76838f;
}
.badge-default[href]:hover,
.badge-default[href]:focus {
  background-color: #f3f7f9;
}
.badge-default.badge-outline {
  color: #e4eaec;
  border-color: #e4eaec;
  background-color: transparent;
}
.badge-default[href]:hover,
.badge-default[href]:focus {
  color: #a3afb7;
}
.badge-default.badge-outline {
  color: #76838f;
}

.badge-primary[href]:hover,
.badge-primary[href]:focus {
  background-color: #89bceb;
}

.badge-primary.badge-outline {
  color: #00b3d4;
  border-color: #00b3d4;
  background-color: transparent;
}

.badge-success[href]:hover,
.badge-success[href]:focus {
  background-color: #5cd29d;
}

.badge-success.badge-outline {
  color: #8ebe31;
  border-color: #8ebe31;
  background-color: transparent;
}

.badge-info[href]:hover,
.badge-info[href]:focus {
  background-color: #77d6e1;
}

.badge-info.badge-outline {
  color: #57c7d4;
  border-color: #57c7d4;
  background-color: transparent;
}

.badge-warning[href]:hover,
.badge-warning[href]:focus {
  background-color: #f4b066;
}

.badge-warning.badge-outline {
  color: #f2a654;
  border-color: #f2a654;
  background-color: transparent;
}

.badge-danger[href]:hover,
.badge-danger[href]:focus {
  background-color: #fa7a7a;
}

.badge-danger.badge-outline {
  color: #f96868;
  border-color: #f96868;
  background-color: transparent;
}

.badge-dark {
  background-color: #526069;
}
.badge-dark[href]:hover,
.badge-dark[href]:focus {
  background-color: #76838f;
}
.badge-dark.badge-outline {
  color: #526069;
  border-color: #526069;
  background-color: transparent;
}
.badge-dark[href]:focus,
.badge-dark[href]:hover {
  background-color: #3c464c;
}

.badge-lg {
  font-size: 16px;
}

.badge-sm {
  font-size: 10px;

  padding: 0.1em 0.5em 0.1em;
}

.jumbotron {
  padding: 0;

  border-radius: 0.215rem;
}
.jumbotron > .jumbotron-photo img {
  width: 100%;

  border-top-left-radius: 0.215rem;
  border-top-right-radius: 0.215rem;
}
.jumbotron-contents {
  padding: 1.429rem;
}
.jumbotron .carousel,
.jumbotron .carousel-inner,
.jumbotron .carousel-inner > .carousel-item.active img {
  border-top-left-radius: 0.215rem;
  border-top-right-radius: 0.215rem;
}
.jumbotron .carousel-inner > .carousel-item > a > img,
.jumbotron .carousel-inner > .carousel-item > img {
  width: 100%;
}
@media (min-width: 768px) {
  .jumbotron,
  .container .jumbotron {
    padding: 0;
  }
}

.alert {
  padding-right: 20px;
  padding-left: 20px;
}
.alert ul {
  padding-left: 13px;
}
.alert ul li {
  padding-left: 7px;
}
.panel > .alert {
  margin: 0;
}
.alert h4 {
  margin-top: 0;
  margin-bottom: 5px;

  color: inherit;
}
.alert > *:last-child {
  margin-bottom: 0;
}

.alert-alt {
  color: #76838f;
  border: none;
  border-left: 3px solid transparent;
  background-color: rgba(243, 247, 249, 0.8);
}
.alert-alt a,
.alert-alt .alert-link {
  text-decoration: none;
}

.alert-dismissible {
  padding-right: 40px;
}
.alert-dismissible .close {
  padding: 0;
}
.alert-dismissible .close {
  top: 0;

  text-decoration: none;

  opacity: 0.6;
}
.alert-dismissible .close:hover,
.alert-dismissible .close:focus {
  opacity: 1;
}
.alert-dismissible.alert-alt .close {
  opacity: 0.6;
  color: #a3afb7;
}
.alert-dismissible.alert-alt .close:hover,
.alert-dismissible.alert-alt .close:focus {
  opacity: 1;
  color: #a3afb7;
}

.alert-icon {
  position: relative;

  padding-left: 45px;
}
.alert-icon > .icon {
  font-size: 1rem;

  position: absolute;
  top: 15px;
  left: 20px;

  width: 1em;

  text-align: center;
}

.alert-avatar {
  position: relative;

  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 75px;
}
.alert-avatar > .avatar {
  position: absolute;
  top: 12px;
  left: 20px;
}

.page-alert .alert-wrap {
  overflow: hidden;

  max-height: 0;
  margin: 0;
  padding: 0;

  -webkit-transition: max-height 0.7s linear 0s;
  -o-transition: max-height 0.7s linear 0s;
  transition: max-height 0.7s linear 0s;
}
.page-alert .alert-wrap.in {
  max-height: 500px;

  -webkit-transition: max-height 1s linear 0s;
  -o-transition: max-height 1s linear 0s;
  transition: max-height 1s linear 0s;
}
.page-alert .alert-wrap .alert {
  margin: 0;

  text-align: left;

  border-radius: 0;
}

.alert-primary {
  color: #00b3d4;
  border-color: #e8f1f8;
  background-color: rgba(232, 241, 248, 0.8);
}
.alert-primary .close {
  color: #00b3d4;
}
.alert-primary .close:hover,
.alert-primary .close:focus {
  color: #00b3d4;
}
.alert-primary .alert-link {
  color: #4e97d9;
}

.alert-alt.alert-primary {
  border-color: #00b3d4;
}
.alert-alt.alert-primary a,
.alert-alt.alert-primary .alert-link {
  color: #00b3d4;
}

.alert-success .alert-link {
  color: #36ab7a;
}

.alert-alt.alert-success {
  border-color: #8ebe31;
}
.alert-alt.alert-success a,
.alert-alt.alert-success .alert-link {
  color: #8ebe31;
}

.alert-info .alert-link {
  color: #47b8c6;
}

.alert-alt.alert-info {
  border-color: #57c7d4;
}
.alert-alt.alert-info a,
.alert-alt.alert-info .alert-link {
  color: #57c7d4;
}

.alert-warning .alert-link {
  color: #ec9940;
}

.alert-alt.alert-warning {
  border-color: #f2a654;
}
.alert-alt.alert-warning a,
.alert-alt.alert-warning .alert-link {
  color: #f2a654;
}

.alert-danger .alert-link {
  color: #e9595b;
}

.alert-alt.alert-danger {
  border-color: #f96868;
}
.alert-alt.alert-danger a,
.alert-alt.alert-danger .alert-link {
  color: #f96868;
}

.alert-social {
  position: relative;

  padding-left: 65px;
}
.alert-social > .icon {
  font-size: 28px;

  position: absolute;
  top: 12px;
  bottom: 0;
  left: 20px;

  width: 1em;

  text-align: center;
}

.alert-facebook {
  color: #fff;
  border-color: #3b5998;
  background-color: #3b5998;
}
.alert-facebook .close {
  color: #fff;
}
.alert-facebook .close:hover,
.alert-facebook .close:focus {
  color: #fff;
}
.alert-facebook .alert-link {
  font-weight: 500;

  color: #fff;
}

.alert-twitter {
  color: #fff;
  border-color: #55acee;
  background-color: #55acee;
}
.alert-twitter .close {
  color: #fff;
}
.alert-twitter .close:hover,
.alert-twitter .close:focus {
  color: #fff;
}
.alert-twitter .alert-link {
  font-weight: 500;

  color: #fff;
}

.alert-google-plus {
  color: #fff;
  border-color: #dd4b39;
  background-color: #dd4b39;
}
.alert-google-plus .close {
  color: #fff;
}
.alert-google-plus .close:hover,
.alert-google-plus .close:focus {
  color: #fff;
}
.alert-google-plus .alert-link {
  font-weight: 500;

  color: #fff;
}

.alert-linkedin {
  color: #fff;
  border-color: #0976b4;
  background-color: #0976b4;
}
.alert-linkedin .close {
  color: #fff;
}
.alert-linkedin .close:hover,
.alert-linkedin .close:focus {
  color: #fff;
}
.alert-linkedin .alert-link {
  font-weight: 500;

  color: #fff;
}

.alert-flickr {
  color: #fff;
  border-color: #ff0084;
  background-color: #ff0084;
}
.alert-flickr .close {
  color: #fff;
}
.alert-flickr .close:hover,
.alert-flickr .close:focus {
  color: #fff;
}
.alert-flickr .alert-link {
  font-weight: 500;

  color: #fff;
}

.alert-tumblr {
  color: #fff;
  border-color: #35465c;
  background-color: #35465c;
}
.alert-tumblr .close {
  color: #fff;
}
.alert-tumblr .close:hover,
.alert-tumblr .close:focus {
  color: #fff;
}
.alert-tumblr .alert-link {
  font-weight: 500;

  color: #fff;
}

.alert-github {
  color: #fff;
  border-color: #4183c4;
  background-color: #4183c4;
}
.alert-github .close {
  color: #fff;
}
.alert-github .close:hover,
.alert-github .close:focus {
  color: #fff;
}
.alert-github .alert-link {
  font-weight: 500;

  color: #fff;
}

.alert-dribbble {
  color: #fff;
  border-color: #c32361;
  background-color: #c32361;
}
.alert-dribbble .close {
  color: #fff;
}
.alert-dribbble .close:hover,
.alert-dribbble .close:focus {
  color: #fff;
}
.alert-dribbble .alert-link {
  font-weight: 500;

  color: #fff;
}

.alert-youtube {
  color: #fff;
  border-color: #b31217;
  background-color: #b31217;
}
.alert-youtube .close {
  color: #fff;
}
.alert-youtube .close:hover,
.alert-youtube .close:focus {
  color: #fff;
}
.alert-youtube .alert-link {
  font-weight: 500;

  color: #fff;
}

.alert.dark .alert-link {
  font-weight: 500;

  color: #fff !important;
}

.alert.dark .alert-left-border {
  border: none;
  border-left: 3px solid transparent;
}

.alert.dark.alert-dismissible.alert-alt .close {
  color: #fff;
}
.alert.dark.alert-dismissible.alert-alt .close:hover,
.alert.dark.alert-dismissible.alert-alt .close:focus {
  color: #fff;
}

.alert.dark.alert-primary {
  color: #fff;
  border-color: #00b3d4;
  background-color: #00b3d4;
}
.alert.dark.alert-primary .close {
  color: #fff;
}
.alert.dark.alert-primary .close:hover,
.alert.dark.alert-primary .close:focus {
  color: #fff;
}
.alert.dark.alert-primary.alert-alt {
  border-color: #2771b4;
}
.alert.dark.alert-primary.alert-alt a,
.alert.dark.alert-primary.alert-alt .alert-link {
  color: #fff;
}

.alert.dark.alert-success {
  color: #fff;
  border-color: #8ebe31;
  background-color: #8ebe31;
}
.alert.dark.alert-success .close {
  color: #fff;
}
.alert.dark.alert-success .close:hover,
.alert.dark.alert-success .close:focus {
  color: #fff;
}
.alert.dark.alert-success.alert-alt {
  border-color: #247151;
}
.alert.dark.alert-success.alert-alt a,
.alert.dark.alert-success.alert-alt .alert-link {
  color: #fff;
}

.alert.dark.alert-info {
  color: #fff;
  border-color: #57c7d4;
  background-color: #57c7d4;
}
.alert.dark.alert-info .close {
  color: #fff;
}
.alert.dark.alert-info .close:hover,
.alert.dark.alert-info .close:focus {
  color: #fff;
}
.alert.dark.alert-info.alert-alt {
  border-color: #2e8893;
}
.alert.dark.alert-info.alert-alt a,
.alert.dark.alert-info.alert-alt .alert-link {
  color: #fff;
}

.alert.dark.alert-warning {
  color: #fff;
  border-color: #f2a654;
  background-color: #f2a654;
}
.alert.dark.alert-warning .close {
  color: #fff;
}
.alert.dark.alert-warning .close:hover,
.alert.dark.alert-warning .close:focus {
  color: #fff;
}
.alert.dark.alert-warning.alert-alt {
  border-color: #cb7314;
}
.alert.dark.alert-warning.alert-alt a,
.alert.dark.alert-warning.alert-alt .alert-link {
  color: #fff;
}

.alert.dark.alert-danger {
  color: #fff;
  border-color: #f96868;
  background-color: #f96868;
}
.alert.dark.alert-danger .close {
  color: #fff;
}
.alert.dark.alert-danger .close:hover,
.alert.dark.alert-danger .close:focus {
  color: #fff;
}
.alert.dark.alert-danger.alert-alt {
  border-color: #d91d1f;
}
.alert.dark.alert-danger.alert-alt a,
.alert.dark.alert-danger.alert-alt .alert-link {
  color: #fff;
}

.progress {
  overflow: hidden;

  height: 15px;
  margin-bottom: 22px;

  border-radius: 0.215rem;
  background-color: #e4eaec;
}

.progress-bar {
  font-size: 0.858rem;
  line-height: 15px;

  float: left;

  width: 0%;
  height: 100%;

  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
  text-align: center;

  color: #fff;
  background-color: #00b3d4;
}

.progress-bar-success {
  background-color: #8ebe31;
}
.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar-info {
  background-color: #57c7d4;
}
.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar-warning {
  background-color: #f2a654;
}
.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar-danger {
  background-color: #f96868;
}
.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-striped .progress-bar,
.progress-bar-striped {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}

.progress.active .progress-bar,
.progress-bar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-square {
  border-radius: 0;
}

.progress-circle {
  border-radius: 1000px;
}

.progress-vertical {
  position: relative;

  display: inline-block;

  width: 15px;
  height: 250px;
  min-height: 250px;
  margin-right: 30px;
  margin-bottom: 0;
}
.progress-vertical .progress-bar {
  width: 100%;
}

.progress-bar-indicating.active {
  position: relative;

  -webkit-animation: none;
  -o-animation: none;
  animation: none;
}
.progress-bar-indicating.active:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: "";
  -webkit-animation: progress-active 3s ease 0s infinite;
  -o-animation: progress-active 3s ease 0s infinite;
  animation: progress-active 3s ease 0s infinite;

  opacity: 0;
  border-radius: inherit;
  background-color: #fff;
}
.progress-vertical .progress-bar-indicating.active:before {
  -webkit-animation-name: progress-vertical-active;
  -o-animation-name: progress-vertical-active;
  animation-name: progress-vertical-active;
}

.progress-skill {
  position: relative;
}
.progress-skill .progress-bar > span {
  position: absolute;
  top: 0;
  right: 10px;

  color: #526069;
}

.progress-lg {
  height: 22px;
}
.progress-lg.progress-vertical {
  width: 25px;
}
.progress-lg .progress-bar {
  line-height: 22px;
}

.progress-sm {
  height: 10px;
}
.progress-sm.progress-vertical {
  width: 10px;
}
.progress-sm .progress-bar {
  line-height: 10px;
}

.progress-xs {
  height: 4px;

  border-radius: 1px;
}
.progress-xs.progress-vertical {
  width: 4px;
}
.progress-xs .progress-bar {
  line-height: 4px;
}

.contextual-progress {
  margin: 20px 0;
}
.contextual-progress .progress-title {
  float: left;
}
.contextual-progress .progress-label {
  position: relative;

  float: right;
}
.contextual-progress .progress {
  height: 2px;
  margin: 5px 0;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

@-o-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

@-webkit-keyframes progress-active {
  0% {
    width: 0;

    opacity: 0.4;
  }
  100% {
    width: 100%;

    opacity: 0;
  }
}

@-o-keyframes progress-active {
  0% {
    width: 0;

    opacity: 0.4;
  }
  100% {
    width: 100%;

    opacity: 0;
  }
}

@keyframes progress-active {
  0% {
    width: 0;

    opacity: 0.4;
  }
  100% {
    width: 100%;

    opacity: 0;
  }
}

@-webkit-keyframes progress-vertical-active {
  0% {
    top: 0;

    opacity: 0;
  }
  100% {
    top: 175px;

    opacity: 0.4;
  }
}

@-o-keyframes progress-vertical-active {
  0% {
    top: 0;

    opacity: 0;
  }
  100% {
    top: 175px;

    opacity: 0.4;
  }
}

@keyframes progress-vertical-active {
  0% {
    top: 0;

    opacity: 0;
  }
  100% {
    top: 175px;

    opacity: 0.4;
  }
}

.media .media {
  padding-bottom: 0;

  border-bottom: none;
}

.media-body {
  overflow: auto;
}

.media-meta {
  font-size: 0.858rem;

  margin-bottom: 3px;

  color: #a3afb7;
}

.media-list {
  padding-left: 0;

  list-style: none;
}
.media-list > .media {
  margin-top: 15px;
}
.media-list > .media:first-child {
  margin-top: 0;
}

@media (min-width: 768px) {
  .media-body {
    overflow: hidden;
  }
  .media .media {
    margin-left: 0;
  }
}

@media (max-width: 479px) {
  .media {
    flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
  }
  .media > div:first-child {
    margin-bottom: 5px;
  }
  .media .media {
    padding-left: 15px;
  }
}

.list-group {
  margin-bottom: 20px;
}
.list-group-item > .media:only-child {
  margin: 0;
}
.list-group .media {
  width: 100%;
  padding: 2px 0;

  border-bottom: 0;
}
.list-group .media-heading {
  font-size: 1rem;

  margin-top: 0;
  margin-bottom: 5px;
}

.list-group-full > .list-group-item {
  padding-right: 0;
  padding-left: 0;
}

a.list-group-item {
  color: #76838f;
  border-radius: 0.215rem;
}
a.list-group-item:focus,
a.list-group-item:hover {
  text-decoration: none;

  color: #76838f;
  background-color: #f3f7f9;
}
a.list-group-item.disabled,
a.list-group-item.disabled:focus,
a.list-group-item.disabled:hover {
  color: #ccd5db;
  background-color: #f3f7f9;
}
a.list-group-item.active,
a.list-group-item.active:focus,
a.list-group-item.active:hover {
  color: #fff;
  background-color: #00b3d4;
}

.list-group-item .icon {
  margin-right: 10px;
}

.list-group.bg-inherit {
  border-radius: 0.215rem;
}
.list-group.bg-inherit .list-group-item {
  border-bottom-color: rgba(0, 0, 0, 0.075);
  background-color: transparent;
}
.list-group.bg-inherit .list-group-item:last-child {
  border-bottom-color: transparent;
}
.list-group.bg-inherit .list-group-item:hover {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.075);
}

.list-group-bordered .list-group-item {
  border-color: #e4eaec;
}
.list-group-bordered .list-group-item.active,
.list-group-bordered .list-group-item.active:hover,
.list-group-bordered .list-group-item.active:focus {
  color: #fff;
  border-color: #4e97d9;
  background-color: #4e97d9;
}

.list-group-dividered .list-group-item {
  border-top-color: #e4eaec;
}
.list-group-dividered .list-group-item.active:hover {
  border-top-color: #e4eaec;
}

.list-group-dividered .list-group-item:last-child {
  border-bottom-color: #e4eaec;
}

.list-group-dividered .list-group-item:first-child {
  border-top-color: transparent;
}
.list-group-dividered .list-group-item:first-child.active:hover {
  border-top-color: transparent;
}

.list-group-gap .list-group-item {
  margin-bottom: 2px;

  border-radius: 0.215rem;
}

.list-group-full .list-group-item {
  padding-right: 0;
  padding-left: 0;
}

.list-group-item {
  border: 1px solid transparent;
}
.list-group-item.disabled .list-group-item-heading,
.list-group-item.disabled:focus .list-group-item-heading,
.list-group-item.disabled:hover .list-group-item-heading {
  color: inherit;
}
.list-group-item.disabled .list-group-item-text,
.list-group-item.disabled:focus .list-group-item-text,
.list-group-item.disabled:hover .list-group-item-text {
  color: #ccd5db;
}
.list-group-item.active .list-group-item-heading,
.list-group-item.active .list-group-item-heading > small,
.list-group-item.active .list-group-item-heading > .small,
.list-group-item.active:focus .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading > small,
.list-group-item.active:focus .list-group-item-heading > .small,
.list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading > small,
.list-group-item.active:hover .list-group-item-heading > .small {
  color: inherit;
}
.list-group-item.active .list-group-item-text,
.list-group-item.active:focus .list-group-item-text,
.list-group-item.active:hover .list-group-item-text {
  color: white;
}

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.list-group-item-text {
  line-height: 1.3;

  margin-bottom: 0;
}

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
  color: #00b3d4;
  border-color: transparent;
  background-color: transparent;
}
.list-group-item.active .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading {
  color: inherit;
}

.list-group-item-dark {
  color: #fff;
  background-color: #526069;
}

a.list-group-item-dark,
button.list-group-item-dark {
  color: #fff;
}
a.list-group-item-dark:focus,
a.list-group-item-dark:hover,
button.list-group-item-dark:focus,
button.list-group-item-dark:hover {
  color: #fff;
  background-color: #47535b;
}
a.list-group-item-dark.active,
button.list-group-item-dark.active {
  color: #fff;
  border-color: #fff;
  background-color: #fff;
}

.list-group-hover .list-group-item:hover {
  background-color: #f3f7f9;
}

.list-group-hover .list-group-item-success,
.list-group-hover a.list-group-item-success,
.list-group-hover button.list-group-item-success {
  color: #fff;
}
.list-group-hover .list-group-item-success:focus,
.list-group-hover .list-group-item-success:hover,
.list-group-hover a.list-group-item-success:focus,
.list-group-hover a.list-group-item-success:hover,
.list-group-hover button.list-group-item-success:focus,
.list-group-hover button.list-group-item-success:hover {
  color: #fff;
  background-color: #3dae7d;
}
.list-group-hover .list-group-item-success.active,
.list-group-hover a.list-group-item-success.active,
.list-group-hover button.list-group-item-success.active {
  color: #fff;
  border-color: #fff;
  background-color: #fff;
}

.list-group-hover .list-group-item-info,
.list-group-hover a.list-group-item-info,
.list-group-hover button.list-group-item-info {
  color: #fff;
}
.list-group-hover .list-group-item-info:focus,
.list-group-hover .list-group-item-info:hover,
.list-group-hover a.list-group-item-info:focus,
.list-group-hover a.list-group-item-info:hover,
.list-group-hover button.list-group-item-info:focus,
.list-group-hover button.list-group-item-info:hover {
  color: #fff;
  background-color: #43c0cf;
}
.list-group-hover .list-group-item-info.active,
.list-group-hover a.list-group-item-info.active,
.list-group-hover button.list-group-item-info.active {
  color: #fff;
  border-color: #fff;
  background-color: #fff;
}

.list-group-hover .list-group-item-warning,
.list-group-hover a.list-group-item-warning,
.list-group-hover button.list-group-item-warning {
  color: #fff;
}
.list-group-hover .list-group-item-warning:focus,
.list-group-hover .list-group-item-warning:hover,
.list-group-hover a.list-group-item-warning:focus,
.list-group-hover a.list-group-item-warning:hover,
.list-group-hover button.list-group-item-warning:focus,
.list-group-hover button.list-group-item-warning:hover {
  color: #fff;
  background-color: #f09a3c;
}
.list-group-hover .list-group-item-warning.active,
.list-group-hover a.list-group-item-warning.active,
.list-group-hover button.list-group-item-warning.active {
  color: #fff;
  border-color: #fff;
  background-color: #fff;
}

.list-group-hover .list-group-item-danger,
.list-group-hover a.list-group-item-danger,
.list-group-hover button.list-group-item-danger {
  color: #fff;
}
.list-group-hover .list-group-item-danger:focus,
.list-group-hover .list-group-item-danger:hover,
.list-group-hover a.list-group-item-danger:focus,
.list-group-hover a.list-group-item-danger:hover,
.list-group-hover button.list-group-item-danger:focus,
.list-group-hover button.list-group-item-danger:hover {
  color: #fff;
  background-color: #f84f4f;
}
.list-group-hover .list-group-item-danger.active,
.list-group-hover a.list-group-item-danger.active,
.list-group-hover button.list-group-item-danger.active {
  color: #fff;
  border-color: #fff;
  background-color: #fff;
}

.list-group-hover .list-group-item-dark,
.list-group-hover a.list-group-item-dark,
.list-group-hover button.list-group-item-dark {
  color: #fff;
}
.list-group-hover .list-group-item-dark:focus,
.list-group-hover .list-group-item-dark:hover,
.list-group-hover a.list-group-item-dark:focus,
.list-group-hover a.list-group-item-dark:hover,
.list-group-hover button.list-group-item-dark:focus,
.list-group-hover button.list-group-item-dark:hover {
  color: #fff;
  background-color: #47535b;
}
.list-group-hover .list-group-item-dark.active,
.list-group-hover a.list-group-item-dark.active,
.list-group-hover button.list-group-item-dark.active {
  color: #fff;
  border-color: #fff;
  background-color: #fff;
}

.close.icon {
  font-size: inherit;
}

button.close {
  cursor: pointer;
}

.card {
  margin-bottom: 2.143rem;

  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.card .cover {
  width: 100%;
  padding: 0;

  border-bottom: none;
}
[class*="blocks-"] > li > .card {
  margin-bottom: 0;
}

.card-title {
  margin-top: 0;
}

.card-header,
.card-footer {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.card-bordered {
  border: 1px solid #e4eaec;
}

.card-header-bordered {
  border-bottom: 1px solid #e4eaec;
}

.card-footer-bordered {
  border-top: 1px solid #e4eaec;
}

.card-transparent {
  background-color: transparent;
}

.card-header-transparent {
  background-color: transparent;
}

.card-footer-transparent {
  background-color: transparent;
}

.card-shadow {
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.card-block {
  position: relative;
}

.card-text.type-link a {
  position: relative;

  display: inline-block;

  padding: 3px 5px;

  color: #a3afb7;
}
.card-text.type-link a:first-child {
  padding-left: 0;
}
.card-text.type-link a:hover {
  color: #ccd5db;
}
.card-text.type-link a + a:before {
  position: absolute;
  top: 10px;
  left: -2px;

  width: 3px;
  height: 3px;

  content: "";

  border-radius: 50%;
  background-color: #a3afb7;
}

.card-watermark {
  line-height: 1;

  position: absolute;
  right: 0;
  bottom: 0;

  opacity: 0.1;
}
.card-watermark.darker {
  color: black;
}
.card-watermark.lighter {
  color: white;
}

.card-divider:after {
  display: block;

  width: 20px;
  height: 2px;
  margin: 15px auto;

  content: "";

  background-color: #fff;
}

.card-actions {
  margin-top: 10px;

  text-align: right;
}
.card-actions a {
  display: inline-block;

  margin-right: 10px;

  vertical-align: middle;

  color: #a3afb7;
}
.card-actions a.icon,
.card-actions a .icon {
  text-decoration: none;
}
.card-actions a.icon + span,
.card-actions a .icon + span {
  margin-left: 2px;
}
.card-actions a.active,
.card-actions a:hover,
.card-actions a:focus {
  text-decoration: none;

  color: #ccd5db;
}
.card-actions a:last-child {
  margin-right: 0;
}

.card-actions-sidebar {
  position: absolute;
  top: 20px;
  left: 20px;

  width: 60px;
}
.card-actions-sidebar a {
  display: inline-block;

  width: 100%;
  height: 60px;
  margin-right: 0;

  text-align: center;

  border-right: 1px solid #e4eaec;
}
.card-actions-sidebar a:before {
  display: inline-block;

  height: 100%;

  content: "";
  vertical-align: middle;
}
.card-actions-sidebar a + a {
  border-top: 1px solid #e4eaec;
}
.card-actions-sidebar + .card-content {
  margin-left: 80px;
}

.card-img {
  border-radius: 0;
}

.card-img-left {
  position: absolute;
  top: 0;
  left: 0;

  width: 40%;
  height: 100%;

  border-radius: -webkit-calc(0.215rem - 1px) 0 0 -webkit-calc(0.215rem - 1px);
  border-radius: calc(0.215rem - 1px) 0 0 calc(0.215rem - 1px);
}
.card-img-left ~ .card-block {
  width: 60%;
  margin-left: 40%;
}
@media (max-width: 767px) {
  .card-img-left {
    position: relative;

    width: 100%;
    height: 320px;
  }
  .card-img-left ~ .card-block {
    width: 100%;
    margin-left: 0;
  }
}

.card-default {
  background-color: #e4eaec;
}
.card-default.card-bordered,
.card-default .card-header-bordered,
.card-default .card-footer-bordered {
  border-color: #e4eaec;
}

.card-columns .card {
  margin-bottom: 2.143rem;

  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.card-sm .card-header {
  padding: 0.858rem 1.072rem;
}

.card-sm .card-block {
  padding: 1.072rem;
}

.card-sm .card-footer {
  padding: 0.858rem 1.072rem;
}

.card-md .card-header {
  padding: 1.786rem 2.143rem;
}

.card-md .card-block {
  padding: 1.786rem;
}

.card-md .card-footer {
  padding: 2.143rem 1.786rem;
}

.card-lg .card-header {
  padding: 2.143rem;
}

.card-lg .card-block {
  padding: 2.143rem;
}

.card-lg .card-footer {
  padding: 2.143rem;
}

.card-header-actions {
  float: right;

  margin: 0;
}

.card > .list-group:not(:first-child) > .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card > .list-group:not(:last-child) > .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card .card-block + .card-block {
  padding-top: 0;
}

body {
  font-weight: 300;
}

th {
  font-weight: 400;
}

b,
strong {
  font-weight: 500;
}

optgroup {
  font-weight: 500;
}

h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small,
h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small,
.h1 small,
.h1 .small,
.h2 small,
.h2 .small,
.h3 small,
.h3 .small,
.h4 small,
.h4 .small,
.h5 small,
.h5 .small,
.h6 small,
.h6 .small {
  font-weight: 300;
}

.lead {
  font-weight: 100;
}

dt {
  font-weight: 500;
}

kbd kbd {
  font-weight: 500;
}

label {
  font-weight: 300;
}

.radio label,
.checkbox label {
  font-weight: 300;
}

.radio-inline,
.checkbox-inline {
  font-weight: 300;
}

.btn-link {
  font-weight: 300;
}

.dropdown-menu > li > a {
  font-weight: 100;
}

.input-group-addon {
  font-weight: 300;
}

.badge {
  font-weight: 500;
}

.popover {
  font-weight: 300;
}

.tooltip {
  font-weight: 300;
}

.modal-content {
  border: none;
}

.modal-header {
  padding: 15px 20px;

  border-bottom: none;
}

.modal-simple .modal-header + .modal-body {
  padding-top: 0;
}

.modal-header .modal-title {
  margin-top: 0;

  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}

.modal-header .close {
  margin-top: 1px;

  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
}

.modal-footer {
  padding: 6px 20px 20px;

  border-top: none;
}
.modal-footer .btn + .btn {
  margin-left: 5px;
}
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

@media (min-width: 480px) {
  .modal-dialog {
    margin: 30px;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    width: auto;
    margin: 30px auto;
  }
  .modal-sm {
    width: 300px;
  }
}

.modal-top {
  margin: 0 auto;
}

.modal-center {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;

  height: 100%;
  margin: 0 auto;

  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: center;
  align-content: center;
}

.modal-bottom {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;

  height: 100%;
  margin: 0 auto;

  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: center;
  align-content: center;
}

.modal-sidebar {
  position: absolute;
  right: 0;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;

  height: 100%;
  margin: 0;

  background-color: #fff;

  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: center;
  align-content: center;
}
.modal-sidebar .modal-content {
  overflow-x: hidden;
  overflow-y: auto;

  border-radius: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.modal-sidebar .modal-header {
  border-bottom: none;
}
.modal-sidebar .modal-footer {
  border-top: none;

  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.modal-sidebar button.close {
  position: fixed;
  z-index: 1;
  top: 20px;
  right: 20px;
}

.modal.fade .modal-dialog.modal-sidebar {
  -webkit-transform: translate(25%, 0px);
  -ms-transform: translate(25%, 0px);
  -o-transform: translate(25%, 0px);
  transform: translate(25%, 0px);
}

.modal.show .modal-dialog.modal-sidebar {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.modal-fill-in {
  background-color: transparent;
}
.modal-fill-in.show {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.95);
}
.modal-fill-in .modal-dialog {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;

  max-width: 100%;
  height: 100%;
  margin: 0 auto;

  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}
@media (min-width: 768px) {
  .modal-fill-in .modal-dialog > * {
    width: 600px;
  }
  .modal-fill-in .modal-dialog.modal-sm > * {
    width: 300px;
  }
  .modal-fill-in .modal-dialog button.close {
    font-size: 2rem;

    position: fixed;
    z-index: 1;
    top: 20px;
    right: 40px;

    -webkit-transform: translate(0%, 0%);
    -ms-transform: translate(0%, 0%);
    -o-transform: translate(0%, 0%);
    transform: translate(0%, 0%);

    opacity: 0.5;
  }
}
@media (min-width: 992px) {
  .modal-fill-in .modal-dialog.modal-lg > * {
    width: 900px;
  }
}
.modal-fill-in .modal-content {
  border-radius: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.modal-primary .modal-header {
  border-radius: 0.286rem 0.286rem 0 0;
  background-color: #00b3d4;
}
.modal-primary .modal-header * {
  color: #fff;
}
.modal-primary .modal-header .close {
  opacity: 0.6;
}

.modal-success .modal-header {
  border-radius: 0.286rem 0.286rem 0 0;
  background-color: #8ebe31;
}
.modal-success .modal-header * {
  color: #fff;
}
.modal-success .modal-header .close {
  opacity: 0.6;
}

.modal-info .modal-header {
  border-radius: 0.286rem 0.286rem 0 0;
  background-color: #57c7d4;
}
.modal-info .modal-header * {
  color: #fff;
}
.modal-info .modal-header .close {
  opacity: 0.6;
}

.modal-warning .modal-header {
  border-radius: 0.286rem 0.286rem 0 0;
  background-color: #f2a654;
}
.modal-warning .modal-header * {
  color: #fff;
}
.modal-warning .modal-header .close {
  opacity: 0.6;
}

.modal-danger .modal-header {
  border-radius: 0.286rem 0.286rem 0 0;
  background-color: #f96868;
}
.modal-danger .modal-header * {
  color: #fff;
}
.modal-danger .modal-header .close {
  opacity: 0.6;
}

.modal.modal-fade-in-scale-up .modal-dialog {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  -o-transform: scale(0.7);
  transform: scale(0.7);

  opacity: 0;
}

.modal.modal-fade-in-scale-up.show .modal-dialog {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);

  opacity: 1;
}

.modal.modal-slide-in-right .modal-dialog {
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9) 0s;
  -o-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9) 0s;
  transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9) 0s;
  -webkit-transform: translate(20%, 0%);
  -ms-transform: translate(20%, 0%);
  -o-transform: translate(20%, 0%);
  transform: translate(20%, 0%);

  opacity: 0;
}

.modal.modal-slide-in-right.show .modal-dialog {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  transform: translate(0px, 0px);

  opacity: 1;
}

.modal.modal-slide-from-bottom .modal-dialog {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: translate(0%, 20%);
  -ms-transform: translate(0%, 20%);
  -o-transform: translate(0%, 20%);
  transform: translate(0%, 20%);

  opacity: 0;
}

.modal.modal-slide-from-bottom.show .modal-dialog {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  transform: translate(0px, 0px);

  opacity: 1;
}

.modal.modal-newspaper .modal-dialog {
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: scale(0) rotate(720deg);
  -ms-transform: scale(0) rotate(720deg);
  -o-transform: scale(0) rotate(720deg);
  transform: scale(0) rotate(720deg);

  opacity: 0;
}

.modal.modal-newspaper.show .modal-dialog {
  -webkit-transform: scale(1) rotate(0deg);
  -ms-transform: scale(1) rotate(0deg);
  -o-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);

  opacity: 1;
}

.modal.modal-fall {
  -webkit-perspective: 1300px;
  perspective: 1300px;
}
.modal.modal-fall .modal-dialog {
  -webkit-transform: translateZ(600px) rotateX(20deg);
  transform: translateZ(600px) rotateX(20deg);

  opacity: 0;

  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.modal.modal-fall.show .modal-dialog {
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
  -webkit-transform: translateZ(0px) rotateX(0deg);
  transform: translateZ(0px) rotateX(0deg);

  opacity: 1;
}

.modal.modal-side-fall {
  -webkit-perspective: 1300px;
  perspective: 1300px;
}
.modal.modal-side-fall .modal-dialog {
  -webkit-transform: translate(30%) translateZ(600px) rotate(10deg);
  transform: translate(30%) translateZ(600px) rotate(10deg);

  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.modal.modal-side-fall.show .modal-dialog {
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
  -webkit-transform: translate(0%) translateZ(0px) rotate(0deg);
  transform: translate(0%) translateZ(0px) rotate(0deg);
}

.modal.modal-3d-flip-horizontal {
  -webkit-perspective: 1300px;
  perspective: 1300px;
}
.modal.modal-3d-flip-horizontal .modal-dialog {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: rotateY(-70deg);
  transform: rotateY(-70deg);

  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.modal.modal-3d-flip-horizontal.show .modal-dialog {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.modal.modal-3d-flip-vertical {
  -webkit-perspective: 1300px;
  perspective: 1300px;
}
.modal.modal-3d-flip-vertical .modal-dialog {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: rotateX(-70deg);
  transform: rotateX(-70deg);

  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.modal.modal-3d-flip-vertical.show .modal-dialog {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.modal.modal-3d-sign {
  -webkit-perspective: 1300px;
  perspective: 1300px;
}
.modal.modal-3d-sign .modal-dialog {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: rotateX(-60deg);
  transform: rotateX(-60deg);
  -webkit-transform-origin: 50% 0 0;
  -ms-transform-origin: 50% 0 0;
  -o-transform-origin: 50% 0 0;
  transform-origin: 50% 0 0;

  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.modal.modal-3d-sign.show .modal-dialog {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.modal.modal-super-scaled .modal-dialog {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  transform: scale(2);

  opacity: 0;
}

.modal.modal-super-scaled.show .modal-dialog {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);

  opacity: 1;
}

.modal.modal-just-me .modal-dialog {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);

  opacity: 0;
}

.modal.modal-just-me .modal-backdrop {
  background-color: #fff;
}

.modal.modal-just-me.show {
  background: #fff;
}
.modal.modal-just-me.show .modal-dialog {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);

  opacity: 1;
}
.modal.modal-just-me.show .modal-backdrop {
  opacity: 1;
}

.modal.modal-3d-slit {
  -webkit-perspective: 1300px;
  perspective: 1300px;
}
.modal.modal-3d-slit .modal-dialog {
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateZ(-3000px) rotateY(90deg);
  transform: translateZ(-3000px) rotateY(90deg);

  opacity: 0;

  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.modal.modal-3d-slit.show .modal-dialog {
  -webkit-animation: slit 0.7s ease-out forwards;
  -o-animation: slit 0.7s ease-out forwards;
  animation: slit 0.7s ease-out forwards;
}

.modal.modal-rotate-from-bottom {
  -webkit-perspective: 1300px;
  perspective: 1300px;
}
.modal.modal-rotate-from-bottom .modal-dialog {
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-transform: translateY(100%) rotateX(90deg);
  transform: translateY(100%) rotateX(90deg);
  -webkit-transform-origin: 0 100% 0;
  -ms-transform-origin: 0 100% 0;
  -o-transform-origin: 0 100% 0;
  transform-origin: 0 100% 0;

  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.modal.modal-rotate-from-bottom.show .modal-dialog {
  -webkit-transform: translateY(0%) rotateX(0deg);
  transform: translateY(0%) rotateX(0deg);
}

.modal.modal-rotate-from-left {
  -webkit-perspective: 1300px;
  perspective: 1300px;
}
.modal.modal-rotate-from-left .modal-dialog {
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  -webkit-transform-origin: 0 100% 0;
  -ms-transform-origin: 0 100% 0;
  -o-transform-origin: 0 100% 0;
  transform-origin: 0 100% 0;

  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.modal.modal-rotate-from-left.show .modal-dialog {
  -webkit-transform: translateZ(0px) translateX(0%) rotateY(0deg);
  transform: translateZ(0px) translateX(0%) rotateY(0deg);
}

@-webkit-keyframes slit {
  50% {
    -webkit-transform: translateZ(-250px) rotateY(89deg);
    transform: translateZ(-250px) rotateY(89deg);

    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateZ(0px) rotateY(0deg);
    transform: translateZ(0px) rotateY(0deg);

    opacity: 1;
  }
}

@-o-keyframes slit {
  50% {
    transform: translateZ(-250px) rotateY(89deg);

    opacity: 0.5;
  }
  100% {
    transform: translateZ(0px) rotateY(0deg);

    opacity: 1;
  }
}

@keyframes slit {
  50% {
    -webkit-transform: translateZ(-250px) rotateY(89deg);
    transform: translateZ(-250px) rotateY(89deg);

    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateZ(0px) rotateY(0deg);
    transform: translateZ(0px) rotateY(0deg);

    opacity: 1;
  }
}

.tooltip-primary .tooltip-inner {
  color: #fff;
  background-color: #00b3d4;
}

.tooltip-primary.bs-tether-element-attached-top .tooltip-inner::before,
.tooltip-primary.tooltip-top .tooltip-inner::before {
  border-bottom-color: #00b3d4;
}

.tooltip-primary.bs-tether-element-attached-right .tooltip-inner::before,
.tooltip-primary.tooltip-right .tooltip-inner::before {
  border-left-color: #00b3d4;
}

.tooltip-primary.bs-tether-element-attached-bottom .tooltip-inner::before,
.tooltip-primary.tooltip-bottom .tooltip-inner::before {
  border-top-color: #00b3d4;
}

.tooltip-primary.bs-tether-element-attached-left .tooltip-inner::before,
.tooltip-primary.tooltip-left .tooltip-inner::before {
  border-right-color: #00b3d4;
}

.tooltip-success .tooltip-inner {
  color: #fff;
  background-color: #8ebe31;
}

.tooltip-success.bs-tether-element-attached-top .tooltip-inner::before,
.tooltip-success.tooltip-top .tooltip-inner::before {
  border-bottom-color: #8ebe31;
}

.tooltip-success.bs-tether-element-attached-right .tooltip-inner::before,
.tooltip-success.tooltip-right .tooltip-inner::before {
  border-left-color: #8ebe31;
}

.tooltip-success.bs-tether-element-attached-bottom .tooltip-inner::before,
.tooltip-success.tooltip-bottom .tooltip-inner::before {
  border-top-color: #8ebe31;
}

.tooltip-success.bs-tether-element-attached-left .tooltip-inner::before,
.tooltip-success.tooltip-left .tooltip-inner::before {
  border-right-color: #8ebe31;
}

.tooltip-info .tooltip-inner {
  color: #fff;
  background-color: #57c7d4;
}

.tooltip-info.bs-tether-element-attached-top .tooltip-inner::before,
.tooltip-info.tooltip-top .tooltip-inner::before {
  border-bottom-color: #57c7d4;
}

.tooltip-info.bs-tether-element-attached-right .tooltip-inner::before,
.tooltip-info.tooltip-right .tooltip-inner::before {
  border-left-color: #57c7d4;
}

.tooltip-info.bs-tether-element-attached-bottom .tooltip-inner::before,
.tooltip-info.tooltip-bottom .tooltip-inner::before {
  border-top-color: #57c7d4;
}

.tooltip-info.bs-tether-element-attached-left .tooltip-inner::before,
.tooltip-info.tooltip-left .tooltip-inner::before {
  border-right-color: #57c7d4;
}

.tooltip-warning .tooltip-inner {
  color: #fff;
  background-color: #f2a654;
}

.tooltip-warning.bs-tether-element-attached-top .tooltip-inner::before,
.tooltip-warning.tooltip-top .tooltip-inner::before {
  border-bottom-color: #f2a654;
}

.tooltip-warning.bs-tether-element-attached-right .tooltip-inner::before,
.tooltip-warning.tooltip-right .tooltip-inner::before {
  border-left-color: #f2a654;
}

.tooltip-warning.bs-tether-element-attached-bottom .tooltip-inner::before,
.tooltip-warning.tooltip-bottom .tooltip-inner::before {
  border-top-color: #f2a654;
}

.tooltip-warning.bs-tether-element-attached-left .tooltip-inner::before,
.tooltip-warning.tooltip-left .tooltip-inner::before {
  border-right-color: #f2a654;
}

.tooltip-danger .tooltip-inner {
  color: #fff;
  background-color: #f96868;
}

.tooltip-danger.bs-tether-element-attached-top .tooltip-inner::before,
.tooltip-danger.tooltip-top .tooltip-inner::before {
  border-bottom-color: #f96868;
}

.tooltip-danger.bs-tether-element-attached-right .tooltip-inner::before,
.tooltip-danger.tooltip-right .tooltip-inner::before {
  border-left-color: #f96868;
}

.tooltip-danger.bs-tether-element-attached-bottom .tooltip-inner::before,
.tooltip-danger.tooltip-bottom .tooltip-inner::before {
  border-top-color: #f96868;
}

.tooltip-danger.bs-tether-element-attached-left .tooltip-inner::before,
.tooltip-danger.tooltip-left .tooltip-inner::before {
  border-right-color: #f96868;
}

.popover {
  padding: 0;

  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}
.popover.bs-tether-element-attached-top > .popover-arrow:after,
.popover.popover-bottom > .popover-arrow:after {
  border-bottom-color: #f3f7f9;
}

.popover-title {
  margin: 0;
}

.popover-primary .popover-title {
  color: #fff;
  border-color: #00b3d4;
  background-color: #00b3d4;
}

.popover-primary.bs-tether-element-attached-top .popover-arrow,
.popover-primary.popover-bottom .popover-arrow {
  border-bottom-color: #00b3d4;
}
.popover-primary.bs-tether-element-attached-top .popover-arrow:after,
.popover-primary.popover-bottom .popover-arrow:after {
  border-bottom-color: #00b3d4;
}

.popover-success .popover-title {
  color: #fff;
  border-color: #8ebe31;
  background-color: #8ebe31;
}

.popover-success.bs-tether-element-attached-top .popover-arrow,
.popover-success.popover-bottom .popover-arrow {
  border-bottom-color: #8ebe31;
}
.popover-success.bs-tether-element-attached-top .popover-arrow:after,
.popover-success.popover-bottom .popover-arrow:after {
  border-bottom-color: #8ebe31;
}

.popover-info .popover-title {
  color: #fff;
  border-color: #57c7d4;
  background-color: #57c7d4;
}

.popover-info.bs-tether-element-attached-top .popover-arrow,
.popover-info.popover-bottom .popover-arrow {
  border-bottom-color: #57c7d4;
}
.popover-info.bs-tether-element-attached-top .popover-arrow:after,
.popover-info.popover-bottom .popover-arrow:after {
  border-bottom-color: #57c7d4;
}

.popover-warning .popover-title {
  color: #fff;
  border-color: #f2a654;
  background-color: #f2a654;
}

.popover-warning.bs-tether-element-attached-top .popover-arrow,
.popover-warning.popover-bottom .popover-arrow {
  border-bottom-color: #f2a654;
}
.popover-warning.bs-tether-element-attached-top .popover-arrow:after,
.popover-warning.popover-bottom .popover-arrow:after {
  border-bottom-color: #f2a654;
}

.popover-danger .popover-title {
  color: #fff;
  border-color: #f96868;
  background-color: #f96868;
}

.popover-danger.bs-tether-element-attached-top .popover-arrow,
.popover-danger.popover-bottom .popover-arrow {
  border-bottom-color: #f96868;
}
.popover-danger.bs-tether-element-attached-top .popover-arrow:after,
.popover-danger.popover-bottom .popover-arrow:after {
  border-bottom-color: #f96868;
}

.carousel-control-next,
.carousel-control-prev {
  min-width: 50px;
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  opacity: 0.4;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  z-index: 5;

  background-image: none;
}

.carousel-caption h1,
.carousel-caption h2,
.carousel-caption h3,
.carousel-caption h4,
.carousel-caption h5,
.carousel-caption h6 {
  color: inherit;
}

.carousel-indicators li {
  cursor: pointer;

  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.3);
}

.carousel-indicators-scaleup li {
  -webkit-transition: -webkit-transform 0.3s ease 0s,
    background-color 0.3s ease 0s;
  -o-transition: -o-transform 0.3s ease 0s, background-color 0.3s ease 0s;
  transition: transform 0.3s ease 0s, background-color 0.3s ease 0s;

  border: none;
}

.carousel-indicators-scaleup .active {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
}

.carousel-indicators-fillin li {
  -webkit-transition: -webkit-box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;

  background-color: transparent;
  -webkit-box-shadow: 0 0 0 2px #fff inset;
  box-shadow: 0 0 0 2px #fff inset;
}

.carousel-indicators-fillin .active {
  -webkit-box-shadow: 0 0 0 8px #fff inset;
  box-shadow: 0 0 0 8px #fff inset;
}

.carousel-indicators-fall li {
  position: relative;

  -webkit-transition: -webkit-transform 0.3s ease 0s,
    background-color 0.3s ease 0s;
  -o-transition: -o-transform 0.3s ease 0s, background-color 0.3s ease 0s;
  transition: transform 0.3s ease 0s, background-color 0.3s ease 0s;
}
.carousel-indicators-fall li:after {
  position: absolute;
  top: 0;
  left: 0;

  visibility: hidden;

  width: 100%;
  height: 100%;

  content: "";
  -webkit-transition: opacity 0.3s ease 0s, visibility 0s ease 0.3s;
  -o-transition: opacity 0.3s ease 0s, visibility 0s ease 0.3s;
  transition: opacity 0.3s ease 0s, visibility 0s ease 0.3s;
  -webkit-transform: translate(0%, -200%);
  -ms-transform: translate(0%, -200%);
  -o-transform: translate(0%, -200%);
  transform: translate(0%, -200%);

  opacity: 0;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
}

.carousel-indicators-fall .active {
  -webkit-transform: translate(0, 200%);
  -ms-transform: translate(0, 200%);
  -o-transform: translate(0, 200%);
  transform: translate(0, 200%);

  background-color: transparent;
}
.carousel-indicators-fall .active:after {
  visibility: visible;

  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;

  opacity: 1;
}

@media (min-width: 768px) {
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    font-size: 24px;

    width: 24px;
    height: 24px;
  }
}

.vertical-align {
  font-size: 0;
}
.vertical-align:before {
  display: inline-block;

  height: 100%;

  content: "";
  vertical-align: middle;
}
.vertical-align-middle,
.vertical-align-bottom {
  font-size: 1rem;

  display: inline-block;

  max-width: 100%;
}
.vertical-align-middle {
  vertical-align: middle;
}
.vertical-align-bottom {
  vertical-align: bottom;
}

.inline {
  display: inline !important;
}

.inline-block {
  display: inline-block !important;
}

.block {
  display: block !important;
}

.text-break {
  white-space: normal;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;

  -ms-hyphens: auto;
}

.text-top {
  vertical-align: top !important;
}

.text-middle {
  vertical-align: middle !important;
}

.text-bottom {
  vertical-align: bottom !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.font-weight-unset {
  font-weight: unset !important;
}

.font-weight-100 {
  font-weight: 100 !important;
}

.font-weight-200 {
  font-weight: 200 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.font-weight-light {
  font-weight: 100 !important;
}

.font-weight-normal {
  font-weight: 300 !important;
}

.font-weight-medium {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.font-size-0 {
  font-size: 0 !important;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-26 {
  font-size: 26px !important;
}

.font-size-30 {
  font-size: 30px !important;
}

.font-size-40 {
  font-size: 40px !important;
}

.font-size-50 {
  font-size: 50px !important;
}

.font-size-60 {
  font-size: 60px !important;
}

.font-size-70 {
  font-size: 70px !important;
}

.font-size-80 {
  font-size: 80px !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-3 {
  margin: 3px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mr-3 {
  margin-right: 3px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.ml-3 {
  margin-left: 3px !important;
}

.mx-3 {
  margin-right: 3px !important;
  margin-left: 3px !important;
}

.my-3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.m-5 {
  margin: 5px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mx-5 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mx-10 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.mx-15 {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mx-20 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.mx-25 {
  margin-right: 25px !important;
  margin-left: 25px !important;
}

.my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mx-30 {
  margin-right: 30px !important;
  margin-left: 30px !important;
}

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.m-35 {
  margin: 35px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.mx-35 {
  margin-right: 35px !important;
  margin-left: 35px !important;
}

.my-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.m-40 {
  margin: 40px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.mx-40 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.mx-45 {
  margin-right: 45px !important;
  margin-left: 45px !important;
}

.my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.mx-50 {
  margin-right: 50px !important;
  margin-left: 50px !important;
}

.my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.m-60 {
  margin: 60px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.mx-60 {
  margin-right: 60px !important;
  margin-left: 60px !important;
}

.my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.m-70 {
  margin: 70px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.mx-70 {
  margin-right: 70px !important;
  margin-left: 70px !important;
}

.my-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.m-80 {
  margin: 80px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.mx-80 {
  margin-right: 80px !important;
  margin-left: 80px !important;
}

.my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.m-100 {
  margin: 100px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.mx-100 {
  margin-right: 100px !important;
  margin-left: 100px !important;
}

.my-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-3 {
  padding: 3px !important;
}

.pt-3 {
  padding-top: 3px !important;
}

.pr-3 {
  padding-right: 3px !important;
}

.pb-3 {
  padding-bottom: 3px !important;
}

.pl-3 {
  padding-left: 3px !important;
}

.px-3 {
  padding-right: 3px !important;
  padding-left: 3px !important;
}

.py-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.p-5 {
  padding: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.px-5 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.px-10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.px-15 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.px-20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.px-25 {
  padding-right: 25px !important;
  padding-left: 25px !important;
}

.py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.px-30 {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.p-35 {
  padding: 35px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.px-35 {
  padding-right: 35px !important;
  padding-left: 35px !important;
}

.py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.p-40 {
  padding: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.px-40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.p-45 {
  padding: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.px-45 {
  padding-right: 45px !important;
  padding-left: 45px !important;
}

.py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.p-50 {
  padding: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.px-50 {
  padding-right: 50px !important;
  padding-left: 50px !important;
}

.py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.p-60 {
  padding: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.px-60 {
  padding-right: 60px !important;
  padding-left: 60px !important;
}

.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.p-70 {
  padding: 70px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.px-70 {
  padding-right: 70px !important;
  padding-left: 70px !important;
}

.py-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.p-80 {
  padding: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.px-80 {
  padding-right: 80px !important;
  padding-left: 80px !important;
}

.py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.p-100 {
  padding: 100px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.px-100 {
  padding-right: 100px !important;
  padding-left: 100px !important;
}

.py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.m--30 {
  margin: -30px !important;
}

.mt--30 {
  margin-top: -30px !important;
}

.mr--30 {
  margin-right: -30px !important;
}

.mb--30 {
  margin-bottom: -30px !important;
}

.ml--30 {
  margin-left: -30px !important;
}

.mx--30 {
  margin-right: -30px !important;
  margin-left: -30px !important;
}

.my--30 {
  margin-top: -30px !important;
  margin-bottom: -30px !important;
}

.m--25 {
  margin: -25px !important;
}

.mt--25 {
  margin-top: -25px !important;
}

.mr--25 {
  margin-right: -25px !important;
}

.mb--25 {
  margin-bottom: -25px !important;
}

.ml--25 {
  margin-left: -25px !important;
}

.mx--25 {
  margin-right: -25px !important;
  margin-left: -25px !important;
}

.my--25 {
  margin-top: -25px !important;
  margin-bottom: -25px !important;
}

.m--20 {
  margin: -20px !important;
}

.mt--20 {
  margin-top: -20px !important;
}

.mr--20 {
  margin-right: -20px !important;
}

.mb--20 {
  margin-bottom: -20px !important;
}

.ml--20 {
  margin-left: -20px !important;
}

.mx--20 {
  margin-right: -20px !important;
  margin-left: -20px !important;
}

.my--20 {
  margin-top: -20px !important;
  margin-bottom: -20px !important;
}

.m--15 {
  margin: -15px !important;
}

.mt--15 {
  margin-top: -15px !important;
}

.mr--15 {
  margin-right: -15px !important;
}

.mb--15 {
  margin-bottom: -15px !important;
}

.ml--15 {
  margin-left: -15px !important;
}

.mx--15 {
  margin-right: -15px !important;
  margin-left: -15px !important;
}

.my--15 {
  margin-top: -15px !important;
  margin-bottom: -15px !important;
}

.m--10 {
  margin: -10px !important;
}

.mt--10 {
  margin-top: -10px !important;
}

.mr--10 {
  margin-right: -10px !important;
}

.mb--10 {
  margin-bottom: -10px !important;
}

.ml--10 {
  margin-left: -10px !important;
}

.mx--10 {
  margin-right: -10px !important;
  margin-left: -10px !important;
}

.my--10 {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}

.m--5 {
  margin: -5px !important;
}

.mt--5 {
  margin-top: -5px !important;
}

.mr--5 {
  margin-right: -5px !important;
}

.mb--5 {
  margin-bottom: -5px !important;
}

.ml--5 {
  margin-left: -5px !important;
}

.mx--5 {
  margin-right: -5px !important;
  margin-left: -5px !important;
}

.my--5 {
  margin-top: -5px !important;
  margin-bottom: -5px !important;
}

.m--3 {
  margin: -3px !important;
}

.mt--3 {
  margin-top: -3px !important;
}

.mr--3 {
  margin-right: -3px !important;
}

.mb--3 {
  margin-bottom: -3px !important;
}

.ml--3 {
  margin-left: -3px !important;
}

.mx--3 {
  margin-right: -3px !important;
  margin-left: -3px !important;
}

.my--3 {
  margin-top: -3px !important;
  margin-bottom: -3px !important;
}

.m--1 {
  margin: -1px !important;
}

.mt--1 {
  margin-top: -1px !important;
}

.mr--1 {
  margin-right: -1px !important;
}

.mb--1 {
  margin-bottom: -1px !important;
}

.ml--1 {
  margin-left: -1px !important;
}

.mx--1 {
  margin-right: -1px !important;
  margin-left: -1px !important;
}

.my--1 {
  margin-top: -1px !important;
  margin-bottom: -1px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

@media (min-width: 480px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0 {
    margin-left: 0 !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-sm-3 {
    margin: 3px !important;
  }
  .mt-sm-3 {
    margin-top: 3px !important;
  }
  .mr-sm-3 {
    margin-right: 3px !important;
  }
  .mb-sm-3 {
    margin-bottom: 3px !important;
  }
  .ml-sm-3 {
    margin-left: 3px !important;
  }
  .mx-sm-3 {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
  .my-sm-3 {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .m-sm-5 {
    margin: 5px !important;
  }
  .mt-sm-5 {
    margin-top: 5px !important;
  }
  .mr-sm-5 {
    margin-right: 5px !important;
  }
  .mb-sm-5 {
    margin-bottom: 5px !important;
  }
  .ml-sm-5 {
    margin-left: 5px !important;
  }
  .mx-sm-5 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .my-sm-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .m-sm-10 {
    margin: 10px !important;
  }
  .mt-sm-10 {
    margin-top: 10px !important;
  }
  .mr-sm-10 {
    margin-right: 10px !important;
  }
  .mb-sm-10 {
    margin-bottom: 10px !important;
  }
  .ml-sm-10 {
    margin-left: 10px !important;
  }
  .mx-sm-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .my-sm-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .m-sm-15 {
    margin: 15px !important;
  }
  .mt-sm-15 {
    margin-top: 15px !important;
  }
  .mr-sm-15 {
    margin-right: 15px !important;
  }
  .mb-sm-15 {
    margin-bottom: 15px !important;
  }
  .ml-sm-15 {
    margin-left: 15px !important;
  }
  .mx-sm-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .my-sm-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .m-sm-20 {
    margin: 20px !important;
  }
  .mt-sm-20 {
    margin-top: 20px !important;
  }
  .mr-sm-20 {
    margin-right: 20px !important;
  }
  .mb-sm-20 {
    margin-bottom: 20px !important;
  }
  .ml-sm-20 {
    margin-left: 20px !important;
  }
  .mx-sm-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .my-sm-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .m-sm-25 {
    margin: 25px !important;
  }
  .mt-sm-25 {
    margin-top: 25px !important;
  }
  .mr-sm-25 {
    margin-right: 25px !important;
  }
  .mb-sm-25 {
    margin-bottom: 25px !important;
  }
  .ml-sm-25 {
    margin-left: 25px !important;
  }
  .mx-sm-25 {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .my-sm-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .m-sm-30 {
    margin: 30px !important;
  }
  .mt-sm-30 {
    margin-top: 30px !important;
  }
  .mr-sm-30 {
    margin-right: 30px !important;
  }
  .mb-sm-30 {
    margin-bottom: 30px !important;
  }
  .ml-sm-30 {
    margin-left: 30px !important;
  }
  .mx-sm-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .my-sm-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .m-sm-35 {
    margin: 35px !important;
  }
  .mt-sm-35 {
    margin-top: 35px !important;
  }
  .mr-sm-35 {
    margin-right: 35px !important;
  }
  .mb-sm-35 {
    margin-bottom: 35px !important;
  }
  .ml-sm-35 {
    margin-left: 35px !important;
  }
  .mx-sm-35 {
    margin-right: 35px !important;
    margin-left: 35px !important;
  }
  .my-sm-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .m-sm-40 {
    margin: 40px !important;
  }
  .mt-sm-40 {
    margin-top: 40px !important;
  }
  .mr-sm-40 {
    margin-right: 40px !important;
  }
  .mb-sm-40 {
    margin-bottom: 40px !important;
  }
  .ml-sm-40 {
    margin-left: 40px !important;
  }
  .mx-sm-40 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .my-sm-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .m-sm-45 {
    margin: 45px !important;
  }
  .mt-sm-45 {
    margin-top: 45px !important;
  }
  .mr-sm-45 {
    margin-right: 45px !important;
  }
  .mb-sm-45 {
    margin-bottom: 45px !important;
  }
  .ml-sm-45 {
    margin-left: 45px !important;
  }
  .mx-sm-45 {
    margin-right: 45px !important;
    margin-left: 45px !important;
  }
  .my-sm-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .m-sm-50 {
    margin: 50px !important;
  }
  .mt-sm-50 {
    margin-top: 50px !important;
  }
  .mr-sm-50 {
    margin-right: 50px !important;
  }
  .mb-sm-50 {
    margin-bottom: 50px !important;
  }
  .ml-sm-50 {
    margin-left: 50px !important;
  }
  .mx-sm-50 {
    margin-right: 50px !important;
    margin-left: 50px !important;
  }
  .my-sm-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .m-sm-60 {
    margin: 60px !important;
  }
  .mt-sm-60 {
    margin-top: 60px !important;
  }
  .mr-sm-60 {
    margin-right: 60px !important;
  }
  .mb-sm-60 {
    margin-bottom: 60px !important;
  }
  .ml-sm-60 {
    margin-left: 60px !important;
  }
  .mx-sm-60 {
    margin-right: 60px !important;
    margin-left: 60px !important;
  }
  .my-sm-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .m-sm-70 {
    margin: 70px !important;
  }
  .mt-sm-70 {
    margin-top: 70px !important;
  }
  .mr-sm-70 {
    margin-right: 70px !important;
  }
  .mb-sm-70 {
    margin-bottom: 70px !important;
  }
  .ml-sm-70 {
    margin-left: 70px !important;
  }
  .mx-sm-70 {
    margin-right: 70px !important;
    margin-left: 70px !important;
  }
  .my-sm-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .m-sm-80 {
    margin: 80px !important;
  }
  .mt-sm-80 {
    margin-top: 80px !important;
  }
  .mr-sm-80 {
    margin-right: 80px !important;
  }
  .mb-sm-80 {
    margin-bottom: 80px !important;
  }
  .ml-sm-80 {
    margin-left: 80px !important;
  }
  .mx-sm-80 {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
  .my-sm-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .m-sm-100 {
    margin: 100px !important;
  }
  .mt-sm-100 {
    margin-top: 100px !important;
  }
  .mr-sm-100 {
    margin-right: 100px !important;
  }
  .mb-sm-100 {
    margin-bottom: 100px !important;
  }
  .ml-sm-100 {
    margin-left: 100px !important;
  }
  .mx-sm-100 {
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
  .my-sm-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0 {
    padding-left: 0 !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-sm-3 {
    padding: 3px !important;
  }
  .pt-sm-3 {
    padding-top: 3px !important;
  }
  .pr-sm-3 {
    padding-right: 3px !important;
  }
  .pb-sm-3 {
    padding-bottom: 3px !important;
  }
  .pl-sm-3 {
    padding-left: 3px !important;
  }
  .px-sm-3 {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .py-sm-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .p-sm-5 {
    padding: 5px !important;
  }
  .pt-sm-5 {
    padding-top: 5px !important;
  }
  .pr-sm-5 {
    padding-right: 5px !important;
  }
  .pb-sm-5 {
    padding-bottom: 5px !important;
  }
  .pl-sm-5 {
    padding-left: 5px !important;
  }
  .px-sm-5 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .py-sm-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .p-sm-10 {
    padding: 10px !important;
  }
  .pt-sm-10 {
    padding-top: 10px !important;
  }
  .pr-sm-10 {
    padding-right: 10px !important;
  }
  .pb-sm-10 {
    padding-bottom: 10px !important;
  }
  .pl-sm-10 {
    padding-left: 10px !important;
  }
  .px-sm-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .py-sm-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .p-sm-15 {
    padding: 15px !important;
  }
  .pt-sm-15 {
    padding-top: 15px !important;
  }
  .pr-sm-15 {
    padding-right: 15px !important;
  }
  .pb-sm-15 {
    padding-bottom: 15px !important;
  }
  .pl-sm-15 {
    padding-left: 15px !important;
  }
  .px-sm-15 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .py-sm-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .p-sm-20 {
    padding: 20px !important;
  }
  .pt-sm-20 {
    padding-top: 20px !important;
  }
  .pr-sm-20 {
    padding-right: 20px !important;
  }
  .pb-sm-20 {
    padding-bottom: 20px !important;
  }
  .pl-sm-20 {
    padding-left: 20px !important;
  }
  .px-sm-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .py-sm-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .p-sm-25 {
    padding: 25px !important;
  }
  .pt-sm-25 {
    padding-top: 25px !important;
  }
  .pr-sm-25 {
    padding-right: 25px !important;
  }
  .pb-sm-25 {
    padding-bottom: 25px !important;
  }
  .pl-sm-25 {
    padding-left: 25px !important;
  }
  .px-sm-25 {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .py-sm-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .p-sm-30 {
    padding: 30px !important;
  }
  .pt-sm-30 {
    padding-top: 30px !important;
  }
  .pr-sm-30 {
    padding-right: 30px !important;
  }
  .pb-sm-30 {
    padding-bottom: 30px !important;
  }
  .pl-sm-30 {
    padding-left: 30px !important;
  }
  .px-sm-30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .py-sm-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .p-sm-35 {
    padding: 35px !important;
  }
  .pt-sm-35 {
    padding-top: 35px !important;
  }
  .pr-sm-35 {
    padding-right: 35px !important;
  }
  .pb-sm-35 {
    padding-bottom: 35px !important;
  }
  .pl-sm-35 {
    padding-left: 35px !important;
  }
  .px-sm-35 {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }
  .py-sm-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .p-sm-40 {
    padding: 40px !important;
  }
  .pt-sm-40 {
    padding-top: 40px !important;
  }
  .pr-sm-40 {
    padding-right: 40px !important;
  }
  .pb-sm-40 {
    padding-bottom: 40px !important;
  }
  .pl-sm-40 {
    padding-left: 40px !important;
  }
  .px-sm-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
  .py-sm-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .p-sm-45 {
    padding: 45px !important;
  }
  .pt-sm-45 {
    padding-top: 45px !important;
  }
  .pr-sm-45 {
    padding-right: 45px !important;
  }
  .pb-sm-45 {
    padding-bottom: 45px !important;
  }
  .pl-sm-45 {
    padding-left: 45px !important;
  }
  .px-sm-45 {
    padding-right: 45px !important;
    padding-left: 45px !important;
  }
  .py-sm-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .p-sm-50 {
    padding: 50px !important;
  }
  .pt-sm-50 {
    padding-top: 50px !important;
  }
  .pr-sm-50 {
    padding-right: 50px !important;
  }
  .pb-sm-50 {
    padding-bottom: 50px !important;
  }
  .pl-sm-50 {
    padding-left: 50px !important;
  }
  .px-sm-50 {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }
  .py-sm-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .p-sm-60 {
    padding: 60px !important;
  }
  .pt-sm-60 {
    padding-top: 60px !important;
  }
  .pr-sm-60 {
    padding-right: 60px !important;
  }
  .pb-sm-60 {
    padding-bottom: 60px !important;
  }
  .pl-sm-60 {
    padding-left: 60px !important;
  }
  .px-sm-60 {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }
  .py-sm-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .p-sm-70 {
    padding: 70px !important;
  }
  .pt-sm-70 {
    padding-top: 70px !important;
  }
  .pr-sm-70 {
    padding-right: 70px !important;
  }
  .pb-sm-70 {
    padding-bottom: 70px !important;
  }
  .pl-sm-70 {
    padding-left: 70px !important;
  }
  .px-sm-70 {
    padding-right: 70px !important;
    padding-left: 70px !important;
  }
  .py-sm-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .p-sm-80 {
    padding: 80px !important;
  }
  .pt-sm-80 {
    padding-top: 80px !important;
  }
  .pr-sm-80 {
    padding-right: 80px !important;
  }
  .pb-sm-80 {
    padding-bottom: 80px !important;
  }
  .pl-sm-80 {
    padding-left: 80px !important;
  }
  .px-sm-80 {
    padding-right: 80px !important;
    padding-left: 80px !important;
  }
  .py-sm-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .p-sm-100 {
    padding: 100px !important;
  }
  .pt-sm-100 {
    padding-top: 100px !important;
  }
  .pr-sm-100 {
    padding-right: 100px !important;
  }
  .pb-sm-100 {
    padding-bottom: 100px !important;
  }
  .pl-sm-100 {
    padding-left: 100px !important;
  }
  .px-sm-100 {
    padding-right: 100px !important;
    padding-left: 100px !important;
  }
  .py-sm-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .m-sm--30 {
    margin: -30px !important;
  }
  .mt-sm--30 {
    margin-top: -30px !important;
  }
  .mr-sm--30 {
    margin-right: -30px !important;
  }
  .mb-sm--30 {
    margin-bottom: -30px !important;
  }
  .ml-sm--30 {
    margin-left: -30px !important;
  }
  .mx-sm--30 {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
  .my-sm--30 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .m-sm--25 {
    margin: -25px !important;
  }
  .mt-sm--25 {
    margin-top: -25px !important;
  }
  .mr-sm--25 {
    margin-right: -25px !important;
  }
  .mb-sm--25 {
    margin-bottom: -25px !important;
  }
  .ml-sm--25 {
    margin-left: -25px !important;
  }
  .mx-sm--25 {
    margin-right: -25px !important;
    margin-left: -25px !important;
  }
  .my-sm--25 {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }
  .m-sm--20 {
    margin: -20px !important;
  }
  .mt-sm--20 {
    margin-top: -20px !important;
  }
  .mr-sm--20 {
    margin-right: -20px !important;
  }
  .mb-sm--20 {
    margin-bottom: -20px !important;
  }
  .ml-sm--20 {
    margin-left: -20px !important;
  }
  .mx-sm--20 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }
  .my-sm--20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .m-sm--15 {
    margin: -15px !important;
  }
  .mt-sm--15 {
    margin-top: -15px !important;
  }
  .mr-sm--15 {
    margin-right: -15px !important;
  }
  .mb-sm--15 {
    margin-bottom: -15px !important;
  }
  .ml-sm--15 {
    margin-left: -15px !important;
  }
  .mx-sm--15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .my-sm--15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .m-sm--10 {
    margin: -10px !important;
  }
  .mt-sm--10 {
    margin-top: -10px !important;
  }
  .mr-sm--10 {
    margin-right: -10px !important;
  }
  .mb-sm--10 {
    margin-bottom: -10px !important;
  }
  .ml-sm--10 {
    margin-left: -10px !important;
  }
  .mx-sm--10 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
  .my-sm--10 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .m-sm--5 {
    margin: -5px !important;
  }
  .mt-sm--5 {
    margin-top: -5px !important;
  }
  .mr-sm--5 {
    margin-right: -5px !important;
  }
  .mb-sm--5 {
    margin-bottom: -5px !important;
  }
  .ml-sm--5 {
    margin-left: -5px !important;
  }
  .mx-sm--5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }
  .my-sm--5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .m-sm--3 {
    margin: -3px !important;
  }
  .mt-sm--3 {
    margin-top: -3px !important;
  }
  .mr-sm--3 {
    margin-right: -3px !important;
  }
  .mb-sm--3 {
    margin-bottom: -3px !important;
  }
  .ml-sm--3 {
    margin-left: -3px !important;
  }
  .mx-sm--3 {
    margin-right: -3px !important;
    margin-left: -3px !important;
  }
  .my-sm--3 {
    margin-top: -3px !important;
    margin-bottom: -3px !important;
  }
  .m-sm--1 {
    margin: -1px !important;
  }
  .mt-sm--1 {
    margin-top: -1px !important;
  }
  .mr-sm--1 {
    margin-right: -1px !important;
  }
  .mb-sm--1 {
    margin-bottom: -1px !important;
  }
  .ml-sm--1 {
    margin-left: -1px !important;
  }
  .mx-sm--1 {
    margin-right: -1px !important;
    margin-left: -1px !important;
  }
  .my-sm--1 {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto {
    margin-left: auto !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0 {
    margin-left: 0 !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-md-3 {
    margin: 3px !important;
  }
  .mt-md-3 {
    margin-top: 3px !important;
  }
  .mr-md-3 {
    margin-right: 3px !important;
  }
  .mb-md-3 {
    margin-bottom: 3px !important;
  }
  .ml-md-3 {
    margin-left: 3px !important;
  }
  .mx-md-3 {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
  .my-md-3 {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .m-md-5 {
    margin: 5px !important;
  }
  .mt-md-5 {
    margin-top: 5px !important;
  }
  .mr-md-5 {
    margin-right: 5px !important;
  }
  .mb-md-5 {
    margin-bottom: 5px !important;
  }
  .ml-md-5 {
    margin-left: 5px !important;
  }
  .mx-md-5 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .my-md-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .m-md-10 {
    margin: 10px !important;
  }
  .mt-md-10 {
    margin-top: 10px !important;
  }
  .mr-md-10 {
    margin-right: 10px !important;
  }
  .mb-md-10 {
    margin-bottom: 10px !important;
  }
  .ml-md-10 {
    margin-left: 10px !important;
  }
  .mx-md-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .my-md-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .m-md-15 {
    margin: 15px !important;
  }
  .mt-md-15 {
    margin-top: 15px !important;
  }
  .mr-md-15 {
    margin-right: 15px !important;
  }
  .mb-md-15 {
    margin-bottom: 15px !important;
  }
  .ml-md-15 {
    margin-left: 15px !important;
  }
  .mx-md-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .my-md-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .m-md-20 {
    margin: 20px !important;
  }
  .mt-md-20 {
    margin-top: 20px !important;
  }
  .mr-md-20 {
    margin-right: 20px !important;
  }
  .mb-md-20 {
    margin-bottom: 20px !important;
  }
  .ml-md-20 {
    margin-left: 20px !important;
  }
  .mx-md-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .my-md-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .m-md-25 {
    margin: 25px !important;
  }
  .mt-md-25 {
    margin-top: 25px !important;
  }
  .mr-md-25 {
    margin-right: 25px !important;
  }
  .mb-md-25 {
    margin-bottom: 25px !important;
  }
  .ml-md-25 {
    margin-left: 25px !important;
  }
  .mx-md-25 {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .my-md-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .m-md-30 {
    margin: 30px !important;
  }
  .mt-md-30 {
    margin-top: 30px !important;
  }
  .mr-md-30 {
    margin-right: 30px !important;
  }
  .mb-md-30 {
    margin-bottom: 30px !important;
  }
  .ml-md-30 {
    margin-left: 30px !important;
  }
  .mx-md-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .my-md-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .m-md-35 {
    margin: 35px !important;
  }
  .mt-md-35 {
    margin-top: 35px !important;
  }
  .mr-md-35 {
    margin-right: 35px !important;
  }
  .mb-md-35 {
    margin-bottom: 35px !important;
  }
  .ml-md-35 {
    margin-left: 35px !important;
  }
  .mx-md-35 {
    margin-right: 35px !important;
    margin-left: 35px !important;
  }
  .my-md-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .m-md-40 {
    margin: 40px !important;
  }
  .mt-md-40 {
    margin-top: 40px !important;
  }
  .mr-md-40 {
    margin-right: 40px !important;
  }
  .mb-md-40 {
    margin-bottom: 40px !important;
  }
  .ml-md-40 {
    margin-left: 40px !important;
  }
  .mx-md-40 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .my-md-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .m-md-45 {
    margin: 45px !important;
  }
  .mt-md-45 {
    margin-top: 45px !important;
  }
  .mr-md-45 {
    margin-right: 45px !important;
  }
  .mb-md-45 {
    margin-bottom: 45px !important;
  }
  .ml-md-45 {
    margin-left: 45px !important;
  }
  .mx-md-45 {
    margin-right: 45px !important;
    margin-left: 45px !important;
  }
  .my-md-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .m-md-50 {
    margin: 50px !important;
  }
  .mt-md-50 {
    margin-top: 50px !important;
  }
  .mr-md-50 {
    margin-right: 50px !important;
  }
  .mb-md-50 {
    margin-bottom: 50px !important;
  }
  .ml-md-50 {
    margin-left: 50px !important;
  }
  .mx-md-50 {
    margin-right: 50px !important;
    margin-left: 50px !important;
  }
  .my-md-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .m-md-60 {
    margin: 60px !important;
  }
  .mt-md-60 {
    margin-top: 60px !important;
  }
  .mr-md-60 {
    margin-right: 60px !important;
  }
  .mb-md-60 {
    margin-bottom: 60px !important;
  }
  .ml-md-60 {
    margin-left: 60px !important;
  }
  .mx-md-60 {
    margin-right: 60px !important;
    margin-left: 60px !important;
  }
  .my-md-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .m-md-70 {
    margin: 70px !important;
  }
  .mt-md-70 {
    margin-top: 70px !important;
  }
  .mr-md-70 {
    margin-right: 70px !important;
  }
  .mb-md-70 {
    margin-bottom: 70px !important;
  }
  .ml-md-70 {
    margin-left: 70px !important;
  }
  .mx-md-70 {
    margin-right: 70px !important;
    margin-left: 70px !important;
  }
  .my-md-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .m-md-80 {
    margin: 80px !important;
  }
  .mt-md-80 {
    margin-top: 80px !important;
  }
  .mr-md-80 {
    margin-right: 80px !important;
  }
  .mb-md-80 {
    margin-bottom: 80px !important;
  }
  .ml-md-80 {
    margin-left: 80px !important;
  }
  .mx-md-80 {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
  .my-md-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .m-md-100 {
    margin: 100px !important;
  }
  .mt-md-100 {
    margin-top: 100px !important;
  }
  .mr-md-100 {
    margin-right: 100px !important;
  }
  .mb-md-100 {
    margin-bottom: 100px !important;
  }
  .ml-md-100 {
    margin-left: 100px !important;
  }
  .mx-md-100 {
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
  .my-md-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0 {
    padding-left: 0 !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-md-3 {
    padding: 3px !important;
  }
  .pt-md-3 {
    padding-top: 3px !important;
  }
  .pr-md-3 {
    padding-right: 3px !important;
  }
  .pb-md-3 {
    padding-bottom: 3px !important;
  }
  .pl-md-3 {
    padding-left: 3px !important;
  }
  .px-md-3 {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .py-md-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .p-md-5 {
    padding: 5px !important;
  }
  .pt-md-5 {
    padding-top: 5px !important;
  }
  .pr-md-5 {
    padding-right: 5px !important;
  }
  .pb-md-5 {
    padding-bottom: 5px !important;
  }
  .pl-md-5 {
    padding-left: 5px !important;
  }
  .px-md-5 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .py-md-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .p-md-10 {
    padding: 10px !important;
  }
  .pt-md-10 {
    padding-top: 10px !important;
  }
  .pr-md-10 {
    padding-right: 10px !important;
  }
  .pb-md-10 {
    padding-bottom: 10px !important;
  }
  .pl-md-10 {
    padding-left: 10px !important;
  }
  .px-md-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .py-md-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .p-md-15 {
    padding: 15px !important;
  }
  .pt-md-15 {
    padding-top: 15px !important;
  }
  .pr-md-15 {
    padding-right: 15px !important;
  }
  .pb-md-15 {
    padding-bottom: 15px !important;
  }
  .pl-md-15 {
    padding-left: 15px !important;
  }
  .px-md-15 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .py-md-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .p-md-20 {
    padding: 20px !important;
  }
  .pt-md-20 {
    padding-top: 20px !important;
  }
  .pr-md-20 {
    padding-right: 20px !important;
  }
  .pb-md-20 {
    padding-bottom: 20px !important;
  }
  .pl-md-20 {
    padding-left: 20px !important;
  }
  .px-md-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .py-md-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .p-md-25 {
    padding: 25px !important;
  }
  .pt-md-25 {
    padding-top: 25px !important;
  }
  .pr-md-25 {
    padding-right: 25px !important;
  }
  .pb-md-25 {
    padding-bottom: 25px !important;
  }
  .pl-md-25 {
    padding-left: 25px !important;
  }
  .px-md-25 {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .py-md-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .p-md-30 {
    padding: 30px !important;
  }
  .pt-md-30 {
    padding-top: 30px !important;
  }
  .pr-md-30 {
    padding-right: 30px !important;
  }
  .pb-md-30 {
    padding-bottom: 30px !important;
  }
  .pl-md-30 {
    padding-left: 30px !important;
  }
  .px-md-30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .py-md-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .p-md-35 {
    padding: 35px !important;
  }
  .pt-md-35 {
    padding-top: 35px !important;
  }
  .pr-md-35 {
    padding-right: 35px !important;
  }
  .pb-md-35 {
    padding-bottom: 35px !important;
  }
  .pl-md-35 {
    padding-left: 35px !important;
  }
  .px-md-35 {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }
  .py-md-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .p-md-40 {
    padding: 40px !important;
  }
  .pt-md-40 {
    padding-top: 40px !important;
  }
  .pr-md-40 {
    padding-right: 40px !important;
  }
  .pb-md-40 {
    padding-bottom: 40px !important;
  }
  .pl-md-40 {
    padding-left: 40px !important;
  }
  .px-md-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
  .py-md-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .p-md-45 {
    padding: 45px !important;
  }
  .pt-md-45 {
    padding-top: 45px !important;
  }
  .pr-md-45 {
    padding-right: 45px !important;
  }
  .pb-md-45 {
    padding-bottom: 45px !important;
  }
  .pl-md-45 {
    padding-left: 45px !important;
  }
  .px-md-45 {
    padding-right: 45px !important;
    padding-left: 45px !important;
  }
  .py-md-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .p-md-50 {
    padding: 50px !important;
  }
  .pt-md-50 {
    padding-top: 50px !important;
  }
  .pr-md-50 {
    padding-right: 50px !important;
  }
  .pb-md-50 {
    padding-bottom: 50px !important;
  }
  .pl-md-50 {
    padding-left: 50px !important;
  }
  .px-md-50 {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }
  .py-md-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .p-md-60 {
    padding: 60px !important;
  }
  .pt-md-60 {
    padding-top: 60px !important;
  }
  .pr-md-60 {
    padding-right: 60px !important;
  }
  .pb-md-60 {
    padding-bottom: 60px !important;
  }
  .pl-md-60 {
    padding-left: 60px !important;
  }
  .px-md-60 {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }
  .py-md-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .p-md-70 {
    padding: 70px !important;
  }
  .pt-md-70 {
    padding-top: 70px !important;
  }
  .pr-md-70 {
    padding-right: 70px !important;
  }
  .pb-md-70 {
    padding-bottom: 70px !important;
  }
  .pl-md-70 {
    padding-left: 70px !important;
  }
  .px-md-70 {
    padding-right: 70px !important;
    padding-left: 70px !important;
  }
  .py-md-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .p-md-80 {
    padding: 80px !important;
  }
  .pt-md-80 {
    padding-top: 80px !important;
  }
  .pr-md-80 {
    padding-right: 80px !important;
  }
  .pb-md-80 {
    padding-bottom: 80px !important;
  }
  .pl-md-80 {
    padding-left: 80px !important;
  }
  .px-md-80 {
    padding-right: 80px !important;
    padding-left: 80px !important;
  }
  .py-md-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .p-md-100 {
    padding: 100px !important;
  }
  .pt-md-100 {
    padding-top: 100px !important;
  }
  .pr-md-100 {
    padding-right: 100px !important;
  }
  .pb-md-100 {
    padding-bottom: 100px !important;
  }
  .pl-md-100 {
    padding-left: 100px !important;
  }
  .px-md-100 {
    padding-right: 100px !important;
    padding-left: 100px !important;
  }
  .py-md-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .m-md--30 {
    margin: -30px !important;
  }
  .mt-md--30 {
    margin-top: -30px !important;
  }
  .mr-md--30 {
    margin-right: -30px !important;
  }
  .mb-md--30 {
    margin-bottom: -30px !important;
  }
  .ml-md--30 {
    margin-left: -30px !important;
  }
  .mx-md--30 {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
  .my-md--30 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .m-md--25 {
    margin: -25px !important;
  }
  .mt-md--25 {
    margin-top: -25px !important;
  }
  .mr-md--25 {
    margin-right: -25px !important;
  }
  .mb-md--25 {
    margin-bottom: -25px !important;
  }
  .ml-md--25 {
    margin-left: -25px !important;
  }
  .mx-md--25 {
    margin-right: -25px !important;
    margin-left: -25px !important;
  }
  .my-md--25 {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }
  .m-md--20 {
    margin: -20px !important;
  }
  .mt-md--20 {
    margin-top: -20px !important;
  }
  .mr-md--20 {
    margin-right: -20px !important;
  }
  .mb-md--20 {
    margin-bottom: -20px !important;
  }
  .ml-md--20 {
    margin-left: -20px !important;
  }
  .mx-md--20 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }
  .my-md--20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .m-md--15 {
    margin: -15px !important;
  }
  .mt-md--15 {
    margin-top: -15px !important;
  }
  .mr-md--15 {
    margin-right: -15px !important;
  }
  .mb-md--15 {
    margin-bottom: -15px !important;
  }
  .ml-md--15 {
    margin-left: -15px !important;
  }
  .mx-md--15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .my-md--15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .m-md--10 {
    margin: -10px !important;
  }
  .mt-md--10 {
    margin-top: -10px !important;
  }
  .mr-md--10 {
    margin-right: -10px !important;
  }
  .mb-md--10 {
    margin-bottom: -10px !important;
  }
  .ml-md--10 {
    margin-left: -10px !important;
  }
  .mx-md--10 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
  .my-md--10 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .m-md--5 {
    margin: -5px !important;
  }
  .mt-md--5 {
    margin-top: -5px !important;
  }
  .mr-md--5 {
    margin-right: -5px !important;
  }
  .mb-md--5 {
    margin-bottom: -5px !important;
  }
  .ml-md--5 {
    margin-left: -5px !important;
  }
  .mx-md--5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }
  .my-md--5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .m-md--3 {
    margin: -3px !important;
  }
  .mt-md--3 {
    margin-top: -3px !important;
  }
  .mr-md--3 {
    margin-right: -3px !important;
  }
  .mb-md--3 {
    margin-bottom: -3px !important;
  }
  .ml-md--3 {
    margin-left: -3px !important;
  }
  .mx-md--3 {
    margin-right: -3px !important;
    margin-left: -3px !important;
  }
  .my-md--3 {
    margin-top: -3px !important;
    margin-bottom: -3px !important;
  }
  .m-md--1 {
    margin: -1px !important;
  }
  .mt-md--1 {
    margin-top: -1px !important;
  }
  .mr-md--1 {
    margin-right: -1px !important;
  }
  .mb-md--1 {
    margin-bottom: -1px !important;
  }
  .ml-md--1 {
    margin-left: -1px !important;
  }
  .mx-md--1 {
    margin-right: -1px !important;
    margin-left: -1px !important;
  }
  .my-md--1 {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto {
    margin-left: auto !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0 {
    margin-left: 0 !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-lg-3 {
    margin: 3px !important;
  }
  .mt-lg-3 {
    margin-top: 3px !important;
  }
  .mr-lg-3 {
    margin-right: 3px !important;
  }
  .mb-lg-3 {
    margin-bottom: 3px !important;
  }
  .ml-lg-3 {
    margin-left: 3px !important;
  }
  .mx-lg-3 {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
  .my-lg-3 {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .m-lg-5 {
    margin: 5px !important;
  }
  .mt-lg-5 {
    margin-top: 5px !important;
  }
  .mr-lg-5 {
    margin-right: 5px !important;
  }
  .mb-lg-5 {
    margin-bottom: 5px !important;
  }
  .ml-lg-5 {
    margin-left: 5px !important;
  }
  .mx-lg-5 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .my-lg-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .m-lg-10 {
    margin: 10px !important;
  }
  .mt-lg-10 {
    margin-top: 10px !important;
  }
  .mr-lg-10 {
    margin-right: 10px !important;
  }
  .mb-lg-10 {
    margin-bottom: 10px !important;
  }
  .ml-lg-10 {
    margin-left: 10px !important;
  }
  .mx-lg-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .my-lg-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .m-lg-15 {
    margin: 15px !important;
  }
  .mt-lg-15 {
    margin-top: 15px !important;
  }
  .mr-lg-15 {
    margin-right: 15px !important;
  }
  .mb-lg-15 {
    margin-bottom: 15px !important;
  }
  .ml-lg-15 {
    margin-left: 15px !important;
  }
  .mx-lg-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .my-lg-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .m-lg-20 {
    margin: 20px !important;
  }
  .mt-lg-20 {
    margin-top: 20px !important;
  }
  .mr-lg-20 {
    margin-right: 20px !important;
  }
  .mb-lg-20 {
    margin-bottom: 20px !important;
  }
  .ml-lg-20 {
    margin-left: 20px !important;
  }
  .mx-lg-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .my-lg-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .m-lg-25 {
    margin: 25px !important;
  }
  .mt-lg-25 {
    margin-top: 25px !important;
  }
  .mr-lg-25 {
    margin-right: 25px !important;
  }
  .mb-lg-25 {
    margin-bottom: 25px !important;
  }
  .ml-lg-25 {
    margin-left: 25px !important;
  }
  .mx-lg-25 {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .my-lg-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .m-lg-30 {
    margin: 30px !important;
  }
  .mt-lg-30 {
    margin-top: 30px !important;
  }
  .mr-lg-30 {
    margin-right: 30px !important;
  }
  .mb-lg-30 {
    margin-bottom: 30px !important;
  }
  .ml-lg-30 {
    margin-left: 30px !important;
  }
  .mx-lg-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .my-lg-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .m-lg-35 {
    margin: 35px !important;
  }
  .mt-lg-35 {
    margin-top: 35px !important;
  }
  .mr-lg-35 {
    margin-right: 35px !important;
  }
  .mb-lg-35 {
    margin-bottom: 35px !important;
  }
  .ml-lg-35 {
    margin-left: 35px !important;
  }
  .mx-lg-35 {
    margin-right: 35px !important;
    margin-left: 35px !important;
  }
  .my-lg-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .m-lg-40 {
    margin: 40px !important;
  }
  .mt-lg-40 {
    margin-top: 40px !important;
  }
  .mr-lg-40 {
    margin-right: 40px !important;
  }
  .mb-lg-40 {
    margin-bottom: 40px !important;
  }
  .ml-lg-40 {
    margin-left: 40px !important;
  }
  .mx-lg-40 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .my-lg-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .m-lg-45 {
    margin: 45px !important;
  }
  .mt-lg-45 {
    margin-top: 45px !important;
  }
  .mr-lg-45 {
    margin-right: 45px !important;
  }
  .mb-lg-45 {
    margin-bottom: 45px !important;
  }
  .ml-lg-45 {
    margin-left: 45px !important;
  }
  .mx-lg-45 {
    margin-right: 45px !important;
    margin-left: 45px !important;
  }
  .my-lg-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .m-lg-50 {
    margin: 50px !important;
  }
  .mt-lg-50 {
    margin-top: 50px !important;
  }
  .mr-lg-50 {
    margin-right: 50px !important;
  }
  .mb-lg-50 {
    margin-bottom: 50px !important;
  }
  .ml-lg-50 {
    margin-left: 50px !important;
  }
  .mx-lg-50 {
    margin-right: 50px !important;
    margin-left: 50px !important;
  }
  .my-lg-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .m-lg-60 {
    margin: 60px !important;
  }
  .mt-lg-60 {
    margin-top: 60px !important;
  }
  .mr-lg-60 {
    margin-right: 60px !important;
  }
  .mb-lg-60 {
    margin-bottom: 60px !important;
  }
  .ml-lg-60 {
    margin-left: 60px !important;
  }
  .mx-lg-60 {
    margin-right: 60px !important;
    margin-left: 60px !important;
  }
  .my-lg-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .m-lg-70 {
    margin: 70px !important;
  }
  .mt-lg-70 {
    margin-top: 70px !important;
  }
  .mr-lg-70 {
    margin-right: 70px !important;
  }
  .mb-lg-70 {
    margin-bottom: 70px !important;
  }
  .ml-lg-70 {
    margin-left: 70px !important;
  }
  .mx-lg-70 {
    margin-right: 70px !important;
    margin-left: 70px !important;
  }
  .my-lg-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .m-lg-80 {
    margin: 80px !important;
  }
  .mt-lg-80 {
    margin-top: 80px !important;
  }
  .mr-lg-80 {
    margin-right: 80px !important;
  }
  .mb-lg-80 {
    margin-bottom: 80px !important;
  }
  .ml-lg-80 {
    margin-left: 80px !important;
  }
  .mx-lg-80 {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
  .my-lg-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .m-lg-100 {
    margin: 100px !important;
  }
  .mt-lg-100 {
    margin-top: 100px !important;
  }
  .mr-lg-100 {
    margin-right: 100px !important;
  }
  .mb-lg-100 {
    margin-bottom: 100px !important;
  }
  .ml-lg-100 {
    margin-left: 100px !important;
  }
  .mx-lg-100 {
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
  .my-lg-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0 {
    padding-left: 0 !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-lg-3 {
    padding: 3px !important;
  }
  .pt-lg-3 {
    padding-top: 3px !important;
  }
  .pr-lg-3 {
    padding-right: 3px !important;
  }
  .pb-lg-3 {
    padding-bottom: 3px !important;
  }
  .pl-lg-3 {
    padding-left: 3px !important;
  }
  .px-lg-3 {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .py-lg-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .p-lg-5 {
    padding: 5px !important;
  }
  .pt-lg-5 {
    padding-top: 5px !important;
  }
  .pr-lg-5 {
    padding-right: 5px !important;
  }
  .pb-lg-5 {
    padding-bottom: 5px !important;
  }
  .pl-lg-5 {
    padding-left: 5px !important;
  }
  .px-lg-5 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .py-lg-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .p-lg-10 {
    padding: 10px !important;
  }
  .pt-lg-10 {
    padding-top: 10px !important;
  }
  .pr-lg-10 {
    padding-right: 10px !important;
  }
  .pb-lg-10 {
    padding-bottom: 10px !important;
  }
  .pl-lg-10 {
    padding-left: 10px !important;
  }
  .px-lg-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .py-lg-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .p-lg-15 {
    padding: 15px !important;
  }
  .pt-lg-15 {
    padding-top: 15px !important;
  }
  .pr-lg-15 {
    padding-right: 15px !important;
  }
  .pb-lg-15 {
    padding-bottom: 15px !important;
  }
  .pl-lg-15 {
    padding-left: 15px !important;
  }
  .px-lg-15 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .py-lg-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .p-lg-20 {
    padding: 20px !important;
  }
  .pt-lg-20 {
    padding-top: 20px !important;
  }
  .pr-lg-20 {
    padding-right: 20px !important;
  }
  .pb-lg-20 {
    padding-bottom: 20px !important;
  }
  .pl-lg-20 {
    padding-left: 20px !important;
  }
  .px-lg-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .py-lg-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .p-lg-25 {
    padding: 25px !important;
  }
  .pt-lg-25 {
    padding-top: 25px !important;
  }
  .pr-lg-25 {
    padding-right: 25px !important;
  }
  .pb-lg-25 {
    padding-bottom: 25px !important;
  }
  .pl-lg-25 {
    padding-left: 25px !important;
  }
  .px-lg-25 {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .py-lg-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .p-lg-30 {
    padding: 30px !important;
  }
  .pt-lg-30 {
    padding-top: 30px !important;
  }
  .pr-lg-30 {
    padding-right: 30px !important;
  }
  .pb-lg-30 {
    padding-bottom: 30px !important;
  }
  .pl-lg-30 {
    padding-left: 30px !important;
  }
  .px-lg-30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .py-lg-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .p-lg-35 {
    padding: 35px !important;
  }
  .pt-lg-35 {
    padding-top: 35px !important;
  }
  .pr-lg-35 {
    padding-right: 35px !important;
  }
  .pb-lg-35 {
    padding-bottom: 35px !important;
  }
  .pl-lg-35 {
    padding-left: 35px !important;
  }
  .px-lg-35 {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }
  .py-lg-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .p-lg-40 {
    padding: 40px !important;
  }
  .pt-lg-40 {
    padding-top: 40px !important;
  }
  .pr-lg-40 {
    padding-right: 40px !important;
  }
  .pb-lg-40 {
    padding-bottom: 40px !important;
  }
  .pl-lg-40 {
    padding-left: 40px !important;
  }
  .px-lg-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
  .py-lg-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .p-lg-45 {
    padding: 45px !important;
  }
  .pt-lg-45 {
    padding-top: 45px !important;
  }
  .pr-lg-45 {
    padding-right: 45px !important;
  }
  .pb-lg-45 {
    padding-bottom: 45px !important;
  }
  .pl-lg-45 {
    padding-left: 45px !important;
  }
  .px-lg-45 {
    padding-right: 45px !important;
    padding-left: 45px !important;
  }
  .py-lg-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .p-lg-50 {
    padding: 50px !important;
  }
  .pt-lg-50 {
    padding-top: 50px !important;
  }
  .pr-lg-50 {
    padding-right: 50px !important;
  }
  .pb-lg-50 {
    padding-bottom: 50px !important;
  }
  .pl-lg-50 {
    padding-left: 50px !important;
  }
  .px-lg-50 {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }
  .py-lg-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .p-lg-60 {
    padding: 60px !important;
  }
  .pt-lg-60 {
    padding-top: 60px !important;
  }
  .pr-lg-60 {
    padding-right: 60px !important;
  }
  .pb-lg-60 {
    padding-bottom: 60px !important;
  }
  .pl-lg-60 {
    padding-left: 60px !important;
  }
  .px-lg-60 {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }
  .py-lg-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .p-lg-70 {
    padding: 70px !important;
  }
  .pt-lg-70 {
    padding-top: 70px !important;
  }
  .pr-lg-70 {
    padding-right: 70px !important;
  }
  .pb-lg-70 {
    padding-bottom: 70px !important;
  }
  .pl-lg-70 {
    padding-left: 70px !important;
  }
  .px-lg-70 {
    padding-right: 70px !important;
    padding-left: 70px !important;
  }
  .py-lg-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .p-lg-80 {
    padding: 80px !important;
  }
  .pt-lg-80 {
    padding-top: 80px !important;
  }
  .pr-lg-80 {
    padding-right: 80px !important;
  }
  .pb-lg-80 {
    padding-bottom: 80px !important;
  }
  .pl-lg-80 {
    padding-left: 80px !important;
  }
  .px-lg-80 {
    padding-right: 80px !important;
    padding-left: 80px !important;
  }
  .py-lg-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .p-lg-100 {
    padding: 100px !important;
  }
  .pt-lg-100 {
    padding-top: 100px !important;
  }
  .pr-lg-100 {
    padding-right: 100px !important;
  }
  .pb-lg-100 {
    padding-bottom: 100px !important;
  }
  .pl-lg-100 {
    padding-left: 100px !important;
  }
  .px-lg-100 {
    padding-right: 100px !important;
    padding-left: 100px !important;
  }
  .py-lg-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .m-lg--30 {
    margin: -30px !important;
  }
  .mt-lg--30 {
    margin-top: -30px !important;
  }
  .mr-lg--30 {
    margin-right: -30px !important;
  }
  .mb-lg--30 {
    margin-bottom: -30px !important;
  }
  .ml-lg--30 {
    margin-left: -30px !important;
  }
  .mx-lg--30 {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
  .my-lg--30 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .m-lg--25 {
    margin: -25px !important;
  }
  .mt-lg--25 {
    margin-top: -25px !important;
  }
  .mr-lg--25 {
    margin-right: -25px !important;
  }
  .mb-lg--25 {
    margin-bottom: -25px !important;
  }
  .ml-lg--25 {
    margin-left: -25px !important;
  }
  .mx-lg--25 {
    margin-right: -25px !important;
    margin-left: -25px !important;
  }
  .my-lg--25 {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }
  .m-lg--20 {
    margin: -20px !important;
  }
  .mt-lg--20 {
    margin-top: -20px !important;
  }
  .mr-lg--20 {
    margin-right: -20px !important;
  }
  .mb-lg--20 {
    margin-bottom: -20px !important;
  }
  .ml-lg--20 {
    margin-left: -20px !important;
  }
  .mx-lg--20 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }
  .my-lg--20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .m-lg--15 {
    margin: -15px !important;
  }
  .mt-lg--15 {
    margin-top: -15px !important;
  }
  .mr-lg--15 {
    margin-right: -15px !important;
  }
  .mb-lg--15 {
    margin-bottom: -15px !important;
  }
  .ml-lg--15 {
    margin-left: -15px !important;
  }
  .mx-lg--15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .my-lg--15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .m-lg--10 {
    margin: -10px !important;
  }
  .mt-lg--10 {
    margin-top: -10px !important;
  }
  .mr-lg--10 {
    margin-right: -10px !important;
  }
  .mb-lg--10 {
    margin-bottom: -10px !important;
  }
  .ml-lg--10 {
    margin-left: -10px !important;
  }
  .mx-lg--10 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
  .my-lg--10 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .m-lg--5 {
    margin: -5px !important;
  }
  .mt-lg--5 {
    margin-top: -5px !important;
  }
  .mr-lg--5 {
    margin-right: -5px !important;
  }
  .mb-lg--5 {
    margin-bottom: -5px !important;
  }
  .ml-lg--5 {
    margin-left: -5px !important;
  }
  .mx-lg--5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }
  .my-lg--5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .m-lg--3 {
    margin: -3px !important;
  }
  .mt-lg--3 {
    margin-top: -3px !important;
  }
  .mr-lg--3 {
    margin-right: -3px !important;
  }
  .mb-lg--3 {
    margin-bottom: -3px !important;
  }
  .ml-lg--3 {
    margin-left: -3px !important;
  }
  .mx-lg--3 {
    margin-right: -3px !important;
    margin-left: -3px !important;
  }
  .my-lg--3 {
    margin-top: -3px !important;
    margin-bottom: -3px !important;
  }
  .m-lg--1 {
    margin: -1px !important;
  }
  .mt-lg--1 {
    margin-top: -1px !important;
  }
  .mr-lg--1 {
    margin-right: -1px !important;
  }
  .mb-lg--1 {
    margin-bottom: -1px !important;
  }
  .ml-lg--1 {
    margin-left: -1px !important;
  }
  .mx-lg--1 {
    margin-right: -1px !important;
    margin-left: -1px !important;
  }
  .my-lg--1 {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto {
    margin-left: auto !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0 {
    margin-left: 0 !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-xl-3 {
    margin: 3px !important;
  }
  .mt-xl-3 {
    margin-top: 3px !important;
  }
  .mr-xl-3 {
    margin-right: 3px !important;
  }
  .mb-xl-3 {
    margin-bottom: 3px !important;
  }
  .ml-xl-3 {
    margin-left: 3px !important;
  }
  .mx-xl-3 {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
  .my-xl-3 {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .m-xl-5 {
    margin: 5px !important;
  }
  .mt-xl-5 {
    margin-top: 5px !important;
  }
  .mr-xl-5 {
    margin-right: 5px !important;
  }
  .mb-xl-5 {
    margin-bottom: 5px !important;
  }
  .ml-xl-5 {
    margin-left: 5px !important;
  }
  .mx-xl-5 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .my-xl-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .m-xl-10 {
    margin: 10px !important;
  }
  .mt-xl-10 {
    margin-top: 10px !important;
  }
  .mr-xl-10 {
    margin-right: 10px !important;
  }
  .mb-xl-10 {
    margin-bottom: 10px !important;
  }
  .ml-xl-10 {
    margin-left: 10px !important;
  }
  .mx-xl-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .my-xl-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .m-xl-15 {
    margin: 15px !important;
  }
  .mt-xl-15 {
    margin-top: 15px !important;
  }
  .mr-xl-15 {
    margin-right: 15px !important;
  }
  .mb-xl-15 {
    margin-bottom: 15px !important;
  }
  .ml-xl-15 {
    margin-left: 15px !important;
  }
  .mx-xl-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .my-xl-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .m-xl-20 {
    margin: 20px !important;
  }
  .mt-xl-20 {
    margin-top: 20px !important;
  }
  .mr-xl-20 {
    margin-right: 20px !important;
  }
  .mb-xl-20 {
    margin-bottom: 20px !important;
  }
  .ml-xl-20 {
    margin-left: 20px !important;
  }
  .mx-xl-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .my-xl-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .m-xl-25 {
    margin: 25px !important;
  }
  .mt-xl-25 {
    margin-top: 25px !important;
  }
  .mr-xl-25 {
    margin-right: 25px !important;
  }
  .mb-xl-25 {
    margin-bottom: 25px !important;
  }
  .ml-xl-25 {
    margin-left: 25px !important;
  }
  .mx-xl-25 {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .my-xl-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .m-xl-30 {
    margin: 30px !important;
  }
  .mt-xl-30 {
    margin-top: 30px !important;
  }
  .mr-xl-30 {
    margin-right: 30px !important;
  }
  .mb-xl-30 {
    margin-bottom: 30px !important;
  }
  .ml-xl-30 {
    margin-left: 30px !important;
  }
  .mx-xl-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .my-xl-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .m-xl-35 {
    margin: 35px !important;
  }
  .mt-xl-35 {
    margin-top: 35px !important;
  }
  .mr-xl-35 {
    margin-right: 35px !important;
  }
  .mb-xl-35 {
    margin-bottom: 35px !important;
  }
  .ml-xl-35 {
    margin-left: 35px !important;
  }
  .mx-xl-35 {
    margin-right: 35px !important;
    margin-left: 35px !important;
  }
  .my-xl-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .m-xl-40 {
    margin: 40px !important;
  }
  .mt-xl-40 {
    margin-top: 40px !important;
  }
  .mr-xl-40 {
    margin-right: 40px !important;
  }
  .mb-xl-40 {
    margin-bottom: 40px !important;
  }
  .ml-xl-40 {
    margin-left: 40px !important;
  }
  .mx-xl-40 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .my-xl-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .m-xl-45 {
    margin: 45px !important;
  }
  .mt-xl-45 {
    margin-top: 45px !important;
  }
  .mr-xl-45 {
    margin-right: 45px !important;
  }
  .mb-xl-45 {
    margin-bottom: 45px !important;
  }
  .ml-xl-45 {
    margin-left: 45px !important;
  }
  .mx-xl-45 {
    margin-right: 45px !important;
    margin-left: 45px !important;
  }
  .my-xl-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .m-xl-50 {
    margin: 50px !important;
  }
  .mt-xl-50 {
    margin-top: 50px !important;
  }
  .mr-xl-50 {
    margin-right: 50px !important;
  }
  .mb-xl-50 {
    margin-bottom: 50px !important;
  }
  .ml-xl-50 {
    margin-left: 50px !important;
  }
  .mx-xl-50 {
    margin-right: 50px !important;
    margin-left: 50px !important;
  }
  .my-xl-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .m-xl-60 {
    margin: 60px !important;
  }
  .mt-xl-60 {
    margin-top: 60px !important;
  }
  .mr-xl-60 {
    margin-right: 60px !important;
  }
  .mb-xl-60 {
    margin-bottom: 60px !important;
  }
  .ml-xl-60 {
    margin-left: 60px !important;
  }
  .mx-xl-60 {
    margin-right: 60px !important;
    margin-left: 60px !important;
  }
  .my-xl-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .m-xl-70 {
    margin: 70px !important;
  }
  .mt-xl-70 {
    margin-top: 70px !important;
  }
  .mr-xl-70 {
    margin-right: 70px !important;
  }
  .mb-xl-70 {
    margin-bottom: 70px !important;
  }
  .ml-xl-70 {
    margin-left: 70px !important;
  }
  .mx-xl-70 {
    margin-right: 70px !important;
    margin-left: 70px !important;
  }
  .my-xl-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .m-xl-80 {
    margin: 80px !important;
  }
  .mt-xl-80 {
    margin-top: 80px !important;
  }
  .mr-xl-80 {
    margin-right: 80px !important;
  }
  .mb-xl-80 {
    margin-bottom: 80px !important;
  }
  .ml-xl-80 {
    margin-left: 80px !important;
  }
  .mx-xl-80 {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
  .my-xl-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .m-xl-100 {
    margin: 100px !important;
  }
  .mt-xl-100 {
    margin-top: 100px !important;
  }
  .mr-xl-100 {
    margin-right: 100px !important;
  }
  .mb-xl-100 {
    margin-bottom: 100px !important;
  }
  .ml-xl-100 {
    margin-left: 100px !important;
  }
  .mx-xl-100 {
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
  .my-xl-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0 {
    padding-left: 0 !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-xl-3 {
    padding: 3px !important;
  }
  .pt-xl-3 {
    padding-top: 3px !important;
  }
  .pr-xl-3 {
    padding-right: 3px !important;
  }
  .pb-xl-3 {
    padding-bottom: 3px !important;
  }
  .pl-xl-3 {
    padding-left: 3px !important;
  }
  .px-xl-3 {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .py-xl-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .p-xl-5 {
    padding: 5px !important;
  }
  .pt-xl-5 {
    padding-top: 5px !important;
  }
  .pr-xl-5 {
    padding-right: 5px !important;
  }
  .pb-xl-5 {
    padding-bottom: 5px !important;
  }
  .pl-xl-5 {
    padding-left: 5px !important;
  }
  .px-xl-5 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .py-xl-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .p-xl-10 {
    padding: 10px !important;
  }
  .pt-xl-10 {
    padding-top: 10px !important;
  }
  .pr-xl-10 {
    padding-right: 10px !important;
  }
  .pb-xl-10 {
    padding-bottom: 10px !important;
  }
  .pl-xl-10 {
    padding-left: 10px !important;
  }
  .px-xl-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .py-xl-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .p-xl-15 {
    padding: 15px !important;
  }
  .pt-xl-15 {
    padding-top: 15px !important;
  }
  .pr-xl-15 {
    padding-right: 15px !important;
  }
  .pb-xl-15 {
    padding-bottom: 15px !important;
  }
  .pl-xl-15 {
    padding-left: 15px !important;
  }
  .px-xl-15 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .py-xl-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .p-xl-20 {
    padding: 20px !important;
  }
  .pt-xl-20 {
    padding-top: 20px !important;
  }
  .pr-xl-20 {
    padding-right: 20px !important;
  }
  .pb-xl-20 {
    padding-bottom: 20px !important;
  }
  .pl-xl-20 {
    padding-left: 20px !important;
  }
  .px-xl-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .py-xl-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .p-xl-25 {
    padding: 25px !important;
  }
  .pt-xl-25 {
    padding-top: 25px !important;
  }
  .pr-xl-25 {
    padding-right: 25px !important;
  }
  .pb-xl-25 {
    padding-bottom: 25px !important;
  }
  .pl-xl-25 {
    padding-left: 25px !important;
  }
  .px-xl-25 {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .py-xl-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .p-xl-30 {
    padding: 30px !important;
  }
  .pt-xl-30 {
    padding-top: 30px !important;
  }
  .pr-xl-30 {
    padding-right: 30px !important;
  }
  .pb-xl-30 {
    padding-bottom: 30px !important;
  }
  .pl-xl-30 {
    padding-left: 30px !important;
  }
  .px-xl-30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .py-xl-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .p-xl-35 {
    padding: 35px !important;
  }
  .pt-xl-35 {
    padding-top: 35px !important;
  }
  .pr-xl-35 {
    padding-right: 35px !important;
  }
  .pb-xl-35 {
    padding-bottom: 35px !important;
  }
  .pl-xl-35 {
    padding-left: 35px !important;
  }
  .px-xl-35 {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }
  .py-xl-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .p-xl-40 {
    padding: 40px !important;
  }
  .pt-xl-40 {
    padding-top: 40px !important;
  }
  .pr-xl-40 {
    padding-right: 40px !important;
  }
  .pb-xl-40 {
    padding-bottom: 40px !important;
  }
  .pl-xl-40 {
    padding-left: 40px !important;
  }
  .px-xl-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
  .py-xl-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .p-xl-45 {
    padding: 45px !important;
  }
  .pt-xl-45 {
    padding-top: 45px !important;
  }
  .pr-xl-45 {
    padding-right: 45px !important;
  }
  .pb-xl-45 {
    padding-bottom: 45px !important;
  }
  .pl-xl-45 {
    padding-left: 45px !important;
  }
  .px-xl-45 {
    padding-right: 45px !important;
    padding-left: 45px !important;
  }
  .py-xl-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .p-xl-50 {
    padding: 50px !important;
  }
  .pt-xl-50 {
    padding-top: 50px !important;
  }
  .pr-xl-50 {
    padding-right: 50px !important;
  }
  .pb-xl-50 {
    padding-bottom: 50px !important;
  }
  .pl-xl-50 {
    padding-left: 50px !important;
  }
  .px-xl-50 {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }
  .py-xl-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .p-xl-60 {
    padding: 60px !important;
  }
  .pt-xl-60 {
    padding-top: 60px !important;
  }
  .pr-xl-60 {
    padding-right: 60px !important;
  }
  .pb-xl-60 {
    padding-bottom: 60px !important;
  }
  .pl-xl-60 {
    padding-left: 60px !important;
  }
  .px-xl-60 {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }
  .py-xl-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .p-xl-70 {
    padding: 70px !important;
  }
  .pt-xl-70 {
    padding-top: 70px !important;
  }
  .pr-xl-70 {
    padding-right: 70px !important;
  }
  .pb-xl-70 {
    padding-bottom: 70px !important;
  }
  .pl-xl-70 {
    padding-left: 70px !important;
  }
  .px-xl-70 {
    padding-right: 70px !important;
    padding-left: 70px !important;
  }
  .py-xl-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .p-xl-80 {
    padding: 80px !important;
  }
  .pt-xl-80 {
    padding-top: 80px !important;
  }
  .pr-xl-80 {
    padding-right: 80px !important;
  }
  .pb-xl-80 {
    padding-bottom: 80px !important;
  }
  .pl-xl-80 {
    padding-left: 80px !important;
  }
  .px-xl-80 {
    padding-right: 80px !important;
    padding-left: 80px !important;
  }
  .py-xl-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .p-xl-100 {
    padding: 100px !important;
  }
  .pt-xl-100 {
    padding-top: 100px !important;
  }
  .pr-xl-100 {
    padding-right: 100px !important;
  }
  .pb-xl-100 {
    padding-bottom: 100px !important;
  }
  .pl-xl-100 {
    padding-left: 100px !important;
  }
  .px-xl-100 {
    padding-right: 100px !important;
    padding-left: 100px !important;
  }
  .py-xl-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .m-xl--30 {
    margin: -30px !important;
  }
  .mt-xl--30 {
    margin-top: -30px !important;
  }
  .mr-xl--30 {
    margin-right: -30px !important;
  }
  .mb-xl--30 {
    margin-bottom: -30px !important;
  }
  .ml-xl--30 {
    margin-left: -30px !important;
  }
  .mx-xl--30 {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
  .my-xl--30 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .m-xl--25 {
    margin: -25px !important;
  }
  .mt-xl--25 {
    margin-top: -25px !important;
  }
  .mr-xl--25 {
    margin-right: -25px !important;
  }
  .mb-xl--25 {
    margin-bottom: -25px !important;
  }
  .ml-xl--25 {
    margin-left: -25px !important;
  }
  .mx-xl--25 {
    margin-right: -25px !important;
    margin-left: -25px !important;
  }
  .my-xl--25 {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }
  .m-xl--20 {
    margin: -20px !important;
  }
  .mt-xl--20 {
    margin-top: -20px !important;
  }
  .mr-xl--20 {
    margin-right: -20px !important;
  }
  .mb-xl--20 {
    margin-bottom: -20px !important;
  }
  .ml-xl--20 {
    margin-left: -20px !important;
  }
  .mx-xl--20 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }
  .my-xl--20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .m-xl--15 {
    margin: -15px !important;
  }
  .mt-xl--15 {
    margin-top: -15px !important;
  }
  .mr-xl--15 {
    margin-right: -15px !important;
  }
  .mb-xl--15 {
    margin-bottom: -15px !important;
  }
  .ml-xl--15 {
    margin-left: -15px !important;
  }
  .mx-xl--15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .my-xl--15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .m-xl--10 {
    margin: -10px !important;
  }
  .mt-xl--10 {
    margin-top: -10px !important;
  }
  .mr-xl--10 {
    margin-right: -10px !important;
  }
  .mb-xl--10 {
    margin-bottom: -10px !important;
  }
  .ml-xl--10 {
    margin-left: -10px !important;
  }
  .mx-xl--10 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
  .my-xl--10 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .m-xl--5 {
    margin: -5px !important;
  }
  .mt-xl--5 {
    margin-top: -5px !important;
  }
  .mr-xl--5 {
    margin-right: -5px !important;
  }
  .mb-xl--5 {
    margin-bottom: -5px !important;
  }
  .ml-xl--5 {
    margin-left: -5px !important;
  }
  .mx-xl--5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }
  .my-xl--5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .m-xl--3 {
    margin: -3px !important;
  }
  .mt-xl--3 {
    margin-top: -3px !important;
  }
  .mr-xl--3 {
    margin-right: -3px !important;
  }
  .mb-xl--3 {
    margin-bottom: -3px !important;
  }
  .ml-xl--3 {
    margin-left: -3px !important;
  }
  .mx-xl--3 {
    margin-right: -3px !important;
    margin-left: -3px !important;
  }
  .my-xl--3 {
    margin-top: -3px !important;
    margin-bottom: -3px !important;
  }
  .m-xl--1 {
    margin: -1px !important;
  }
  .mt-xl--1 {
    margin-top: -1px !important;
  }
  .mr-xl--1 {
    margin-right: -1px !important;
  }
  .mb-xl--1 {
    margin-bottom: -1px !important;
  }
  .ml-xl--1 {
    margin-left: -1px !important;
  }
  .mx-xl--1 {
    margin-right: -1px !important;
    margin-left: -1px !important;
  }
  .my-xl--1 {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto {
    margin-left: auto !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 1600px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0 {
    margin-left: 0 !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-xxl-3 {
    margin: 3px !important;
  }
  .mt-xxl-3 {
    margin-top: 3px !important;
  }
  .mr-xxl-3 {
    margin-right: 3px !important;
  }
  .mb-xxl-3 {
    margin-bottom: 3px !important;
  }
  .ml-xxl-3 {
    margin-left: 3px !important;
  }
  .mx-xxl-3 {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
  .my-xxl-3 {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .m-xxl-5 {
    margin: 5px !important;
  }
  .mt-xxl-5 {
    margin-top: 5px !important;
  }
  .mr-xxl-5 {
    margin-right: 5px !important;
  }
  .mb-xxl-5 {
    margin-bottom: 5px !important;
  }
  .ml-xxl-5 {
    margin-left: 5px !important;
  }
  .mx-xxl-5 {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
  .my-xxl-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .m-xxl-10 {
    margin: 10px !important;
  }
  .mt-xxl-10 {
    margin-top: 10px !important;
  }
  .mr-xxl-10 {
    margin-right: 10px !important;
  }
  .mb-xxl-10 {
    margin-bottom: 10px !important;
  }
  .ml-xxl-10 {
    margin-left: 10px !important;
  }
  .mx-xxl-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .my-xxl-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .m-xxl-15 {
    margin: 15px !important;
  }
  .mt-xxl-15 {
    margin-top: 15px !important;
  }
  .mr-xxl-15 {
    margin-right: 15px !important;
  }
  .mb-xxl-15 {
    margin-bottom: 15px !important;
  }
  .ml-xxl-15 {
    margin-left: 15px !important;
  }
  .mx-xxl-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .my-xxl-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .m-xxl-20 {
    margin: 20px !important;
  }
  .mt-xxl-20 {
    margin-top: 20px !important;
  }
  .mr-xxl-20 {
    margin-right: 20px !important;
  }
  .mb-xxl-20 {
    margin-bottom: 20px !important;
  }
  .ml-xxl-20 {
    margin-left: 20px !important;
  }
  .mx-xxl-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }
  .my-xxl-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .m-xxl-25 {
    margin: 25px !important;
  }
  .mt-xxl-25 {
    margin-top: 25px !important;
  }
  .mr-xxl-25 {
    margin-right: 25px !important;
  }
  .mb-xxl-25 {
    margin-bottom: 25px !important;
  }
  .ml-xxl-25 {
    margin-left: 25px !important;
  }
  .mx-xxl-25 {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .my-xxl-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .m-xxl-30 {
    margin: 30px !important;
  }
  .mt-xxl-30 {
    margin-top: 30px !important;
  }
  .mr-xxl-30 {
    margin-right: 30px !important;
  }
  .mb-xxl-30 {
    margin-bottom: 30px !important;
  }
  .ml-xxl-30 {
    margin-left: 30px !important;
  }
  .mx-xxl-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  .my-xxl-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .m-xxl-35 {
    margin: 35px !important;
  }
  .mt-xxl-35 {
    margin-top: 35px !important;
  }
  .mr-xxl-35 {
    margin-right: 35px !important;
  }
  .mb-xxl-35 {
    margin-bottom: 35px !important;
  }
  .ml-xxl-35 {
    margin-left: 35px !important;
  }
  .mx-xxl-35 {
    margin-right: 35px !important;
    margin-left: 35px !important;
  }
  .my-xxl-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .m-xxl-40 {
    margin: 40px !important;
  }
  .mt-xxl-40 {
    margin-top: 40px !important;
  }
  .mr-xxl-40 {
    margin-right: 40px !important;
  }
  .mb-xxl-40 {
    margin-bottom: 40px !important;
  }
  .ml-xxl-40 {
    margin-left: 40px !important;
  }
  .mx-xxl-40 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
  .my-xxl-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .m-xxl-45 {
    margin: 45px !important;
  }
  .mt-xxl-45 {
    margin-top: 45px !important;
  }
  .mr-xxl-45 {
    margin-right: 45px !important;
  }
  .mb-xxl-45 {
    margin-bottom: 45px !important;
  }
  .ml-xxl-45 {
    margin-left: 45px !important;
  }
  .mx-xxl-45 {
    margin-right: 45px !important;
    margin-left: 45px !important;
  }
  .my-xxl-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .m-xxl-50 {
    margin: 50px !important;
  }
  .mt-xxl-50 {
    margin-top: 50px !important;
  }
  .mr-xxl-50 {
    margin-right: 50px !important;
  }
  .mb-xxl-50 {
    margin-bottom: 50px !important;
  }
  .ml-xxl-50 {
    margin-left: 50px !important;
  }
  .mx-xxl-50 {
    margin-right: 50px !important;
    margin-left: 50px !important;
  }
  .my-xxl-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .m-xxl-60 {
    margin: 60px !important;
  }
  .mt-xxl-60 {
    margin-top: 60px !important;
  }
  .mr-xxl-60 {
    margin-right: 60px !important;
  }
  .mb-xxl-60 {
    margin-bottom: 60px !important;
  }
  .ml-xxl-60 {
    margin-left: 60px !important;
  }
  .mx-xxl-60 {
    margin-right: 60px !important;
    margin-left: 60px !important;
  }
  .my-xxl-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .m-xxl-70 {
    margin: 70px !important;
  }
  .mt-xxl-70 {
    margin-top: 70px !important;
  }
  .mr-xxl-70 {
    margin-right: 70px !important;
  }
  .mb-xxl-70 {
    margin-bottom: 70px !important;
  }
  .ml-xxl-70 {
    margin-left: 70px !important;
  }
  .mx-xxl-70 {
    margin-right: 70px !important;
    margin-left: 70px !important;
  }
  .my-xxl-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .m-xxl-80 {
    margin: 80px !important;
  }
  .mt-xxl-80 {
    margin-top: 80px !important;
  }
  .mr-xxl-80 {
    margin-right: 80px !important;
  }
  .mb-xxl-80 {
    margin-bottom: 80px !important;
  }
  .ml-xxl-80 {
    margin-left: 80px !important;
  }
  .mx-xxl-80 {
    margin-right: 80px !important;
    margin-left: 80px !important;
  }
  .my-xxl-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .m-xxl-100 {
    margin: 100px !important;
  }
  .mt-xxl-100 {
    margin-top: 100px !important;
  }
  .mr-xxl-100 {
    margin-right: 100px !important;
  }
  .mb-xxl-100 {
    margin-bottom: 100px !important;
  }
  .ml-xxl-100 {
    margin-left: 100px !important;
  }
  .mx-xxl-100 {
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
  .my-xxl-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0 {
    padding-left: 0 !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-xxl-3 {
    padding: 3px !important;
  }
  .pt-xxl-3 {
    padding-top: 3px !important;
  }
  .pr-xxl-3 {
    padding-right: 3px !important;
  }
  .pb-xxl-3 {
    padding-bottom: 3px !important;
  }
  .pl-xxl-3 {
    padding-left: 3px !important;
  }
  .px-xxl-3 {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .py-xxl-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .p-xxl-5 {
    padding: 5px !important;
  }
  .pt-xxl-5 {
    padding-top: 5px !important;
  }
  .pr-xxl-5 {
    padding-right: 5px !important;
  }
  .pb-xxl-5 {
    padding-bottom: 5px !important;
  }
  .pl-xxl-5 {
    padding-left: 5px !important;
  }
  .px-xxl-5 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .py-xxl-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .p-xxl-10 {
    padding: 10px !important;
  }
  .pt-xxl-10 {
    padding-top: 10px !important;
  }
  .pr-xxl-10 {
    padding-right: 10px !important;
  }
  .pb-xxl-10 {
    padding-bottom: 10px !important;
  }
  .pl-xxl-10 {
    padding-left: 10px !important;
  }
  .px-xxl-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .py-xxl-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .p-xxl-15 {
    padding: 15px !important;
  }
  .pt-xxl-15 {
    padding-top: 15px !important;
  }
  .pr-xxl-15 {
    padding-right: 15px !important;
  }
  .pb-xxl-15 {
    padding-bottom: 15px !important;
  }
  .pl-xxl-15 {
    padding-left: 15px !important;
  }
  .px-xxl-15 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .py-xxl-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .p-xxl-20 {
    padding: 20px !important;
  }
  .pt-xxl-20 {
    padding-top: 20px !important;
  }
  .pr-xxl-20 {
    padding-right: 20px !important;
  }
  .pb-xxl-20 {
    padding-bottom: 20px !important;
  }
  .pl-xxl-20 {
    padding-left: 20px !important;
  }
  .px-xxl-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .py-xxl-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .p-xxl-25 {
    padding: 25px !important;
  }
  .pt-xxl-25 {
    padding-top: 25px !important;
  }
  .pr-xxl-25 {
    padding-right: 25px !important;
  }
  .pb-xxl-25 {
    padding-bottom: 25px !important;
  }
  .pl-xxl-25 {
    padding-left: 25px !important;
  }
  .px-xxl-25 {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .py-xxl-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .p-xxl-30 {
    padding: 30px !important;
  }
  .pt-xxl-30 {
    padding-top: 30px !important;
  }
  .pr-xxl-30 {
    padding-right: 30px !important;
  }
  .pb-xxl-30 {
    padding-bottom: 30px !important;
  }
  .pl-xxl-30 {
    padding-left: 30px !important;
  }
  .px-xxl-30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .py-xxl-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .p-xxl-35 {
    padding: 35px !important;
  }
  .pt-xxl-35 {
    padding-top: 35px !important;
  }
  .pr-xxl-35 {
    padding-right: 35px !important;
  }
  .pb-xxl-35 {
    padding-bottom: 35px !important;
  }
  .pl-xxl-35 {
    padding-left: 35px !important;
  }
  .px-xxl-35 {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }
  .py-xxl-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .p-xxl-40 {
    padding: 40px !important;
  }
  .pt-xxl-40 {
    padding-top: 40px !important;
  }
  .pr-xxl-40 {
    padding-right: 40px !important;
  }
  .pb-xxl-40 {
    padding-bottom: 40px !important;
  }
  .pl-xxl-40 {
    padding-left: 40px !important;
  }
  .px-xxl-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
  .py-xxl-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .p-xxl-45 {
    padding: 45px !important;
  }
  .pt-xxl-45 {
    padding-top: 45px !important;
  }
  .pr-xxl-45 {
    padding-right: 45px !important;
  }
  .pb-xxl-45 {
    padding-bottom: 45px !important;
  }
  .pl-xxl-45 {
    padding-left: 45px !important;
  }
  .px-xxl-45 {
    padding-right: 45px !important;
    padding-left: 45px !important;
  }
  .py-xxl-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .p-xxl-50 {
    padding: 50px !important;
  }
  .pt-xxl-50 {
    padding-top: 50px !important;
  }
  .pr-xxl-50 {
    padding-right: 50px !important;
  }
  .pb-xxl-50 {
    padding-bottom: 50px !important;
  }
  .pl-xxl-50 {
    padding-left: 50px !important;
  }
  .px-xxl-50 {
    padding-right: 50px !important;
    padding-left: 50px !important;
  }
  .py-xxl-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .p-xxl-60 {
    padding: 60px !important;
  }
  .pt-xxl-60 {
    padding-top: 60px !important;
  }
  .pr-xxl-60 {
    padding-right: 60px !important;
  }
  .pb-xxl-60 {
    padding-bottom: 60px !important;
  }
  .pl-xxl-60 {
    padding-left: 60px !important;
  }
  .px-xxl-60 {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }
  .py-xxl-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .p-xxl-70 {
    padding: 70px !important;
  }
  .pt-xxl-70 {
    padding-top: 70px !important;
  }
  .pr-xxl-70 {
    padding-right: 70px !important;
  }
  .pb-xxl-70 {
    padding-bottom: 70px !important;
  }
  .pl-xxl-70 {
    padding-left: 70px !important;
  }
  .px-xxl-70 {
    padding-right: 70px !important;
    padding-left: 70px !important;
  }
  .py-xxl-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .p-xxl-80 {
    padding: 80px !important;
  }
  .pt-xxl-80 {
    padding-top: 80px !important;
  }
  .pr-xxl-80 {
    padding-right: 80px !important;
  }
  .pb-xxl-80 {
    padding-bottom: 80px !important;
  }
  .pl-xxl-80 {
    padding-left: 80px !important;
  }
  .px-xxl-80 {
    padding-right: 80px !important;
    padding-left: 80px !important;
  }
  .py-xxl-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .p-xxl-100 {
    padding: 100px !important;
  }
  .pt-xxl-100 {
    padding-top: 100px !important;
  }
  .pr-xxl-100 {
    padding-right: 100px !important;
  }
  .pb-xxl-100 {
    padding-bottom: 100px !important;
  }
  .pl-xxl-100 {
    padding-left: 100px !important;
  }
  .px-xxl-100 {
    padding-right: 100px !important;
    padding-left: 100px !important;
  }
  .py-xxl-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .m-xxl--30 {
    margin: -30px !important;
  }
  .mt-xxl--30 {
    margin-top: -30px !important;
  }
  .mr-xxl--30 {
    margin-right: -30px !important;
  }
  .mb-xxl--30 {
    margin-bottom: -30px !important;
  }
  .ml-xxl--30 {
    margin-left: -30px !important;
  }
  .mx-xxl--30 {
    margin-right: -30px !important;
    margin-left: -30px !important;
  }
  .my-xxl--30 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .m-xxl--25 {
    margin: -25px !important;
  }
  .mt-xxl--25 {
    margin-top: -25px !important;
  }
  .mr-xxl--25 {
    margin-right: -25px !important;
  }
  .mb-xxl--25 {
    margin-bottom: -25px !important;
  }
  .ml-xxl--25 {
    margin-left: -25px !important;
  }
  .mx-xxl--25 {
    margin-right: -25px !important;
    margin-left: -25px !important;
  }
  .my-xxl--25 {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }
  .m-xxl--20 {
    margin: -20px !important;
  }
  .mt-xxl--20 {
    margin-top: -20px !important;
  }
  .mr-xxl--20 {
    margin-right: -20px !important;
  }
  .mb-xxl--20 {
    margin-bottom: -20px !important;
  }
  .ml-xxl--20 {
    margin-left: -20px !important;
  }
  .mx-xxl--20 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }
  .my-xxl--20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .m-xxl--15 {
    margin: -15px !important;
  }
  .mt-xxl--15 {
    margin-top: -15px !important;
  }
  .mr-xxl--15 {
    margin-right: -15px !important;
  }
  .mb-xxl--15 {
    margin-bottom: -15px !important;
  }
  .ml-xxl--15 {
    margin-left: -15px !important;
  }
  .mx-xxl--15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .my-xxl--15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .m-xxl--10 {
    margin: -10px !important;
  }
  .mt-xxl--10 {
    margin-top: -10px !important;
  }
  .mr-xxl--10 {
    margin-right: -10px !important;
  }
  .mb-xxl--10 {
    margin-bottom: -10px !important;
  }
  .ml-xxl--10 {
    margin-left: -10px !important;
  }
  .mx-xxl--10 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
  .my-xxl--10 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .m-xxl--5 {
    margin: -5px !important;
  }
  .mt-xxl--5 {
    margin-top: -5px !important;
  }
  .mr-xxl--5 {
    margin-right: -5px !important;
  }
  .mb-xxl--5 {
    margin-bottom: -5px !important;
  }
  .ml-xxl--5 {
    margin-left: -5px !important;
  }
  .mx-xxl--5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }
  .my-xxl--5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .m-xxl--3 {
    margin: -3px !important;
  }
  .mt-xxl--3 {
    margin-top: -3px !important;
  }
  .mr-xxl--3 {
    margin-right: -3px !important;
  }
  .mb-xxl--3 {
    margin-bottom: -3px !important;
  }
  .ml-xxl--3 {
    margin-left: -3px !important;
  }
  .mx-xxl--3 {
    margin-right: -3px !important;
    margin-left: -3px !important;
  }
  .my-xxl--3 {
    margin-top: -3px !important;
    margin-bottom: -3px !important;
  }
  .m-xxl--1 {
    margin: -1px !important;
  }
  .mt-xxl--1 {
    margin-top: -1px !important;
  }
  .mr-xxl--1 {
    margin-right: -1px !important;
  }
  .mb-xxl--1 {
    margin-bottom: -1px !important;
  }
  .ml-xxl--1 {
    margin-left: -1px !important;
  }
  .mx-xxl--1 {
    margin-right: -1px !important;
    margin-left: -1px !important;
  }
  .my-xxl--1 {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto {
    margin-left: auto !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

.w-20 {
  width: 20px !important;
}

.w-30 {
  width: 30px !important;
}

.w-40 {
  width: 40px !important;
}

.w-50 {
  width: 50px !important;
}

.w-60 {
  width: 60px !important;
}

.w-80 {
  width: 80px !important;
}

.w-100 {
  width: 100px !important;
}

.w-120 {
  width: 120px !important;
}

.w-150 {
  width: 150px !important;
}

.w-160 {
  width: 160px !important;
}

.w-200 {
  width: 200px !important;
}

.w-250 {
  width: 250px !important;
}

.w-300 {
  width: 300px !important;
}

.w-350 {
  width: 350px !important;
}

.w-400 {
  width: 400px !important;
}

.w-450 {
  width: 450px !important;
}

.w-500 {
  width: 500px !important;
}

.w-p5 {
  width: 5% !important;
}

.w-p10 {
  width: 10% !important;
}

.w-p15 {
  width: 15% !important;
}

.w-p20 {
  width: 20% !important;
}

.w-p25 {
  width: 25% !important;
}

.w-p30 {
  width: 30% !important;
}

.w-p33 {
  width: 33.3333% !important;
}

.w-p50 {
  width: 50% !important;
}

.w-p75 {
  width: 75% !important;
}

.w-p100 {
  width: 100% !important;
}

.h-20 {
  height: 20px !important;
}

.h-30 {
  height: 30px !important;
}

.h-40 {
  height: 40px !important;
}

.h-50 {
  height: 50px !important;
}

.h-60 {
  height: 60px !important;
}

.h-80 {
  height: 80px !important;
}

.h-100 {
  height: 100px !important;
}

.h-120 {
  height: 120px !important;
}

.h-150 {
  height: 150px !important;
}

.h-160 {
  height: 160px !important;
}

.h-200 {
  height: 200px !important;
}

.h-250 {
  height: 250px !important;
}

.h-300 {
  height: 300px !important;
}

.h-350 {
  height: 350px !important;
}

.h-400 {
  height: 400px !important;
}

.h-450 {
  height: 450px !important;
}

.h-500 {
  height: 500px !important;
}

.h-p5 {
  height: 5% !important;
}

.h-p10 {
  height: 10% !important;
}

.h-p15 {
  height: 15% !important;
}

.h-p20 {
  height: 20% !important;
}

.h-p25 {
  height: 25% !important;
}

.h-p30 {
  height: 30% !important;
}

.h-p33 {
  height: 33.3333% !important;
}

.h-p50 {
  height: 50% !important;
}

.h-p75 {
  height: 75% !important;
}

.h-p100 {
  height: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-auto {
  height: auto !important;
}

.w-full {
  width: 100% !important;
}

.h-full {
  height: 100% !important;
}

@media (min-width: 480px) {
  .w-sm-20 {
    width: 20px !important;
  }
  .w-sm-30 {
    width: 30px !important;
  }
  .w-sm-40 {
    width: 40px !important;
  }
  .w-sm-50 {
    width: 50px !important;
  }
  .w-sm-60 {
    width: 60px !important;
  }
  .w-sm-80 {
    width: 80px !important;
  }
  .w-sm-100 {
    width: 100px !important;
  }
  .w-sm-120 {
    width: 120px !important;
  }
  .w-sm-150 {
    width: 150px !important;
  }
  .w-sm-160 {
    width: 160px !important;
  }
  .w-sm-200 {
    width: 200px !important;
  }
  .w-sm-250 {
    width: 250px !important;
  }
  .w-sm-300 {
    width: 300px !important;
  }
  .w-sm-350 {
    width: 350px !important;
  }
  .w-sm-400 {
    width: 400px !important;
  }
  .w-sm-450 {
    width: 450px !important;
  }
  .w-sm-500 {
    width: 500px !important;
  }
  .w-sm-p5 {
    width: 5% !important;
  }
  .w-sm-p10 {
    width: 10% !important;
  }
  .w-sm-p15 {
    width: 15% !important;
  }
  .w-sm-p20 {
    width: 20% !important;
  }
  .w-sm-p25 {
    width: 25% !important;
  }
  .w-sm-p30 {
    width: 30% !important;
  }
  .w-sm-p33 {
    width: 33.3333% !important;
  }
  .w-sm-p50 {
    width: 50% !important;
  }
  .w-sm-p75 {
    width: 75% !important;
  }
  .w-sm-p100 {
    width: 100% !important;
  }
  .h-sm-20 {
    height: 20px !important;
  }
  .h-sm-30 {
    height: 30px !important;
  }
  .h-sm-40 {
    height: 40px !important;
  }
  .h-sm-50 {
    height: 50px !important;
  }
  .h-sm-60 {
    height: 60px !important;
  }
  .h-sm-80 {
    height: 80px !important;
  }
  .h-sm-100 {
    height: 100px !important;
  }
  .h-sm-120 {
    height: 120px !important;
  }
  .h-sm-150 {
    height: 150px !important;
  }
  .h-sm-160 {
    height: 160px !important;
  }
  .h-sm-200 {
    height: 200px !important;
  }
  .h-sm-250 {
    height: 250px !important;
  }
  .h-sm-300 {
    height: 300px !important;
  }
  .h-sm-350 {
    height: 350px !important;
  }
  .h-sm-400 {
    height: 400px !important;
  }
  .h-sm-450 {
    height: 450px !important;
  }
  .h-sm-500 {
    height: 500px !important;
  }
  .h-sm-p5 {
    height: 5% !important;
  }
  .h-sm-p10 {
    height: 10% !important;
  }
  .h-sm-p15 {
    height: 15% !important;
  }
  .h-sm-p20 {
    height: 20% !important;
  }
  .h-sm-p25 {
    height: 25% !important;
  }
  .h-sm-p30 {
    height: 30% !important;
  }
  .h-sm-p33 {
    height: 33.3333% !important;
  }
  .h-sm-p50 {
    height: 50% !important;
  }
  .h-sm-p75 {
    height: 75% !important;
  }
  .h-sm-p100 {
    height: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .w-sm-full {
    width: 100% !important;
  }
  .h-sm-full {
    height: 100% !important;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .w-only-sm-20 {
    width: 20px !important;
  }
  .w-only-sm-30 {
    width: 30px !important;
  }
  .w-only-sm-40 {
    width: 40px !important;
  }
  .w-only-sm-50 {
    width: 50px !important;
  }
  .w-only-sm-60 {
    width: 60px !important;
  }
  .w-only-sm-80 {
    width: 80px !important;
  }
  .w-only-sm-100 {
    width: 100px !important;
  }
  .w-only-sm-120 {
    width: 120px !important;
  }
  .w-only-sm-150 {
    width: 150px !important;
  }
  .w-only-sm-160 {
    width: 160px !important;
  }
  .w-only-sm-200 {
    width: 200px !important;
  }
  .w-only-sm-250 {
    width: 250px !important;
  }
  .w-only-sm-300 {
    width: 300px !important;
  }
  .w-only-sm-350 {
    width: 350px !important;
  }
  .w-only-sm-400 {
    width: 400px !important;
  }
  .w-only-sm-450 {
    width: 450px !important;
  }
  .w-only-sm-500 {
    width: 500px !important;
  }
  .w-only-sm-p5 {
    width: 5% !important;
  }
  .w-only-sm-p10 {
    width: 10% !important;
  }
  .w-only-sm-p15 {
    width: 15% !important;
  }
  .w-only-sm-p20 {
    width: 20% !important;
  }
  .w-only-sm-p25 {
    width: 25% !important;
  }
  .w-only-sm-p30 {
    width: 30% !important;
  }
  .w-only-sm-p33 {
    width: 33.3333% !important;
  }
  .w-only-sm-p50 {
    width: 50% !important;
  }
  .w-only-sm-p75 {
    width: 75% !important;
  }
  .w-only-sm-p100 {
    width: 100% !important;
  }
  .h-only-sm-20 {
    height: 20px !important;
  }
  .h-only-sm-30 {
    height: 30px !important;
  }
  .h-only-sm-40 {
    height: 40px !important;
  }
  .h-only-sm-50 {
    height: 50px !important;
  }
  .h-only-sm-60 {
    height: 60px !important;
  }
  .h-only-sm-80 {
    height: 80px !important;
  }
  .h-only-sm-100 {
    height: 100px !important;
  }
  .h-only-sm-120 {
    height: 120px !important;
  }
  .h-only-sm-150 {
    height: 150px !important;
  }
  .h-only-sm-160 {
    height: 160px !important;
  }
  .h-only-sm-200 {
    height: 200px !important;
  }
  .h-only-sm-250 {
    height: 250px !important;
  }
  .h-only-sm-300 {
    height: 300px !important;
  }
  .h-only-sm-350 {
    height: 350px !important;
  }
  .h-only-sm-400 {
    height: 400px !important;
  }
  .h-only-sm-450 {
    height: 450px !important;
  }
  .h-only-sm-500 {
    height: 500px !important;
  }
  .h-only-sm-p5 {
    height: 5% !important;
  }
  .h-only-sm-p10 {
    height: 10% !important;
  }
  .h-only-sm-p15 {
    height: 15% !important;
  }
  .h-only-sm-p20 {
    height: 20% !important;
  }
  .h-only-sm-p25 {
    height: 25% !important;
  }
  .h-only-sm-p30 {
    height: 30% !important;
  }
  .h-only-sm-p33 {
    height: 33.3333% !important;
  }
  .h-only-sm-p50 {
    height: 50% !important;
  }
  .h-only-sm-p75 {
    height: 75% !important;
  }
  .h-only-sm-p100 {
    height: 100% !important;
  }
  .w-only-sm-auto {
    width: auto !important;
  }
  .h-only-sm-auto {
    height: auto !important;
  }
  .w-only-sm-full {
    width: 100% !important;
  }
  .h-only-sm-full {
    height: 100% !important;
  }
}

@media (min-width: 768px) {
  .w-md-20 {
    width: 20px !important;
  }
  .w-md-30 {
    width: 30px !important;
  }
  .w-md-40 {
    width: 40px !important;
  }
  .w-md-50 {
    width: 50px !important;
  }
  .w-md-60 {
    width: 60px !important;
  }
  .w-md-80 {
    width: 80px !important;
  }
  .w-md-100 {
    width: 100px !important;
  }
  .w-md-120 {
    width: 120px !important;
  }
  .w-md-150 {
    width: 150px !important;
  }
  .w-md-160 {
    width: 160px !important;
  }
  .w-md-200 {
    width: 200px !important;
  }
  .w-md-250 {
    width: 250px !important;
  }
  .w-md-300 {
    width: 300px !important;
  }
  .w-md-350 {
    width: 350px !important;
  }
  .w-md-400 {
    width: 400px !important;
  }
  .w-md-450 {
    width: 450px !important;
  }
  .w-md-500 {
    width: 500px !important;
  }
  .w-md-p5 {
    width: 5% !important;
  }
  .w-md-p10 {
    width: 10% !important;
  }
  .w-md-p15 {
    width: 15% !important;
  }
  .w-md-p20 {
    width: 20% !important;
  }
  .w-md-p25 {
    width: 25% !important;
  }
  .w-md-p30 {
    width: 30% !important;
  }
  .w-md-p33 {
    width: 33.3333% !important;
  }
  .w-md-p50 {
    width: 50% !important;
  }
  .w-md-p75 {
    width: 75% !important;
  }
  .w-md-p100 {
    width: 100% !important;
  }
  .h-md-20 {
    height: 20px !important;
  }
  .h-md-30 {
    height: 30px !important;
  }
  .h-md-40 {
    height: 40px !important;
  }
  .h-md-50 {
    height: 50px !important;
  }
  .h-md-60 {
    height: 60px !important;
  }
  .h-md-80 {
    height: 80px !important;
  }
  .h-md-100 {
    height: 100px !important;
  }
  .h-md-120 {
    height: 120px !important;
  }
  .h-md-150 {
    height: 150px !important;
  }
  .h-md-160 {
    height: 160px !important;
  }
  .h-md-200 {
    height: 200px !important;
  }
  .h-md-250 {
    height: 250px !important;
  }
  .h-md-300 {
    height: 300px !important;
  }
  .h-md-350 {
    height: 350px !important;
  }
  .h-md-400 {
    height: 400px !important;
  }
  .h-md-450 {
    height: 450px !important;
  }
  .h-md-500 {
    height: 500px !important;
  }
  .h-md-p5 {
    height: 5% !important;
  }
  .h-md-p10 {
    height: 10% !important;
  }
  .h-md-p15 {
    height: 15% !important;
  }
  .h-md-p20 {
    height: 20% !important;
  }
  .h-md-p25 {
    height: 25% !important;
  }
  .h-md-p30 {
    height: 30% !important;
  }
  .h-md-p33 {
    height: 33.3333% !important;
  }
  .h-md-p50 {
    height: 50% !important;
  }
  .h-md-p75 {
    height: 75% !important;
  }
  .h-md-p100 {
    height: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .w-md-full {
    width: 100% !important;
  }
  .h-md-full {
    height: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .w-only-md-20 {
    width: 20px !important;
  }
  .w-only-md-30 {
    width: 30px !important;
  }
  .w-only-md-40 {
    width: 40px !important;
  }
  .w-only-md-50 {
    width: 50px !important;
  }
  .w-only-md-60 {
    width: 60px !important;
  }
  .w-only-md-80 {
    width: 80px !important;
  }
  .w-only-md-100 {
    width: 100px !important;
  }
  .w-only-md-120 {
    width: 120px !important;
  }
  .w-only-md-150 {
    width: 150px !important;
  }
  .w-only-md-160 {
    width: 160px !important;
  }
  .w-only-md-200 {
    width: 200px !important;
  }
  .w-only-md-250 {
    width: 250px !important;
  }
  .w-only-md-300 {
    width: 300px !important;
  }
  .w-only-md-350 {
    width: 350px !important;
  }
  .w-only-md-400 {
    width: 400px !important;
  }
  .w-only-md-450 {
    width: 450px !important;
  }
  .w-only-md-500 {
    width: 500px !important;
  }
  .w-only-md-p5 {
    width: 5% !important;
  }
  .w-only-md-p10 {
    width: 10% !important;
  }
  .w-only-md-p15 {
    width: 15% !important;
  }
  .w-only-md-p20 {
    width: 20% !important;
  }
  .w-only-md-p25 {
    width: 25% !important;
  }
  .w-only-md-p30 {
    width: 30% !important;
  }
  .w-only-md-p33 {
    width: 33.3333% !important;
  }
  .w-only-md-p50 {
    width: 50% !important;
  }
  .w-only-md-p75 {
    width: 75% !important;
  }
  .w-only-md-p100 {
    width: 100% !important;
  }
  .h-only-md-20 {
    height: 20px !important;
  }
  .h-only-md-30 {
    height: 30px !important;
  }
  .h-only-md-40 {
    height: 40px !important;
  }
  .h-only-md-50 {
    height: 50px !important;
  }
  .h-only-md-60 {
    height: 60px !important;
  }
  .h-only-md-80 {
    height: 80px !important;
  }
  .h-only-md-100 {
    height: 100px !important;
  }
  .h-only-md-120 {
    height: 120px !important;
  }
  .h-only-md-150 {
    height: 150px !important;
  }
  .h-only-md-160 {
    height: 160px !important;
  }
  .h-only-md-200 {
    height: 200px !important;
  }
  .h-only-md-250 {
    height: 250px !important;
  }
  .h-only-md-300 {
    height: 300px !important;
  }
  .h-only-md-350 {
    height: 350px !important;
  }
  .h-only-md-400 {
    height: 400px !important;
  }
  .h-only-md-450 {
    height: 450px !important;
  }
  .h-only-md-500 {
    height: 500px !important;
  }
  .h-only-md-p5 {
    height: 5% !important;
  }
  .h-only-md-p10 {
    height: 10% !important;
  }
  .h-only-md-p15 {
    height: 15% !important;
  }
  .h-only-md-p20 {
    height: 20% !important;
  }
  .h-only-md-p25 {
    height: 25% !important;
  }
  .h-only-md-p30 {
    height: 30% !important;
  }
  .h-only-md-p33 {
    height: 33.3333% !important;
  }
  .h-only-md-p50 {
    height: 50% !important;
  }
  .h-only-md-p75 {
    height: 75% !important;
  }
  .h-only-md-p100 {
    height: 100% !important;
  }
  .w-only-md-auto {
    width: auto !important;
  }
  .h-only-md-auto {
    height: auto !important;
  }
  .w-only-md-full {
    width: 100% !important;
  }
  .h-only-md-full {
    height: 100% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-20 {
    width: 20px !important;
  }
  .w-lg-30 {
    width: 30px !important;
  }
  .w-lg-40 {
    width: 40px !important;
  }
  .w-lg-50 {
    width: 50px !important;
  }
  .w-lg-60 {
    width: 60px !important;
  }
  .w-lg-80 {
    width: 80px !important;
  }
  .w-lg-100 {
    width: 100px !important;
  }
  .w-lg-120 {
    width: 120px !important;
  }
  .w-lg-150 {
    width: 150px !important;
  }
  .w-lg-160 {
    width: 160px !important;
  }
  .w-lg-200 {
    width: 200px !important;
  }
  .w-lg-250 {
    width: 250px !important;
  }
  .w-lg-300 {
    width: 300px !important;
  }
  .w-lg-350 {
    width: 350px !important;
  }
  .w-lg-400 {
    width: 400px !important;
  }
  .w-lg-450 {
    width: 450px !important;
  }
  .w-lg-500 {
    width: 500px !important;
  }
  .w-lg-p5 {
    width: 5% !important;
  }
  .w-lg-p10 {
    width: 10% !important;
  }
  .w-lg-p15 {
    width: 15% !important;
  }
  .w-lg-p20 {
    width: 20% !important;
  }
  .w-lg-p25 {
    width: 25% !important;
  }
  .w-lg-p30 {
    width: 30% !important;
  }
  .w-lg-p33 {
    width: 33.3333% !important;
  }
  .w-lg-p50 {
    width: 50% !important;
  }
  .w-lg-p75 {
    width: 75% !important;
  }
  .w-lg-p100 {
    width: 100% !important;
  }
  .h-lg-20 {
    height: 20px !important;
  }
  .h-lg-30 {
    height: 30px !important;
  }
  .h-lg-40 {
    height: 40px !important;
  }
  .h-lg-50 {
    height: 50px !important;
  }
  .h-lg-60 {
    height: 60px !important;
  }
  .h-lg-80 {
    height: 80px !important;
  }
  .h-lg-100 {
    height: 100px !important;
  }
  .h-lg-120 {
    height: 120px !important;
  }
  .h-lg-150 {
    height: 150px !important;
  }
  .h-lg-160 {
    height: 160px !important;
  }
  .h-lg-200 {
    height: 200px !important;
  }
  .h-lg-250 {
    height: 250px !important;
  }
  .h-lg-300 {
    height: 300px !important;
  }
  .h-lg-350 {
    height: 350px !important;
  }
  .h-lg-400 {
    height: 400px !important;
  }
  .h-lg-450 {
    height: 450px !important;
  }
  .h-lg-500 {
    height: 500px !important;
  }
  .h-lg-p5 {
    height: 5% !important;
  }
  .h-lg-p10 {
    height: 10% !important;
  }
  .h-lg-p15 {
    height: 15% !important;
  }
  .h-lg-p20 {
    height: 20% !important;
  }
  .h-lg-p25 {
    height: 25% !important;
  }
  .h-lg-p30 {
    height: 30% !important;
  }
  .h-lg-p33 {
    height: 33.3333% !important;
  }
  .h-lg-p50 {
    height: 50% !important;
  }
  .h-lg-p75 {
    height: 75% !important;
  }
  .h-lg-p100 {
    height: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .w-lg-full {
    width: 100% !important;
  }
  .h-lg-full {
    height: 100% !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .w-only-lg-20 {
    width: 20px !important;
  }
  .w-only-lg-30 {
    width: 30px !important;
  }
  .w-only-lg-40 {
    width: 40px !important;
  }
  .w-only-lg-50 {
    width: 50px !important;
  }
  .w-only-lg-60 {
    width: 60px !important;
  }
  .w-only-lg-80 {
    width: 80px !important;
  }
  .w-only-lg-100 {
    width: 100px !important;
  }
  .w-only-lg-120 {
    width: 120px !important;
  }
  .w-only-lg-150 {
    width: 150px !important;
  }
  .w-only-lg-160 {
    width: 160px !important;
  }
  .w-only-lg-200 {
    width: 200px !important;
  }
  .w-only-lg-250 {
    width: 250px !important;
  }
  .w-only-lg-300 {
    width: 300px !important;
  }
  .w-only-lg-350 {
    width: 350px !important;
  }
  .w-only-lg-400 {
    width: 400px !important;
  }
  .w-only-lg-450 {
    width: 450px !important;
  }
  .w-only-lg-500 {
    width: 500px !important;
  }
  .w-only-lg-p5 {
    width: 5% !important;
  }
  .w-only-lg-p10 {
    width: 10% !important;
  }
  .w-only-lg-p15 {
    width: 15% !important;
  }
  .w-only-lg-p20 {
    width: 20% !important;
  }
  .w-only-lg-p25 {
    width: 25% !important;
  }
  .w-only-lg-p30 {
    width: 30% !important;
  }
  .w-only-lg-p33 {
    width: 33.3333% !important;
  }
  .w-only-lg-p50 {
    width: 50% !important;
  }
  .w-only-lg-p75 {
    width: 75% !important;
  }
  .w-only-lg-p100 {
    width: 100% !important;
  }
  .h-only-lg-20 {
    height: 20px !important;
  }
  .h-only-lg-30 {
    height: 30px !important;
  }
  .h-only-lg-40 {
    height: 40px !important;
  }
  .h-only-lg-50 {
    height: 50px !important;
  }
  .h-only-lg-60 {
    height: 60px !important;
  }
  .h-only-lg-80 {
    height: 80px !important;
  }
  .h-only-lg-100 {
    height: 100px !important;
  }
  .h-only-lg-120 {
    height: 120px !important;
  }
  .h-only-lg-150 {
    height: 150px !important;
  }
  .h-only-lg-160 {
    height: 160px !important;
  }
  .h-only-lg-200 {
    height: 200px !important;
  }
  .h-only-lg-250 {
    height: 250px !important;
  }
  .h-only-lg-300 {
    height: 300px !important;
  }
  .h-only-lg-350 {
    height: 350px !important;
  }
  .h-only-lg-400 {
    height: 400px !important;
  }
  .h-only-lg-450 {
    height: 450px !important;
  }
  .h-only-lg-500 {
    height: 500px !important;
  }
  .h-only-lg-p5 {
    height: 5% !important;
  }
  .h-only-lg-p10 {
    height: 10% !important;
  }
  .h-only-lg-p15 {
    height: 15% !important;
  }
  .h-only-lg-p20 {
    height: 20% !important;
  }
  .h-only-lg-p25 {
    height: 25% !important;
  }
  .h-only-lg-p30 {
    height: 30% !important;
  }
  .h-only-lg-p33 {
    height: 33.3333% !important;
  }
  .h-only-lg-p50 {
    height: 50% !important;
  }
  .h-only-lg-p75 {
    height: 75% !important;
  }
  .h-only-lg-p100 {
    height: 100% !important;
  }
  .w-only-lg-auto {
    width: auto !important;
  }
  .h-only-lg-auto {
    height: auto !important;
  }
  .w-only-lg-full {
    width: 100% !important;
  }
  .h-only-lg-full {
    height: 100% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-20 {
    width: 20px !important;
  }
  .w-xl-30 {
    width: 30px !important;
  }
  .w-xl-40 {
    width: 40px !important;
  }
  .w-xl-50 {
    width: 50px !important;
  }
  .w-xl-60 {
    width: 60px !important;
  }
  .w-xl-80 {
    width: 80px !important;
  }
  .w-xl-100 {
    width: 100px !important;
  }
  .w-xl-120 {
    width: 120px !important;
  }
  .w-xl-150 {
    width: 150px !important;
  }
  .w-xl-160 {
    width: 160px !important;
  }
  .w-xl-200 {
    width: 200px !important;
  }
  .w-xl-250 {
    width: 250px !important;
  }
  .w-xl-300 {
    width: 300px !important;
  }
  .w-xl-350 {
    width: 350px !important;
  }
  .w-xl-400 {
    width: 400px !important;
  }
  .w-xl-450 {
    width: 450px !important;
  }
  .w-xl-500 {
    width: 500px !important;
  }
  .w-xl-p5 {
    width: 5% !important;
  }
  .w-xl-p10 {
    width: 10% !important;
  }
  .w-xl-p15 {
    width: 15% !important;
  }
  .w-xl-p20 {
    width: 20% !important;
  }
  .w-xl-p25 {
    width: 25% !important;
  }
  .w-xl-p30 {
    width: 30% !important;
  }
  .w-xl-p33 {
    width: 33.3333% !important;
  }
  .w-xl-p50 {
    width: 50% !important;
  }
  .w-xl-p75 {
    width: 75% !important;
  }
  .w-xl-p100 {
    width: 100% !important;
  }
  .h-xl-20 {
    height: 20px !important;
  }
  .h-xl-30 {
    height: 30px !important;
  }
  .h-xl-40 {
    height: 40px !important;
  }
  .h-xl-50 {
    height: 50px !important;
  }
  .h-xl-60 {
    height: 60px !important;
  }
  .h-xl-80 {
    height: 80px !important;
  }
  .h-xl-100 {
    height: 100px !important;
  }
  .h-xl-120 {
    height: 120px !important;
  }
  .h-xl-150 {
    height: 150px !important;
  }
  .h-xl-160 {
    height: 160px !important;
  }
  .h-xl-200 {
    height: 200px !important;
  }
  .h-xl-250 {
    height: 250px !important;
  }
  .h-xl-300 {
    height: 300px !important;
  }
  .h-xl-350 {
    height: 350px !important;
  }
  .h-xl-400 {
    height: 400px !important;
  }
  .h-xl-450 {
    height: 450px !important;
  }
  .h-xl-500 {
    height: 500px !important;
  }
  .h-xl-p5 {
    height: 5% !important;
  }
  .h-xl-p10 {
    height: 10% !important;
  }
  .h-xl-p15 {
    height: 15% !important;
  }
  .h-xl-p20 {
    height: 20% !important;
  }
  .h-xl-p25 {
    height: 25% !important;
  }
  .h-xl-p30 {
    height: 30% !important;
  }
  .h-xl-p33 {
    height: 33.3333% !important;
  }
  .h-xl-p50 {
    height: 50% !important;
  }
  .h-xl-p75 {
    height: 75% !important;
  }
  .h-xl-p100 {
    height: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .w-xl-full {
    width: 100% !important;
  }
  .h-xl-full {
    height: 100% !important;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .w-only-xl-20 {
    width: 20px !important;
  }
  .w-only-xl-30 {
    width: 30px !important;
  }
  .w-only-xl-40 {
    width: 40px !important;
  }
  .w-only-xl-50 {
    width: 50px !important;
  }
  .w-only-xl-60 {
    width: 60px !important;
  }
  .w-only-xl-80 {
    width: 80px !important;
  }
  .w-only-xl-100 {
    width: 100px !important;
  }
  .w-only-xl-120 {
    width: 120px !important;
  }
  .w-only-xl-150 {
    width: 150px !important;
  }
  .w-only-xl-160 {
    width: 160px !important;
  }
  .w-only-xl-200 {
    width: 200px !important;
  }
  .w-only-xl-250 {
    width: 250px !important;
  }
  .w-only-xl-300 {
    width: 300px !important;
  }
  .w-only-xl-350 {
    width: 350px !important;
  }
  .w-only-xl-400 {
    width: 400px !important;
  }
  .w-only-xl-450 {
    width: 450px !important;
  }
  .w-only-xl-500 {
    width: 500px !important;
  }
  .w-only-xl-p5 {
    width: 5% !important;
  }
  .w-only-xl-p10 {
    width: 10% !important;
  }
  .w-only-xl-p15 {
    width: 15% !important;
  }
  .w-only-xl-p20 {
    width: 20% !important;
  }
  .w-only-xl-p25 {
    width: 25% !important;
  }
  .w-only-xl-p30 {
    width: 30% !important;
  }
  .w-only-xl-p33 {
    width: 33.3333% !important;
  }
  .w-only-xl-p50 {
    width: 50% !important;
  }
  .w-only-xl-p75 {
    width: 75% !important;
  }
  .w-only-xl-p100 {
    width: 100% !important;
  }
  .h-only-xl-20 {
    height: 20px !important;
  }
  .h-only-xl-30 {
    height: 30px !important;
  }
  .h-only-xl-40 {
    height: 40px !important;
  }
  .h-only-xl-50 {
    height: 50px !important;
  }
  .h-only-xl-60 {
    height: 60px !important;
  }
  .h-only-xl-80 {
    height: 80px !important;
  }
  .h-only-xl-100 {
    height: 100px !important;
  }
  .h-only-xl-120 {
    height: 120px !important;
  }
  .h-only-xl-150 {
    height: 150px !important;
  }
  .h-only-xl-160 {
    height: 160px !important;
  }
  .h-only-xl-200 {
    height: 200px !important;
  }
  .h-only-xl-250 {
    height: 250px !important;
  }
  .h-only-xl-300 {
    height: 300px !important;
  }
  .h-only-xl-350 {
    height: 350px !important;
  }
  .h-only-xl-400 {
    height: 400px !important;
  }
  .h-only-xl-450 {
    height: 450px !important;
  }
  .h-only-xl-500 {
    height: 500px !important;
  }
  .h-only-xl-p5 {
    height: 5% !important;
  }
  .h-only-xl-p10 {
    height: 10% !important;
  }
  .h-only-xl-p15 {
    height: 15% !important;
  }
  .h-only-xl-p20 {
    height: 20% !important;
  }
  .h-only-xl-p25 {
    height: 25% !important;
  }
  .h-only-xl-p30 {
    height: 30% !important;
  }
  .h-only-xl-p33 {
    height: 33.3333% !important;
  }
  .h-only-xl-p50 {
    height: 50% !important;
  }
  .h-only-xl-p75 {
    height: 75% !important;
  }
  .h-only-xl-p100 {
    height: 100% !important;
  }
  .w-only-xl-auto {
    width: auto !important;
  }
  .h-only-xl-auto {
    height: auto !important;
  }
  .w-only-xl-full {
    width: 100% !important;
  }
  .h-only-xl-full {
    height: 100% !important;
  }
}

@media (min-width: 1600px) {
  .w-xxl-20 {
    width: 20px !important;
  }
  .w-xxl-30 {
    width: 30px !important;
  }
  .w-xxl-40 {
    width: 40px !important;
  }
  .w-xxl-50 {
    width: 50px !important;
  }
  .w-xxl-60 {
    width: 60px !important;
  }
  .w-xxl-80 {
    width: 80px !important;
  }
  .w-xxl-100 {
    width: 100px !important;
  }
  .w-xxl-120 {
    width: 120px !important;
  }
  .w-xxl-150 {
    width: 150px !important;
  }
  .w-xxl-160 {
    width: 160px !important;
  }
  .w-xxl-200 {
    width: 200px !important;
  }
  .w-xxl-250 {
    width: 250px !important;
  }
  .w-xxl-300 {
    width: 300px !important;
  }
  .w-xxl-350 {
    width: 350px !important;
  }
  .w-xxl-400 {
    width: 400px !important;
  }
  .w-xxl-450 {
    width: 450px !important;
  }
  .w-xxl-500 {
    width: 500px !important;
  }
  .w-xxl-p5 {
    width: 5% !important;
  }
  .w-xxl-p10 {
    width: 10% !important;
  }
  .w-xxl-p15 {
    width: 15% !important;
  }
  .w-xxl-p20 {
    width: 20% !important;
  }
  .w-xxl-p25 {
    width: 25% !important;
  }
  .w-xxl-p30 {
    width: 30% !important;
  }
  .w-xxl-p33 {
    width: 33.3333% !important;
  }
  .w-xxl-p50 {
    width: 50% !important;
  }
  .w-xxl-p75 {
    width: 75% !important;
  }
  .w-xxl-p100 {
    width: 100% !important;
  }
  .h-xxl-20 {
    height: 20px !important;
  }
  .h-xxl-30 {
    height: 30px !important;
  }
  .h-xxl-40 {
    height: 40px !important;
  }
  .h-xxl-50 {
    height: 50px !important;
  }
  .h-xxl-60 {
    height: 60px !important;
  }
  .h-xxl-80 {
    height: 80px !important;
  }
  .h-xxl-100 {
    height: 100px !important;
  }
  .h-xxl-120 {
    height: 120px !important;
  }
  .h-xxl-150 {
    height: 150px !important;
  }
  .h-xxl-160 {
    height: 160px !important;
  }
  .h-xxl-200 {
    height: 200px !important;
  }
  .h-xxl-250 {
    height: 250px !important;
  }
  .h-xxl-300 {
    height: 300px !important;
  }
  .h-xxl-350 {
    height: 350px !important;
  }
  .h-xxl-400 {
    height: 400px !important;
  }
  .h-xxl-450 {
    height: 450px !important;
  }
  .h-xxl-500 {
    height: 500px !important;
  }
  .h-xxl-p5 {
    height: 5% !important;
  }
  .h-xxl-p10 {
    height: 10% !important;
  }
  .h-xxl-p15 {
    height: 15% !important;
  }
  .h-xxl-p20 {
    height: 20% !important;
  }
  .h-xxl-p25 {
    height: 25% !important;
  }
  .h-xxl-p30 {
    height: 30% !important;
  }
  .h-xxl-p33 {
    height: 33.3333% !important;
  }
  .h-xxl-p50 {
    height: 50% !important;
  }
  .h-xxl-p75 {
    height: 75% !important;
  }
  .h-xxl-p100 {
    height: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .h-xxl-auto {
    height: auto !important;
  }
  .w-xxl-full {
    width: 100% !important;
  }
  .h-xxl-full {
    height: 100% !important;
  }
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 479px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 480px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

@media (max-width: 1599px) {
  .hidden-xl-down {
    display: none !important;
  }
}

@media (min-width: 1600px) {
  .hidden-xxl-up {
    display: none !important;
  }
}

.hidden-xxl-down {
  display: none !important;
}
