@import "variables";

@mixin font-headline-1() {
  // e.g. Guthaben at userHome

  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 48px;
  font-weight: 500;
  line-height: 58px;

  @media screen and (max-width: map-get($akb-breakpoints, desktop)) {
    // mobile:
    font-size: 40px;
    line-height: 50px;
  }
}

@mixin font-headline-1-2() {
  // e.g. text: "Personal Information"
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 50px;
}

@mixin font-headline-2() {
  // e.g. text: "Meine Analyses
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

@mixin font-headline-3() {
  // e.g. text: "Meine Analyses
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

@mixin font-headline-5() {
  // e.g. text: "Gruppen"
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 24px;
}
