.modal {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.noScroll {
  overflow-y: hidden;
}

.modal-header .close {
  margin: 0px !important;
  outline: none !important;
}

.modal-header {
  position: relative;
}

.close {
  top: 0;
  right: 0;
  position: absolute;

  padding: 0 0 10px 15px;
}
