.dropdown-toggle:after {
  position: absolute;
  right: 15px;
  top: 15px;
}

.NavigationDropdownMenu__button {
  cursor: default;
  overflow: visible !important;
}
