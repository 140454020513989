@import "variables";
@import "typography";

//TODO: Move static Colors to variables!

.AnalysisBrowser--table td {
  vertical-align: middle !important;
}

.AnalysisBrowser--table {
  user-select: none;
}

.AnalysisBrowser--placeholder {
  padding-bottom: 10px !important;
}

.akb-analysis-browser {
  .panel-header {
    display: flex;
    justify-content: space-between;
    .header {
      @include font-headline-1;
    }

    @media screen and (max-width: map-get($akb-breakpoints, desktop)) {
      padding-left: 16px;
      padding-right: 16px;
    }

    .btn.btn-primary {
      width: 40px;
      height: 40px;
      background-color: #f3f9fa;
      border: none;
      border-radius: 6px;
      padding: 0;
      margin: 8px 0 0 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 33px;
      color: #01b2d4;
      font-family: -webkit-pictograph;
    }
  }

  .panel-content {
    .accordion {
      .card {
        margin: 0;
        overflow: unset;
        .card-header {
          height: 64px;
          color: #323232;
          padding: 0 17px 0 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 6px;
          @media screen and (max-width: map-get($akb-breakpoints, desktop)) {
            padding-left: 16px;
            padding-right: 16px;
          }
        }

        &:nth-child(even) {
          .card-header,
          .card-body {
            background-color: #ffffff;
          }
        }
        &:nth-child(odd) {
          .card-header,
          .card-body {
            background-color: #f8f8f8;

            .akb-life-number-display {
              background-color: #ffffff;
            }
          }
        }

        .card-body {
          padding: 0;
        }
      }
    }
  }

  .analysis-browser-toggle {
    display: flex;

    &.akb-clickable {
      cursor: pointer;
    }

    .text {
      @include font-headline-2;
      margin-right: 16px;
    }
  }

  .akb-list-entry {
    height: 64px;
    padding-left: 24px;
    padding-right: 17px;
    @media screen and (max-width: map-get($akb-breakpoints, desktop)) {
      padding-left: 16px;
    }

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    a.akb-link {
      color: #01b2d4;
      white-space: nowrap;
      overflow: hidden;
      @media screen and (max-width: map-get($akb-breakpoints, desktop)) {
        max-width: calc(100vw * 0.5);
      }

      @include font-headline-3;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .akb-life-number-display {
    display: none;
    white-space: nowrap;
    height: 32px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 32px;
    background-color: #f8f8f8;

    // text
    color: #323232;
    text-align: center;
    @include font-headline-3;

    @media screen and (min-width: 468px) {
      display: block;
    }
  }
}
