//$colors = {
//  $darkGrey: '#323232';
//}

$mobile-resolution-threshold: 767px; // TODO: find a way how to keep that in sync with Constants.js
$main-container-max-width: 1440px;

$akb-breakpoints: (
  desktop: $mobile-resolution-threshold
) !default;

$dark-yellow: #f8f8f8;
$red: #ff0000;
$light-blue: #01b2d4;
$white: #ffffff;
$light-gray: #eaeaea;

$ddwnBg: #ffffff;
$ddwnBorder: #6c757d54;
$ddwnFontColor: #212529;
