@import "variables";

.dropdown {
  &-toggle {
    width: 100%;
    text-align: left;
  }

  &-menu-analysis {
    width: 100%;
    max-height: 300px;

    overflow-y: auto;
  }

  &-analysis {
    margin: 0 0 15px 0;
  }
}

.btn-secondary {
  color: $ddwnFontColor;
  border-color: $ddwnBorder;
  background-color: $ddwnBg;

  &:hover {
    color: $ddwnFontColor;
    background-color: $ddwnBg;
  }
}
